export type FieldProps = {
  parent?: Field;
  context: any;
  validContext: any;
  general: GeneralContext;
  field: Field;
  value: any;
  valid: any;
  onChange: (value: any) => void;
  onValidChange: (valid: any) => void;
};

export type FieldBlockProps = {
  parent: Field;
  layout?: "step" | "block" | "table";
  field: Field[];
  general: GeneralContext;
  value: any;
  valid: any;
  onChange: (key: string, value: any) => void;
  onValidChange: (key: string, valid: boolean) => void;
};

export type FieldBlockEditableProps = {
  parent: Field;
  layout?: "step" | "block" | "table";
  field: Field[];
  general: GeneralContext;
  value: any;
  valid: any;
  onChange: (key: string, value: any) => void;
  onValidChange: (key: string, valid: boolean) => void;
  onConfigChange: (field: Field[]) => void;
  onParentConfigChange: (field: Field) => void;
  onRemove: (index: number) => void;
};

export type FieldBase = {
  key: string;
  options: FieldOptions;
  onChange: (value: any) => void;
  onValidChange: (valid: any) => void;
  general: GeneralContext;
  value: any;
  valid: any;
  context: any;
  field: Field;
};

export interface FunctionDefinitions {
  [domain: string]: {
    [namespace: string]: string;
  };
}
export interface ParsedFunctions {
  [namespace: string]: {
    [functionName: string]: Function;
  };
}
export type FieldBlockViewProps = {
  field: Field[];
  general: GeneralContext;
  value: any;
};

export type FieldEditableProps = {
  parent?: Field;
  context: any;
  validContext: any;
  general: GeneralContext;
  field: Field;
  value: any;
  valid: any;
  onChange: (value: any) => void;
  onValidChange: (valid: any) => void;
  onConfigChange: (config: Field) => void;
  onRemove: () => void;
};

export const InputFieldTypes = [
  "checkbox",
  "input",
  "map",
  "radio",
  "select",
  "textarea",
  "upload",
  "table",
];
