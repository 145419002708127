import React, { FormEvent, useState } from "react";
import { FaPlus, FaTrash } from "react-icons/fa";
import { Input, Select } from "../../../components";
import { IconButton } from "@chakra-ui/react";
import EditableHeader from "../../../components/EditableHeader";
import { AddEnvironment, EnvironmentValueForm } from "./AddEnvironment";

type EnvironmentEditorProps = {
  environments: EnvironmentConfig[];
  onChange: (environments: EnvironmentConfig[]) => void;
};

export const EnvironmentEditor: React.FC<EnvironmentEditorProps> = ({
  environments,
  onChange,
}) => {
  const [search, setSearch] = useState("");
  const [selectedEnvironment, setSelectedEnvironment] =
    useState<EnvironmentConfig | null>(null);

  const searchCallback = (search: string) => {
    setSearch(search);
  };

  const selectEnvironmentCallback = (environmentConfig: EnvironmentConfig) => {
    if (environmentConfig !== undefined && environmentConfig.id) {
      setSelectedEnvironment(environmentConfig);
    }
  };

  const handleSetEnvironment = (key: string, value: any) => {
    if (selectedEnvironment !== null) {
      setSelectedEnvironment((prev: any) => {
        const updatedEnvironment = {
          ...prev,
          [key]: value,
        };

        handleSaveEnvironment(updatedEnvironment);

        return updatedEnvironment;
      });
    }
  };

  const handleAddEnvironmentForm = (e: FormEvent | undefined) => {
    e?.preventDefault();
    setSelectedEnvironment(null);
  };

  const handleSaveEnvironment = (updatedEnvironment: EnvironmentConfig) => {
    const updatedEnvironments = environments.map((environment) =>
      environment.id === updatedEnvironment.id
        ? updatedEnvironment
        : environment
    );
    onChange(updatedEnvironments);
  };

  const handleAddEnvironment = (newEnvironment: EnvironmentConfig) => {
    const environment = {
      id: Date.now(),
      title: newEnvironment.title,
      description: newEnvironment.description,
      key: newEnvironment.key,
      value: newEnvironment.value,
      type: newEnvironment.type,
    };

    setSelectedEnvironment(environment);
    onChange([...environments, environment]);
  };

  const handleRemoveEnvironment = () => {
    if (selectedEnvironment !== null && selectedEnvironment.id) {
      onChange(
        environments.filter(
          (environment) => environment.id !== selectedEnvironment.id
        )
      );
      setSelectedEnvironment(null);
    }
  };

  return (
    <div className="flex flex-grow border-l-0 border-2 rounded-r-md">
      <div className="flex flex-col space-y-6 p-6 w-3/12 border-r-2">
        <div>
          <Input
            type="text"
            placeholder="Nome da variável"
            size="lg"
            className="flex-grow"
            value={search}
            onChange={(e) => searchCallback(e.target.value)}
          />
        </div>
        <div className="mx-0 px-0">
          {environments
            .filter(
              (environment) =>
                search.trim().length === 0 || environment.title.includes(search)
            )
            .sort((a, b) => a.title.localeCompare(b.title))
            .map((environment) => (
              <div
                key={"preset-item-" + environment.id}
                className="px-4 py-2 hover:bg-gray-200"
              >
                <button
                  onClick={() => selectEnvironmentCallback(environment)}
                  className="flex justify-between w-full items-center"
                >
                  {environment.title}
                </button>
              </div>
            ))}
          <div className="flex mt-4 justify-center">
            <IconButton
              aria-label="Add new acceptance type"
              icon={<FaPlus />}
              onClick={handleAddEnvironmentForm}
              size="sm"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col space-y-6 p-6 w-9/12">
        {selectedEnvironment === null && (
          <AddEnvironment
            onAddEnvironment={handleAddEnvironment}
          ></AddEnvironment>
        )}
        {selectedEnvironment !== null && (
          <>
            <div className="flex flex-col mx-auto">
              <>
                <EditableHeader
                  value={selectedEnvironment?.title}
                  onTextChange={(text) => handleSetEnvironment("title", text)}
                  className="text-xl md:text-3xl font-black mb-2 text-center"
                />
                <EditableHeader
                  value={selectedEnvironment?.description}
                  onTextChange={(text) =>
                    handleSetEnvironment("description", text)
                  }
                  className="mb-2 text-center"
                />
              </>
            </div>
            <div
              className="flex flex-col mx-auto space-y-2"
              style={{ minWidth: "600px", maxWidth: "605px" }}
            >
              <label>Chave</label>
              <Input
                type="text"
                placeholder="Chave"
                size="lg"
                value={selectedEnvironment?.key}
                onChange={(e) => handleSetEnvironment("key", e.target.value)}
              />
              <label>Tipo</label>
              <Select
                size="lg"
                className="flex-grow"
                value={selectedEnvironment?.type}
                onChange={(e) => handleSetEnvironment("type", e.target.value)}
              >
                <option value="string">Texto</option>
                <option value="number">Número</option>
                <option value="boolean">Booleano</option>
                <option value="date">Data</option>
                <option value="object">Objeto</option>
              </Select>
              {selectedEnvironment && (
                <EnvironmentValueForm
                  form={selectedEnvironment}
                  setValue={(value) => handleSetEnvironment("value", value)}
                />
              )}
            </div>
            <div className="text-end">
              <span className="font-bold">Remover variável</span>
              <IconButton
                size={"lg"}
                aria-label="Remove Environment"
                icon={<FaTrash />}
                onClick={handleRemoveEnvironment}
                className="ml-2"
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
