import { useState } from "react";
import { Field } from "../Field";

export type FieldTableProps = {
  table: Field[][];
  options: Pick<FieldOptions, "columns" | "width">;
  general: GeneralContext;
  value: any;
  valid: any;
  onChange: (value: any) => void;
  onValidChange: (valid: any) => void;
};

export const Table: React.FC<FieldTableProps> = ({
  table,
  options,
  general,
  value = {},
  valid = {},
  onChange,
  onValidChange,
}) => {
  const [localValue, setLocalValue] = useState(value);
  const [localValid, setLocalValid] = useState(valid);

  return (
    <div className="flex flex-col" style={{ width: options.width }}>
      {table?.map((row, rowIndex) => {
        return (
          <div className="flex">
            {row.map((field) => {
              if (field.type === "integration" || field.type === "link") {
                return (
                  <div className="w-0">
                    <Field
                      parent={field}
                      general={general}
                      field={field}
                      value={localValue?.[field.key]}
                      valid={localValid?.[field.key]}
                      onChange={(v) => {
                        setLocalValue((value: any) => {
                          const newValue = { ...value, [field.key]: v };
                          onChange(newValue);
                          return newValue;
                        });
                      }}
                      onValidChange={(v) => {
                        setLocalValid((valid: any) => {
                          const newValid = { ...valid, [field.key]: v };
                          onValidChange(newValid);
                          return newValid;
                        });
                      }}
                      context={value}
                      validContext={valid}
                    />
                  </div>
                );
              }

              return field.type !== "none" ? (
                <div
                  className={`border-x border-t ${
                    rowIndex + 1 === table.length ? "border-b" : ""
                  } ${field.type !== "array" ? "px-4 py-3" : ""}`}
                  style={{
                    width: `${
                      ((field.options.columns ?? row.length) /
                        (options.columns ?? 1)) *
                      100
                    }%`,
                  }}
                >
                  <Field
                    parent={field}
                    general={general}
                    field={field}
                    value={localValue?.[field.key]}
                    valid={localValid?.[field.key]}
                    onChange={(v) => {
                      setLocalValue((value: any) => {
                        const newValue = { ...value, [field.key]: v };
                        onChange(newValue);
                        return newValue;
                      });
                    }}
                    onValidChange={(v) => {
                      setLocalValid((valid: any) => {
                        const newValid = { ...valid, [field.key]: v };
                        onValidChange(newValid);
                        return newValid;
                      });
                    }}
                    context={value}
                    validContext={valid}
                  />
                </div>
              ) : (
                <div
                  className="border-x"
                  style={{
                    width: `${
                      ((field.options.columns ?? row.length) /
                        (options.columns ?? 1)) *
                      100
                    }%`,
                  }}
                ></div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
