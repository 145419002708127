import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tag,
  Tooltip,
} from "@chakra-ui/react";
import { StyleContext } from "../../../reducers";

export const VersionsMenu = ({
  versions,
  defaultVersion,
  callback,
}: {
  versions: {
    id: string;
    version: number;
    commitMessage: string;
    timestamp: string;
    createdBy: string;
    stage?: string;
  }[];
  defaultVersion: {
    version: number;
    stage?: string;
  };
  callback: (version: number, stage?: string) => void;
}) => {
  const [currentVersion, setCurrentVersion] = useState(defaultVersion);

  const handleSelect = (version: any) => {
    setCurrentVersion({ version: version.version, stage: version.stage });
    callback(version.version, version.stage);
  };

  useEffect(() => {
    setCurrentVersion(defaultVersion);
  }, [versions, defaultVersion]);

  const styleContext = useContext(StyleContext);
  const stageMapper: { [key: string]: { label: string; color: string } } = {
    development: { label: "desenvolvimento", color: "gray" },
    approval: { label: "homologação", color: "yellow" },
    production: { label: "produção", color: "green" },
  };
  return (
    <Menu>
      <MenuButton as={Button}>{`v${currentVersion.version} ${
        currentVersion.stage
          ? `(${stageMapper[currentVersion.stage].label})`
          : ""
      }`}</MenuButton>
      <MenuList
        maxH="200px"
        overflowY="auto"
        zIndex={10000}
        bg={styleContext.state.backgroundColor}
      >
        {versions
          .sort(
            (a, b) =>
              new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
          )
          .map((version: any) => (
            <Tooltip
              key={version.version}
              label={
                (version.commitMessage ?? "") +
                ` (Data: ${new Date(version.timestamp).toLocaleString(
                  "pt-br"
                )} - Autor: ${version.createdBy})`
              }
              placement="right"
            >
              <MenuItem
                onClick={() => handleSelect(version)}
                bg={styleContext.state.backgroundColor}
                className="font-normal"
              >
                (v{version.version}): {version.commitMessage?.slice(0, 25)}
                {version.commitMessage?.length > 25 ? "..." : ""}
                {(version.stage === "approval" ||
                  version.stage === "production") && (
                  <Tag
                    className="ml-2"
                    colorScheme={stageMapper[version?.stage]?.color}
                  >
                    {stageMapper[version?.stage]?.label}
                  </Tag>
                )}{" "}
              </MenuItem>
            </Tooltip>
          ))}
      </MenuList>
    </Menu>
  );
};
