import {
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  useDisclosure,
  Portal,
} from "@chakra-ui/react";
import { FaEllipsisV, FaCaretRight } from "react-icons/fa";
import LayersModal from "./LayersModal";
import { useContext, useState } from "react";
import { StyleContext } from "../../../reducers";

export type FieldMenuEditorProps = {
  field: Field;
  onChange: (config: any) => void;
  onKeyChange?: (key: string) => void;
  options?: { iconSize: number };
};

const KEY = "Chave";
const ITEMS = "Items";
const LAYERS = "Camadas";

export const FieldMenuEditor: React.FC<FieldMenuEditorProps> = ({
  field,
  onChange,
  onKeyChange,
  options,
}) => {
  const menuController = useDisclosure();
  const submenuController = useDisclosure();
  const styleContext = useContext(StyleContext);
  const [layerModalOpen, setLayerModalOpen] = useState(false);
  const [submenuOptions, setSubmenuOptions] = useState<any[]>([]);

  const onMenuClick = async (option: any) => {
    if (option.title === KEY) {
      const value = await (prompt as any)(option.title, field.key);

      if (value !== null && onKeyChange) {
        onKeyChange(value);
      }
    } else if (option.title === ITEMS) {
      const value = await (prompt as any)(
        option.title,
        (field.options as any)?.[option.key]
          ?.map((item: any) => item.key)
          .join("\n")
      );

      if (value !== null) {
        onChange({
          options: {
            ...field.options,
            [option.key]:
              value?.split("\n").map((item: string) => ({
                key: item.trim(),
                value: item.trim(),
              })) ?? [],
          },
        });
      }
    } else if (option.fields?.length) {
      const defaultValue: any = {};

      option.fields?.forEach((formField: any) => {
        defaultValue[formField.key] =
          (field.options as any)?.[formField.key] ?? formField.default ?? "";
      });

      const value = await (prompt as any)(
        option.title,
        defaultValue,
        option.fields
      );

      if (value !== null) {
        onChange({
          options: {
            ...field.options,
            ...value,
          },
        });
      }
    } else if (option.title === LAYERS) {
      setLayerModalOpen(true);
    } else {
      const optionValue =
        (field as any)[option.namespace ?? "options"]?.[option.key] ?? "";
      const value = await (prompt as any)(
        option.title,
        option.type === "object" ? JSON.stringify(optionValue) : optionValue,
        [],
        { tooltip: option.tooltip, code: option.code }
      );

      try {
        if (value !== null) {
          onChange({
            [option.namespace ?? "options"]: {
              ...((field as any)?.[option.namespace ?? "options"] ?? {}),
              [option.key]:
                option.type === "object" ? JSON.parse(value) : value,
            },
          });
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleSubmenuOpen = (submenuOptions: any[]) => {
    setSubmenuOptions(submenuOptions);
    submenuController.onOpen();
  };

  return (
    <div>
      <LayersModal
        layers={field.options.layers ?? []}
        isOpen={layerModalOpen}
        onSave={(value) => {
          onChange({
            options: {
              ...field.options,
              layers: value,
            },
          });
          setLayerModalOpen(false);
        }}
        onClose={() => {
          setLayerModalOpen(false);
        }}
      />
      <Menu isOpen={menuController.isOpen} onClose={menuController.onClose}>
        <MenuButton
          as={IconButton}
          aria-label="Field Edit Options"
          icon={
            <>
              <div
                className={`${
                  options?.iconSize
                    ? "cursor-pointer p-4 bg-gray-300 rounded-xl"
                    : ""
                }`}
              >
                <FaEllipsisV size={options?.iconSize} />
              </div>
            </>
          }
          onClick={menuController.onOpen}
        />
        <Portal>
          <MenuList
            zIndex={"overlay"}
            bg={styleContext.state.backgroundColor}
            maxHeight="300px"
            overflowY="auto"
          >
            {fieldEditOptions[field.type]?.map((option: any, index: number) => (
              <MenuItem
                key={index}
                onClick={() => {
                  if (option.submenu) {
                    handleSubmenuOpen(option.submenu);
                  } else {
                    onMenuClick(option);
                    menuController.onClose();
                  }
                }}
                icon={option.submenu && <FaCaretRight />}
                bg={styleContext.state.backgroundColor}
              >
                {option.title}
              </MenuItem>
            ))}
          </MenuList>
        </Portal>
      </Menu>
      <Menu
        isOpen={submenuController.isOpen}
        onClose={submenuController.onClose}
      >
        <MenuButton></MenuButton>
        <Portal>
          <MenuList
            zIndex={"overlay"}
            bg={styleContext.state.backgroundColor}
            maxHeight="300px"
            overflowY="auto"
          >
            {submenuOptions.map((subOption: any, subIndex: number) => (
              <MenuItem
                key={subIndex}
                onClick={() => {
                  onMenuClick(subOption);
                  submenuController.onClose();
                }}
                bg={styleContext.state.backgroundColor}
              >
                {subOption.title}
              </MenuItem>
            ))}
          </MenuList>
        </Portal>
      </Menu>
    </div>
  );
};

const keyConfig = {
  key: "key",
  title: KEY,
};

// options
const tooltipOption = { key: "tooltip", title: "Tooltip" };

const enableApostilleOption = {
  title: "Edição no Apostilamento",
  fields: [
    {
      key: "enableApostille",
      title: "Permitir edição no apostilamento",
      type: "radio",
      default: "false",
      options: {
        label: "",
        items: [
          { key: "Sim", value: "true" },
          { key: "Não", value: "false" },
        ],
      },
    },
  ],
};

const placeholderOption = { key: "placeholder", title: "Placeholder" };

const itemsOption = { key: "items", title: ITEMS };

const requiredOption = {
  title: "Campo obrigatório",
  fields: [
    {
      key: "required",
      title: "Campo obrigatório",
      type: "radio",
      default: "false",
      options: {
        label: "",
        items: [
          { key: "Sim", value: "true" },
          { key: "Não", value: "false" },
        ],
      },
    },
  ],
};

const readOnlyOption = {
  title: "Apenas leitura",
  fields: [
    {
      key: "readOnly",
      title: "Apenas leitura",
      type: "radio",
      default: "false",
      options: {
        label: "",
        items: [
          { key: "Sim", value: "true" },
          { key: "Não", value: "false" },
        ],
      },
    },
  ],
};

const toggleOptions = {
  title: "Mostrar toggle",
  fields: [
    {
      key: "toggle",
      title: "Mostrar toggle",
      type: "radio",
      default: "true",
      options: {
        label: "",
        items: [
          { key: "Mostar", value: "true" },
          { key: "Não mostrar", value: "false" },
        ],
      },
    },
  ],
};

const showLogOption = {
  title: "Mostrar registro",
  fields: [
    {
      key: "log",
      title: "Mostar registro",
      type: "radio",
      default: "false",
      options: {
        label: "",
        items: [
          { key: "Sim", value: "true" },
          { key: "Não", value: "false" },
        ],
      },
    },
  ],
};

const galleryOption = {
  title: "Mostrar Galeria",
  fields: [
    {
      key: "gallery",
      title: "Mostrar Galeria",
      type: "radio",
      default: "false",
      options: {
        label: "",
        items: [
          { key: "Sim", value: "true" },
          { key: "Não", value: "false" },
        ],
      },
    },
  ],
};

// expressions
const modelExpression = {
  key: "model",
  title: "Modelo",
  namespace: "expressions",
  code: true,
};

const modelExpressionDescription = {
  key: "modeExpressionDescription",
  title: "Descrição da modelo",
};

const visibleExpression = {
  key: "visible",
  title: "Visibilidade",
  namespace: "expressions",
  code: true,
};

const validExpression = {
  key: "valid",
  title: "Validação",
  namespace: "expressions",
  code: true,
};

const optionExpression = {
  key: "options",
  title: "Opções",
  namespace: "expressions",
  code: true,
};

const logExpression = {
  key: "log",
  title: "Registro",
  namespace: "expressions",
  code: true,
};

const defaultOptions = [
  keyConfig,
  requiredOption,
  readOnlyOption,
  enableApostilleOption,
  tooltipOption,
  {
    title: "Expressões",
    submenu: [
      modelExpression,
      modelExpressionDescription,
      visibleExpression,
      validExpression,
      optionExpression,
    ],
  },
];

type FieldMenuOptions = {
  title: string;
  submenu?: FieldMenuOptions[];
  code?: boolean;
  fields?: any[];
  key?: string;
  namespace?: string;
  tooltip?: string;
  type?: "object";
};

const fieldEditOptions: {
  [key: string]: FieldMenuOptions[];
} = {
  title: [tooltipOption, visibleExpression],
  subtitle: [tooltipOption, visibleExpression],
  input: [
    ...defaultOptions,
    {
      title: "Especificos",
      submenu: [
        {
          title: "Tipo",
          fields: [
            {
              key: "type",
              title: "Tipo do campo",
              type: "radio",
              default: "text",
              options: {
                label: "",
                items: [
                  { key: "Texto", value: "text" },
                  { key: "Área", value: "area" },
                  { key: "Moeda", value: "currency" },
                  { key: "Porcentagem", value: "percentage" },
                ],
              },
            },
          ],
        },
        { key: "decimalScale", title: "Casas decimais" },
        placeholderOption,
        { key: "mask", title: "Máscara" },
      ],
    },
  ],
  textarea: [...defaultOptions, placeholderOption],
  select: [...defaultOptions, placeholderOption, itemsOption],
  radio: [...defaultOptions, itemsOption],
  checkbox: [...defaultOptions, itemsOption],
  upload: [
    keyConfig,
    requiredOption,
    enableApostilleOption,
    tooltipOption,
    {
      title: "Expressões",
      submenu: [
        modelExpression,
        modelExpressionDescription,
        visibleExpression,
        validExpression,
      ],
    },
    {
      title: "Específicos",
      submenu: [galleryOption],
    },
  ],
  link: [
    keyConfig,
    enableApostilleOption,
    tooltipOption,
    {
      title: "Expressões",
      submenu: [
        {
          key: "model",
          title: "Modelo",
          namespace: "expressions",
          code: true,
          tooltip: `Retorno esperado <b>{ from: "VINCULADO", to: "VINCULANTE", by: "TIPO_DE_VINCULO" }</b>`,
        },
        validExpression,
      ],
    },
  ],
  integration: [
    keyConfig,
    enableApostilleOption,
    tooltipOption,
    {
      title: "Expressões",
      submenu: [
        {
          key: "integration",
          title: "Integração",
          namespace: "expressions",
          code: true,
          tooltip: `Retorno esperado <b>{ method: "GET", url: "ex.: https://api.com/api/v1/resource", headers: {} }</b>`,
        },
        validExpression,
        logExpression,
        showLogOption,
      ],
    },
  ],
  map: [...defaultOptions, { key: "layers", title: LAYERS }],
  block: [
    keyConfig,
    enableApostilleOption,
    tooltipOption,
    {
      title: "Expressões",
      submenu: [
        modelExpression,
        modelExpressionDescription,
        visibleExpression,
        validExpression,
        optionExpression,
      ],
    },
    toggleOptions,
  ],
  preset: [
    { key: "key", title: "Chave Personalizada" },
    enableApostilleOption,
    {
      title: "Expressões",
      submenu: [visibleExpression, validExpression],
    },
  ],
  array: [
    keyConfig,
    readOnlyOption,
    enableApostilleOption,
    tooltipOption,
    {
      title: "Expressões",
      submenu: [
        modelExpression,
        modelExpressionDescription,
        visibleExpression,
        validExpression,
      ],
    },
    { key: "layout", title: "Layout" },
  ],
  table: [
    keyConfig,
    enableApostilleOption,
    tooltipOption,
    {
      title: "Expressões",
      submenu: [
        modelExpression,
        modelExpressionDescription,
        visibleExpression,
        validExpression,
      ],
    },
    {
      title: "Especificos",
      submenu: [
        { key: "table", title: "Tabela", type: "object" },
        { key: "columns", title: "Número de Colunas" },
        { key: "width", title: "Largura" },
      ],
    },
  ],
  // used atDocumentEditor & PlateEditor
  documentMap: [
    { key: "layers", title: LAYERS },
    { key: "source", title: "Caminho do GeoJSON" },
  ],
};
