import React, { FormEvent, useContext, useEffect, useState } from "react";
import { FaPlus, FaTrash } from "react-icons/fa";
import { Input, SL } from "../../components";
import { IconButton, Spinner } from "@chakra-ui/react";
import EditableHeader from "../../components/EditableHeader";
import { FunctionsConfig, FunctionsList } from "./components/FunctionsList";
import { AddFunction } from "./components/AddFunction";
import {
  createFunction,
  deleteFunction,
  getFunction,
  listFunctions,
  updateFunction,
} from "./api";
import { HotkeyContext } from "../../reducers/hotkeys.reducer";
import Editor from "@monaco-editor/react";

export type FunctionConfig = {
  id?: string;
  group: string;
  title: string;
  description: string;
  key: string;
  value: string;
};

export const Functions: React.FC = () => {
  const hotkeyContext = useContext(HotkeyContext);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [functions, setFunctions] = useState<FunctionsConfig>({});
  const [selectedFunction, setSelectedFunction] =
    useState<FunctionConfig | null>(null);
  const [addingFunction, setAddingFunction] = useState(false);

  const fetchFunctions = async () => {
    setFunctions(await listFunctions());
    setLoading(false);
  };

  useEffect(() => {
    fetchFunctions();
  }, []);

  useEffect(() => {
    hotkeyContext.dispatch({
      type: "SET_HOTKEY",
      payload: {
        N: (e) => !loading && handleAddFunctionForm(e),
        S: () => !loading && handleSaveFunction(),
      },
    });

    return () => {
      hotkeyContext.dispatch({
        type: "UNSET_HOTKEY",
        delete: ["N", "S"],
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, selectedFunction]);

  const searchCallback = (search: string) => {
    setSearch(search);
  };

  const selectFunctionCallback = async (funConfig: FunctionConfig) => {
    if (funConfig !== undefined && funConfig.id) {
      setLoading(true);
      const fun = await getFunction(funConfig.id);
      setSelectedFunction(fun);
      setAddingFunction(false);
      setLoading(false);
    }
  };

  const handleSetFunction = (key: string, value: any) => {
    if (selectedFunction !== null) {
      setSelectedFunction((prev: any) => ({
        ...prev,
        [key]: value,
      }));
    }
  };

  const handleAddFunctionForm = (e: FormEvent | undefined) => {
    e?.preventDefault();
    setSelectedFunction(null);
    setAddingFunction(true);
  };

  const handleSaveFunction = async () => {
    if (selectedFunction !== null && selectedFunction.id) {
      setLoading(true);
      await updateFunction(selectedFunction.id, {
        group: selectedFunction.group,
        title: selectedFunction.title,
        description: selectedFunction.description,
        key: selectedFunction.key,
        value: selectedFunction.value,
      });
      await fetchFunctions();
      setLoading(false);
    }
  };

  const handleAddPerset = async (newFunction: FunctionConfig) => {
    setLoading(true);

    const fun = {
      group: newFunction.group,
      title: newFunction.title,
      description: newFunction.description,
      key: newFunction.key,
      value: newFunction.value,
    };

    await createFunction(fun);
    await fetchFunctions();
    setAddingFunction(false);
    setLoading(false);
  };

  const handleRemoveFunction = async () => {
    if (selectedFunction !== null && selectedFunction.id) {
      setLoading(true);

      const response = window.confirm(
        "Tem certeza que deseja remover esta função?"
      );

      if (!response) {
        setLoading(false);
        return;
      }

      await deleteFunction(selectedFunction.id);
      await fetchFunctions();
      setSelectedFunction(null);
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col space-y-6 mb-24 px-6">
      <h1 className="text-2xl md:text-3xl font-black mb-2 text-center">
        Funções
      </h1>
      <div className="text-end">
        <button
          className="bg-green-600 hover:bg-green-700 text-white px-4 py-2.5 px-8 text-lg rounded-lg disabled:opacity-80"
          onClick={handleAddFunctionForm}
          disabled={loading}
        >
          <FaPlus className="inline" /> Função <SL bg="green.400">N</SL>
        </button>
      </div>
      <div className="flex flex-grow border-2 rounded-md">
        <div className="flex flex-col space-y-6 p-6 w-3/12 border-r-2">
          <div>
            <Input
              type="text"
              placeholder="Nome do do função"
              size="lg"
              className="flex-grow"
              value={search}
              onChange={(e) => searchCallback(e.target.value)}
            />
          </div>
          <div className="mx-0 px-0">
            <FunctionsList
              functions={functions}
              search={search}
              onClick={selectFunctionCallback}
            ></FunctionsList>
          </div>
        </div>
        <div className="flex flex-col space-y-6 p-6 w-9/12">
          {loading && (
            <div className="pt-10 text-center">
              <Spinner size="xl" />
            </div>
          )}
          {selectedFunction === null && addingFunction && (
            <AddFunction onAddFunction={handleAddPerset}></AddFunction>
          )}
          {!loading && selectedFunction !== null && (
            <>
              <div className="flex flex-col md:w-1/2 mx-auto">
                <>
                  <EditableHeader
                    value={selectedFunction.title}
                    onTextChange={(text) => handleSetFunction("title", text)}
                    className="text-xl md:text-3xl font-black mb-2 text-center"
                  />
                  <EditableHeader
                    value={selectedFunction.description}
                    onTextChange={(text) =>
                      handleSetFunction("description", text)
                    }
                    className="mb-2 text-center"
                  />
                </>
              </div>
              <div className="flex flex-col w-2/3 mx-auto space-y-2">
                <label>Chave</label>
                <Input
                  type="text"
                  placeholder="Chave"
                  size="lg"
                  value={selectedFunction.key}
                  onChange={(e) => handleSetFunction("key", e.target.value)}
                />
                <label>Funções</label>
                <Editor
                  height="30vh"
                  defaultLanguage="javascript"
                  defaultValue={selectedFunction.value}
                  theme="vs-dark"
                  onChange={(value) => handleSetFunction("value", value ?? "")}
                />
              </div>
              <div className="pt-6 text-center">
                <button
                  type="submit"
                  className="bg-yellow-600 hover:bg-yellow-700 text-white text-lg py-2.5 px-8 rounded-lg disabled:opacity-80"
                  onClick={handleSaveFunction}
                  disabled={loading}
                >
                  Salvar <SL bg="yellow.500">S</SL>
                </button>
              </div>
              <div className="flex flex-col space-y-4 text-end">
                <div>
                  <span className="font-bold">Remover</span>
                  <IconButton
                    size={"lg"}
                    aria-label="Remove Function"
                    icon={<FaTrash />}
                    onClick={handleRemoveFunction}
                    className="ml-2"
                    disabled={loading}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
