import React, { useContext, useEffect, useState } from "react";
import { SL } from "../../components/ShortcutLabel";
import { HotkeyContext } from "../../reducers/hotkeys.reducer";
import { MyData } from "./MyData";
import { StyleContext } from "../../reducers";
import { MyRepresentations } from "./MyRepresentation";
import { MyRepresentatives } from "./MyRepresentatives";

export function Profile(): JSX.Element {
  const styleContext = useContext(StyleContext);
  const hotkeyContext = useContext(HotkeyContext);
  const [subpage, setSubpage] = useState<string>("mydata");

  const menus = [
    { name: "Meus dados", link: "mydata", key: "1" },
    {
      name: "Minhas representações",
      link: "myrepresentations",
      key: "2",
    },
    {
      name: "Meus representantes",
      link: "myrepresentatives",
      key: "3",
    },
  ];

  useEffect(() => {
    hotkeyContext.dispatch({
      type: "SET_HOTKEY",
      payload: {
        1: () => {
          setSubpage("mydata");
        },
        2: () => {
          setSubpage("myrepresentations");
        },
        3: () => {
          setSubpage("myrepresentatives");
        },
      },
    });

    return () => {
      hotkeyContext.dispatch({
        type: "UNSET_HOTKEY",
        delete: ["1", "2"],
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col space-y-2 mb-20">
      <h1 className="text-2xl md:text-3xl font-black mb-6 text-center">
        Meu Perfil{" "}
      </h1>

      <div className="flex flex-grow border-2 rounded-md mx-6">
        <div className="flex flex-col py-4 w-3/12 border-r-2">
          {menus.map((menu) => (
            <div
              className={`px-6 py-2.5 ${
                styleContext.state.backgroundColor === "#000000"
                  ? "hover:bg-gray-600"
                  : "hover:bg-gray-200"
              }`}
              key={menu.key}
            >
              <button
                onClick={() => setSubpage(menu.link)}
                className="flex justify-between w-full items-center"
              >
                {menu.name} <SL>{menu.key}</SL>
              </button>
            </div>
          ))}
        </div>
        <div className="flex flex-col space-y-6 p-6 w-9/12">
          {subpage === "mydata" && <MyData />}
          {subpage === "myrepresentations" && <MyRepresentations />}
          {subpage === "myrepresentatives" && <MyRepresentatives />}
        </div>
      </div>
    </div>
  );
}
