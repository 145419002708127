import React from 'react';
import { WMSTileLayer } from 'react-leaflet';

interface GeoSampaTileLayerProps {
  layerType: string;
}

export const GeoSampaTileLayer: React.FC<GeoSampaTileLayerProps> = ({ layerType }) => {
  return (
    <>
      {layerType === 'politico' && (
        <WMSTileLayer
          key='politico'
          url="https://geosampa.prefeitura.sp.gov.br/PaginasPublicas/map.geo"
          layers="geoportal:MapaBase_Politico_v2"
          format="image/png"
          maxNativeZoom={18} 
          maxZoom={20}
          transparent={true}
          version="1.1.1"
        />
      )}
      {layerType === 'ortofoto' && (
        <WMSTileLayer
          key='ortofoto'
          url="https://geosampa.prefeitura.sp.gov.br/PaginasPublicas/map.geo?tipoServico=WMS_RASTER"
          layers="geoportal:ORTO_RGB_2020"
          format="image/png"
          maxNativeZoom={18}
          maxZoom={20}
          transparent={true}
          version="1.1.1"
        />
      )}
      <WMSTileLayer
        url="https://geosampa.prefeitura.sp.gov.br/PaginasPublicas/map.geo"
        layers="geoportal:area_contexto_v2"
        format="image/png"
        transparent={true}
        maxNativeZoom={18}
        maxZoom={20}
        version="1.1.1"
      />
    </>
  );
};
