import {
  Stepper,
  Step as StepContainer,
  useSteps,
  Box,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
} from "@chakra-ui/react";
import { Field } from "../Field";
import { HelpTooltipClickable, SL } from "../../../../components";
import { HotkeyContext } from "../../../../reducers";
import { useContext, useEffect, useState } from "react";
import { visibleCallback } from "../utils/expressions";

export type FieldStepProps = {
  field: Field[];
  general: GeneralContext;
  value: any;
  valid: any;
  onChange: (key: string, value: any) => void;
  onValidChange: (key: string, valid: any) => void;
};

export const Step: React.FC<FieldStepProps> = ({
  field,
  general,
  value,
  valid,
  onChange,
  onValidChange,
}) => {
  const hotkeyContext = useContext(HotkeyContext);
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: field?.length ?? 0,
  });
  const [visible, setVisible] = useState<{ [key: string]: boolean }>({});
  const [nextStepDisabled, setNextStepDisabled] = useState(false);
  const [lastVisibleStep, setLastVisibleStep] = useState(field.length - 1);

  useEffect(() => {
    setNextStepDisabled(!nextStepEnabled());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valid, activeStep]);

  useEffect(() => {
    hotkeyContext.dispatch({
      type: "SET_HOTKEY",
      payload: {
        right: () => {
          setNextStep(activeStep);
        },
        left: () => {
          setPreviousStep(activeStep);
        },
      },
    });

    return () => {
      hotkeyContext.dispatch({
        type: "UNSET_HOTKEY",
        delete: ["right", "left"],
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field, valid, activeStep, nextStepDisabled]);

  useEffect(() => {
    const visibleObj: { [key: string]: boolean } = {};
    let lastVisibleIndex = 0;

    const updateVisibility = (f: any, index: number) => {
      if (f.expressions?.visible) {
        visibleCallback(f, value, general, valid, (visible: boolean) => {
          visibleObj[f.key] = visible;
          if (visible !== false) {
            lastVisibleIndex = index;
          }
        });
      } else {
        lastVisibleIndex = index;
      }
    };

    field.forEach((f, index) => updateVisibility(f, index));

    setVisible(visibleObj);
    setLastVisibleStep(lastVisibleIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const nextStepEnabled = () => {
    const stepKey = field[activeStep].key;

    const checkIfIsValid = (obj: any): boolean => {
      if (Array.isArray(obj) && obj.length === 0) {
        return false;
      }

      if (typeof obj === "boolean") {
        return obj;
      }
      if (Array.isArray(obj)) {
        return obj.every(checkIfIsValid);
      }
      if (typeof obj === "object" && obj !== null) {
        return Object.values(obj).every(checkIfIsValid);
      }
      return true;
    };

    return checkIfIsValid(valid?.[stepKey]);
  };

  const setPreviousStep = (step: number) => {
    let previousStepIndex = step - 1;

    while (
      previousStepIndex >= 0 &&
      visible[field[previousStepIndex].key] === false
    ) {
      previousStepIndex--;
    }

    if (previousStepIndex >= 0) {
      setActiveStep(previousStepIndex);
    }

    onValidChange(field[field.length - 1].key, {
      ...valid[field[field.length - 1].key],
      $complete: undefined,
    });
  };

  const setNextStep = (step: number) => {
    let nextStepIndex = step + 1;

    while (
      field[nextStepIndex] &&
      visible[field[nextStepIndex].key] === false
    ) {
      nextStepIndex++;
    }

    if (!nextStepDisabled && nextStepIndex < (field?.length ?? 1)) {
      setActiveStep(nextStepIndex);
    }

    if (nextStepIndex === (field?.length ?? 1)) {
      onValidChange(field[field.length - 1].key, {
        ...valid[field[field.length - 1].key],
        $complete: true,
      });
    }
  };

  return (
    <div className="w-full pb-6">
      <Stepper
        size="lg"
        colorScheme="red"
        orientation={window.innerWidth <= 500 ? "vertical" : "horizontal"}
        index={activeStep}
        className="overflow-x-auto"
      >
        {field?.map((f, index) =>
          visible[f.key] !== false ? (
            <StepContainer key={index}>
              <StepIndicator>
                <StepStatus
                  complete={<StepIcon />}
                  incomplete={<StepNumber />}
                  active={<StepNumber />}
                />
              </StepIndicator>
              <Box className={window.innerWidth <= 500 ? "mt-2" : ""}>
                <StepTitle className="flex items-center space-x-2">
                  <div>{f.options.title} </div>
                  {f.options.tooltip && (
                    <HelpTooltipClickable tooltip={f.options.tooltip} />
                  )}
                </StepTitle>
              </Box>
              <StepSeparator />
            </StepContainer>
          ) : (
            <></>
          )
        )}
      </Stepper>
      <div
        className="flex justify-center mt-10"
        key={`step-${field[activeStep].key}-${activeStep}`}
      >
        <Field
          parent={field[activeStep]}
          context={value}
          validContext={valid}
          general={general}
          field={field[activeStep]}
          value={value?.[field[activeStep].key]}
          valid={valid?.[field[activeStep].key]}
          onChange={(value) => {
            onChange(field[activeStep].key, value);
          }}
          onValidChange={(valid: any) => {
            onValidChange(field[activeStep].key, valid);
          }}
        ></Field>
      </div>
      <div className="flex justify-end mt-6">
        {activeStep > 0 && (
          <button
            className="hover:bg-gray-200 text-lg px-6 py-2 rounded-xl disabled:opacity-80 mr-5"
            onClick={() => setPreviousStep(activeStep)}
          >
            Anterior <SL>←</SL>
          </button>
        )}
        {activeStep !== lastVisibleStep && (
          <>
            <button
              className={`bg-yellow-600 hover:bg-yellow-700 text-white text-lg px-6 py-2 rounded-md ${
                nextStepDisabled ? "disabled:opacity-80 cursor-not-allowed" : ""
              }`}
              onClick={() => setNextStep(activeStep)}
              disabled={nextStepDisabled}
            >
              Próximo <SL bg="yellow.500">→</SL>
            </button>
          </>
        )}
        {activeStep === lastVisibleStep && (
          <button
            type="submit"
            className={`bg-yellow-600 hover:bg-yellow-700 text-white text-lg px-6 py-2 rounded-md disabled:opacity-80 ${
              nextStepDisabled ? "disabled:opacity-80 cursor-not-allowed" : ""
            }`}
            onClick={() => setNextStep(activeStep)}
            disabled={nextStepDisabled}
          >
            Solicitar <SL bg="yellow.500">S</SL>
          </button>
        )}
      </div>
    </div>
  );
};
