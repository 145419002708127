import React, { useEffect } from "react";
import { FieldBlockView } from "./FieldBlockView";
import {
  Checkbox,
  Integration,
  Label,
  Link,
  Map,
  Paragraph,
  TableView,
  Title,
} from "./fields";
import { HelpTooltipClickable } from "../../../components";
import { downloadFile } from "./utils/utils";
import { optionCallback, visibleCallback } from "./utils/expressions";
import { InputFieldTypes } from "./utils/types";
import { VersionsMenu } from "../components/VersionsMenu";

export type FieldViewProps = {
  context: any;
  general: GeneralContext;
  field: Field;
  value: any;
};

export type FieldBase = {
  field: Field;
  value: any;
  general: GeneralContext;
};

export const FIELD_COMPONENT_MAP: {
  [field: string]: (...args: any[]) => JSX.Element;
} = {
  input: ({ value }: FieldBase) => <span>{value ?? "Não informado"}</span>,
  textarea: ({ value }: FieldBase) => <span>{value ?? "Não informado"}</span>,
  select: ({ value }: FieldBase) => <span>{value ?? "Não informado"}</span>,
  checkbox: ({ field, value, general }: FieldBase) => (
    <Checkbox
      field={field}
      key={field.key}
      onChange={() => {}}
      options={{ ...field.options, readOnly: true } as any}
      value={value}
      general={general}
    />
  ),
  radio: ({ value }: FieldBase) => <span>{value ?? "Não informado"}</span>,
  upload: ({ field, value }: FieldBase) => {
    return (
      value?.map((file: string) => (
        <div
          className="cursor-pointer"
          onClick={() => downloadFile(field.options.dir, file)}
        >
          {file}
        </div>
      )) ?? "Não informado"
    );
  },
  map: ({ field, value }: FieldBase) => (
    <Map key={field.key} options={field.options as any} value={value} />
  ),
  table: ({ field, value }: FieldBase) => (
    <TableView
      table={field.options.table as Field[][]}
      options={field.options as any}
      value={value}
    />
  ),
};

export const FieldView: React.FC<FieldViewProps> = ({
  context,
  general,
  field,
  value,
}): JSX.Element => {
  const [visible, setVisible] = React.useState(
    field.expressions?.visible ? false : field.options.visible !== false
  );
  const [options, setOptions] = React.useState<FieldOptions>(
    field.options ?? {}
  );
  const [version, setVersion] = React.useState<{ version: number }>({
    version:
      general?.$history?.length > 0
        ? general?.$history[general.$history.length - 1].version
        : -1,
  });

  useEffect(() => {
    visibleCallback(field, context, general, {}, setVisible);
    optionCallback(field, context, general, {}, setOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context]);

  const FieldComponent = FIELD_COMPONENT_MAP[field.type] || (() => <></>);
  const dynamicVersionValue =
    general?.$history && general?.$history?.length > 0
      ? general?.$history.find((v: any) => v.version === version.version)?.value
      : value;

  if (visible) {
    return (
      <div className="w-full mb-6">
        {field.type === "title" && (
          <Title key={field.key} options={field.options as any} />
        )}
        {field.type === "subtitle" && (
          <Paragraph key={field.key} options={field.options as any} />
        )}
        {field.type === "link" && <Link value={value} />}
        {field.type === "integration" && (
          <Integration field={field} options={options} value={value} />
        )}
        {InputFieldTypes.includes(field.type) && (
          <>
            <div className="flex items-start space-x-4 font-bold">
              {field.options.label && (
                <Label
                  key={field.key}
                  context={context}
                  options={field.options as any}
                />
              )}
              {field.options.tooltip && (
                <HelpTooltipClickable tooltip={field.options.tooltip} />
              )}
              {general?.$history && general?.$history?.length > 0 && (
                <VersionsMenu
                  defaultVersion={version}
                  versions={general.$history}
                  callback={(version) => {
                    setVersion({ version });
                  }}
                ></VersionsMenu>
              )}
            </div>
            {general?.$history && general?.$history?.length > 0 ? (
              <FieldComponent
                field={{ ...field, options }}
                value={dynamicVersionValue}
              />
            ) : (
              <FieldComponent field={{ ...field, options }} value={value} />
            )}
          </>
        )}
        {field.type === "block" && field.block && (
          <>
            {field.key !== "root" && (
              <div className="mb-4">
                <h1 className="text-3xl font-black">{field.options.title}</h1>
              </div>
            )}
            <FieldBlockView
              field={field.block}
              general={general}
              value={value}
            ></FieldBlockView>
          </>
        )}
        {field.type === "preset" && field.preset && (
          <>
            <FieldBlockView
              field={field.preset}
              general={general}
              value={value}
            />
          </>
        )}
        {field.type === "array" && field.block && (
          <>
            <div className="flex items-start space-x-4 font-bold">
              {field.options.label && (
                <Label
                  key={field.key}
                  context={context}
                  options={field.options as any}
                />
              )}
              {field.options.tooltip && (
                <HelpTooltipClickable tooltip={field.options.tooltip} />
              )}
              {general?.$history && general?.$history?.length > 0 && (
                <VersionsMenu
                  defaultVersion={version}
                  versions={general.$history}
                  callback={(version) => {
                    setVersion({ version });
                  }}
                ></VersionsMenu>
              )}
            </div>
            {dynamicVersionValue?.map((v: any, index: number) => {
              return (
                <div
                  className={`border p-6 rounded-md ${index > 0 ? "mt-4" : ""}`}
                >
                  <FieldBlockView
                    field={field.block ?? []}
                    general={{
                      ...general,
                      $history: general?.$history?.[index],
                    }}
                    value={v}
                  />
                </div>
              );
            })}
          </>
        )}
      </div>
    );
  } else {
    return <></>;
  }
};
