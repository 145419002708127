import React from "react";
import { FaExclamationTriangle, FaInfoCircle } from "react-icons/fa";
import { MdCheckCircle, MdError } from "react-icons/md";

export const RenderValidState = ({
  validState,
}: {
  validState: ValidState | boolean;
}) => {
  const ValidStateIcons = {
    valid: <MdCheckCircle color="green" size={24} />,
    invalid: <MdError color="red" size={24} />,
    warning: <FaExclamationTriangle color="orange" size={24} />,
    information: <FaInfoCircle color="blue" size={24} />,
  };

  if (typeof validState === "boolean") return null;

  if (validState.type) {
    return (
      <div className="flex space-x-4 border rounded-md p-4 mt-4">
        <div>{ValidStateIcons[validState.type]}</div>
        <span>{validState.message}</span>
      </div>
    );
  }
  return null;
};
