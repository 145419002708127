import React, { FormEvent, useContext, useEffect, useState } from "react";
import { FaPlus, FaTrash } from "react-icons/fa";
import { Input, SL } from "../../components";
import { IconButton, Spinner } from "@chakra-ui/react";
import EditableHeader from "../../components/EditableHeader";
import {
  createSecret,
  deleteSecret,
  getSecret,
  listSecrets,
  updateSecret,
} from "./api";
import { HotkeyContext } from "../../reducers/hotkeys.reducer";
import { AddSecret } from "./components/AddSecret";

export type SecretConfig = {
  id?: string;
  title: string;
  description: string;
  value: string;
};

export const Secrets: React.FC = () => {
  const hotkeyContext = useContext(HotkeyContext);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [secrets, setSecrets] = useState<SecretConfig[]>([]);
  const [selectedSecret, setSelectedSecret] = useState<SecretConfig | null>(
    null
  );
  const [addingSecret, setAddingSecret] = useState(false);

  const fetchSecrets = async () => {
    setSecrets(await listSecrets());
    setLoading(false);
  };

  useEffect(() => {
    fetchSecrets();
  }, []);

  useEffect(() => {
    hotkeyContext.dispatch({
      type: "SET_HOTKEY",
      payload: {
        N: (e) => !loading && handleAddSecretForm(e),
      },
    });

    return () => {
      hotkeyContext.dispatch({
        type: "UNSET_HOTKEY",
        delete: ["N"],
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const searchCallback = (search: string) => {
    setSearch(search);
  };

  const selectSecretCallback = async (secretConfig: SecretConfig) => {
    if (secretConfig !== undefined && secretConfig.id) {
      setLoading(true);
      const secret = await getSecret(secretConfig.id);
      setSelectedSecret(secret);
      setAddingSecret(false);
      setLoading(false);
    }
  };

  const handleSetSecret = (key: string, value: any) => {
    if (selectedSecret !== null) {
      setSelectedSecret((prev: any) => ({
        ...prev,
        [key]: value,
      }));
    }
  };

  const handleAddSecretForm = (e: FormEvent | undefined) => {
    e?.preventDefault();
    setSelectedSecret(null);
    setAddingSecret(true);
  };

  const handleSaveSecret = async () => {
    if (selectedSecret !== null && selectedSecret.id) {
      setLoading(true);
      await updateSecret(selectedSecret.id, {
        title: selectedSecret.title,
        description: selectedSecret.description,
        value: selectedSecret.value,
      });
      await fetchSecrets();
      setLoading(false);
    }
  };

  const handleAddSecret = async (newSecret: SecretConfig) => {
    setLoading(true);

    const secret = {
      title: newSecret.title,
      description: newSecret.description,
      value: newSecret.value,
    };

    await createSecret(secret);
    await fetchSecrets();
    setAddingSecret(false);
    setLoading(false);
  };

  const handleRemoveSecret = async () => {
    if (selectedSecret !== null && selectedSecret.id) {
      setLoading(true);

      const response = window.confirm(
        "Tem certeza que deseja remover este secret?"
      );

      if (!response) {
        setLoading(false);
        return;
      }

      await deleteSecret(selectedSecret.id);
      await fetchSecrets();
      setSelectedSecret(null);
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col space-y-6 mb-24 px-6">
      <h1 className="text-2xl md:text-3xl font-black mb-2 text-center">
        Secrets
      </h1>
      <div className="text-end">
        <button
          className="bg-green-600 hover:bg-green-700 text-white px-4 py-2.5 px-8 text-lg rounded-lg disabled:opacity-80"
          onClick={handleAddSecretForm}
          disabled={loading}
        >
          <FaPlus className="inline" /> Secret <SL bg="green.400">N</SL>
        </button>
      </div>
      <div className="flex flex-grow border-2 rounded-md">
        <div className="flex flex-col space-y-6 p-6 w-3/12 border-r-2">
          <div>
            <Input
              type="text"
              placeholder="Nome do do secret"
              size="lg"
              className="flex-grow"
              value={search}
              onChange={(e) => searchCallback(e.target.value)}
            />
          </div>
          <div className="mx-0 px-0">
            {secrets
              .filter(
                (secret) =>
                  search.trim().length === 0 || secret.title.includes(search)
              )
              .sort((a, b) => a.title.localeCompare(b.title))
              .map((secret) => (
                <div
                  key={"preset-item-" + secret.id}
                  className="px-4 py-2 hover:bg-gray-200"
                >
                  <button
                    onClick={() => selectSecretCallback(secret)}
                    className="flex justify-between w-full items-center"
                  >
                    {secret.title}
                  </button>
                </div>
              ))}
          </div>
        </div>
        <div className="flex flex-col space-y-6 p-6 w-9/12">
          {loading && (
            <div className="pt-10 text-center">
              <Spinner size="xl" />
            </div>
          )}
          {selectedSecret === null && addingSecret && (
            <AddSecret onAddSecret={handleAddSecret}></AddSecret>
          )}
          {!loading && selectedSecret !== null && (
            <>
              <div className="flex flex-col md:w-1/2 mx-auto">
                <>
                  <EditableHeader
                    value={selectedSecret.title}
                    onTextChange={(text) => handleSetSecret("title", text)}
                    className="text-xl md:text-3xl font-black mb-2 text-center"
                  />
                  <EditableHeader
                    value={selectedSecret.description}
                    onTextChange={(text) =>
                      handleSetSecret("description", text)
                    }
                    className="mb-2 text-center"
                  />
                </>
              </div>
              <div className="flex flex-col w-2/3 mx-auto space-y-2">
                <label>ID</label>
                <Input
                  type="text"
                  placeholder="ID"
                  size="lg"
                  value={selectedSecret.id}
                  disabled={true}
                />
                <label>Valor</label>
                <Input
                  type="text"
                  placeholder="Valor"
                  size="lg"
                  value={selectedSecret.value}
                  onChange={(e) => handleSetSecret("value", e.target.value)}
                />
              </div>
              <div className="pt-6 text-center">
                <button
                  type="submit"
                  className="bg-yellow-600 hover:bg-yellow-700 text-white text-lg py-2.5 px-8 rounded-lg disabled:opacity-80"
                  onClick={handleSaveSecret}
                  disabled={loading}
                >
                  Salvar <SL bg="yellow.500">S</SL>
                </button>
              </div>
              <div className="text-end">
                <span className="font-bold">Remover secret</span>
                <IconButton
                  size={"lg"}
                  aria-label="Remove Secret"
                  icon={<FaTrash />}
                  onClick={handleRemoveSecret}
                  className="ml-2"
                  disabled={loading}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
