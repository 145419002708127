import React, { useState, CSSProperties } from "react";

interface EditableHeaderProps {
  html?: string;
  value?: string;
  onTextChange?: (newText: string) => void;
  className?: string;
  style?: CSSProperties;
}

const EditableHeader: React.FC<EditableHeaderProps> = ({
  html,
  value,
  onTextChange,
  className,
  style,
}) => {
  const [showHtml, setShowHtml] = useState(false);

  const handleBlur = (e: React.FocusEvent<HTMLElement>) => {
    setShowHtml(false);

    const newText = e.target.innerText;

    if (onTextChange) {
      onTextChange(newText.length === 0 ? "Insira um texto" : newText);
    }
  };

  const handleClick = () => {
    setShowHtml(true);
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLHeadingElement>) => {
    e.preventDefault();
    const text = e.clipboardData.getData("text/plain");
    document.execCommand("insertText", false, text);
  };

  return (
    <div>
      {html && (
        <h1
          contentEditable
          onBlur={handleBlur}
          onClick={handleClick}
          onPaste={handlePaste}
          className={className}
          style={style}
        >
          {showHtml ? (
            html
          ) : (
            <span dangerouslySetInnerHTML={{ __html: html ?? "" }}></span>
          )}
        </h1>
      )}
      {!html && (
        <h1
          contentEditable
          onBlur={handleBlur}
          onPaste={handlePaste}
          className={"w-full " + className}
          style={style}
        >
          {value}
        </h1>
      )}
    </div>
  );
};

export default EditableHeader;
