import React, { useContext, useEffect, useState } from "react";
import Papa from "papaparse";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  IconButton,
} from "@chakra-ui/react";
import { FaTrash, FaPlus, FaUpload } from "react-icons/fa";
import EditableHeader from "../../../components/EditableHeader";
import { StyleContext } from "../../../reducers";

interface Row {
  [key: string]: string;
}

interface TableData {
  headers: string[];
  rows: Row[];
}

interface DynamicTableProps {
  value: string;
  onChange: (data: string) => void;
}

const DynamicTable: React.FC<DynamicTableProps> = ({ value, onChange }) => {
  let valueParsed: any = {};

  try {
    valueParsed = JSON.parse(value);
  } catch (e) {}

  const [data, setData] = useState<TableData>(
    valueParsed.headers ? valueParsed : { headers: [], rows: [] }
  );
  const styleContex = useContext(StyleContext);

  useEffect(() => {
    onChange(JSON.stringify(data));
  }, [data, onChange]);

  const handleHeaderChange = (index: number, value: string) => {
    const oldHeader = data.headers[index];
    const updatedHeaders = [...data.headers];
    updatedHeaders[index] = value;

    const updatedRows = data.rows.map((row) => {
      const newRow = { ...row, [value]: row[oldHeader] };
      delete newRow[oldHeader];
      return newRow;
    });

    setData({ headers: updatedHeaders, rows: updatedRows });
  };

  const handleCellChange = (
    rowIndex: number,
    header: string,
    value: string
  ) => {
    const updatedRows = [...data.rows];
    updatedRows[rowIndex][header] = value;
    setData({ ...data, rows: updatedRows });
  };

  const addColumn = async () => {
    const newHeader = await prompt("Nome da coluna");
    if (newHeader) {
      setData((prevData) => ({
        headers: [...prevData.headers, newHeader],
        rows: prevData.rows.map((row) => ({ ...row, [newHeader]: "" })),
      }));
    }
  };

  const addRow = () => {
    const newRow: Row = data.headers.reduce((acc, header) => {
      acc[header] = "";
      return acc;
    }, {} as Row);
    setData((prevData) => ({
      ...prevData,
      rows: [...prevData.rows, newRow],
    }));
  };

  const deleteColumn = (index: number) => {
    const headerToDelete = data.headers[index];
    setData((prevData) => ({
      headers: prevData.headers.filter((_, i) => i !== index),
      rows: prevData.rows.map((row) => {
        const newRow = { ...row };
        delete newRow[headerToDelete];
        return newRow;
      }),
    }));
  };

  const deleteRow = (index: number) => {
    setData((prevData) => ({
      ...prevData,
      rows: prevData.rows.filter((_, i) => i !== index),
    }));
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      Papa.parse(file, {
        complete: (results: any) => {
          const parsedData = results.data as string[][];
          const headers = parsedData[0];
          const rows = parsedData.slice(1).map((row) => {
            return headers.reduce((acc, header, index) => {
              acc[header] = row[index] || "";
              return acc;
            }, {} as Row);
          });
          setData({ headers, rows });
        },
        header: false,
      });
    }
  };

  return (
    <>
      <div className="flex space-x-4">
        <TableContainer
          className="border rounded-md w-full"
          style={{ maxHeight: "600px", overflowY: "auto" }}
        >
          <Table>
            <Thead>
              <Tr>
                {data.headers.map((header, index) => (
                  <Th key={header}>
                    <div className="flex space-x-2 items-center">
                      <EditableHeader
                        value={header}
                        onTextChange={(text) => handleHeaderChange(index, text)}
                        style={{
                          color: styleContex.state.textColor,
                          minWidth: "50px",
                          maxWidth: "300px",
                        }}
                      />
                      <IconButton
                        aria-label="delete column"
                        icon={<FaTrash />}
                        size="xs"
                        ml={2}
                        onClick={() => deleteColumn(index)}
                      />
                    </div>
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {data.rows.map((row, rowIndex) => (
                <Tr key={rowIndex}>
                  {data.headers.map((header) => (
                    <Td key={header}>
                      <EditableHeader
                        value={row[header]}
                        onTextChange={(text) =>
                          handleCellChange(rowIndex, header, text)
                        }
                        style={{ minWidth: "30px" }}
                      />
                    </Td>
                  ))}
                  <Td>
                    <IconButton
                      aria-label="delete row"
                      icon={<FaTrash />}
                      size="xs"
                      onClick={() => deleteRow(rowIndex)}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
        <div>
          <IconButton
            aria-label="add column"
            icon={<FaPlus />}
            onClick={addColumn}
          />
        </div>
      </div>
      <div className="flex justify-center text-center p-4 w-full">
        <IconButton aria-label="add row" icon={<FaPlus />} onClick={addRow} />
      </div>
      <div className="flex justify-center text-center p-4 w-full">
        <input
          type="file"
          accept=".csv"
          onChange={handleFileUpload}
          className="hidden"
          id="csv-upload"
        />
        <label htmlFor="csv-upload">
          <IconButton aria-label="upload csv" icon={<FaUpload />} as="span" />
        </label>
      </div>
    </>
  );
};

export default DynamicTable;
