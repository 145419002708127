import React, { useContext } from "react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  Portal,
} from "@chakra-ui/react";
import { StyleContext } from "../reducers";
import { MdHelp } from "react-icons/md";

export function HelpTooltipClickable({
  tooltip,
  icon: Icon = MdHelp,
  size = "24px",
}: {
  tooltip: string;
  icon?: React.ElementType;
  size?: string;
}): JSX.Element {
  const { state } = useContext(StyleContext);

  return (
    <Popover placement="auto">
      <PopoverTrigger>
        <span>
          <Icon className="cursor-pointer" size={size} />{" "}
        </span>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          bg={state.backgroundColor}
          className="px-4 py-2.5"
          width="auto"
          maxWidth="1500px"
        >
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody dangerouslySetInnerHTML={{ __html: tooltip }} />
        </PopoverContent>
      </Portal>
    </Popover>
  );
}

export default HelpTooltipClickable;
