import React, { FormEvent, useContext, useEffect, useState } from "react";
import { FaPen, FaPlus, FaRegCopy, FaTrash } from "react-icons/fa";
import { Input, SL } from "../../components";
import { IconButton, Spinner } from "@chakra-ui/react";
import EditableHeader from "../../components/EditableHeader";
import { FormEditor } from "./components/FormEditor";
import {
  PresetConfig,
  PresetsConfig,
  PresetsList,
} from "./components/PresetList";
import { AddPreset, PresetForm } from "./components/AddPreset";
import {
  createPreset,
  deletePreset,
  duplicatePreset,
  findPresetVersion,
  findPresetVersions,
  getPreset,
  listPresets,
  updatePreset,
} from "./api";
import { HotkeyContext } from "../../reducers/hotkeys.reducer";
import { parseFunctions } from "./core/utils/parsers";
import { VersionsMenu } from "./components/VersionsMenu";

type PresetFieldProps = PresetConfig & {
  $user: UserProps;
  $environment: EnvironmentProps;
  $function: FunctionProps;
};

export const Presets: React.FC = () => {
  const hotkeyContext = useContext(HotkeyContext);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [presets, setPresets] = useState<PresetsConfig>({});
  const [selectedPreset, setSelectedPreset] = useState<PresetFieldProps | null>(
    null
  );
  const [addingPreset, setAddingPreset] = useState(false);
  const [currentVersion, setCurrentVersion] = useState<{
    version: number;
    stage?: string;
  }>({ version: 0 });
  const [versions, setVersions] = useState<
    {
      id: string;
      version: number;
      commitMessage: string;
      timestamp: string;
      createdBy: string;
    }[]
  >([]);

  const fetchPresets = async () => {
    setPresets(await listPresets());
    setLoading(false);
  };

  const fetchPresetVersion = async (version: number) => {
    setLoading(true);

    if (selectedPreset !== null && selectedPreset.id !== undefined) {
      const preset = await findPresetVersion(selectedPreset.id, version);

      setSelectedPreset((prev: any) => {
        setCurrentVersion({ version });

        return {
          ...preset,
          id: prev.id,
          $user: prev.$user,
          $environment: prev.$environment,
          $function: prev.$function,
        } as PresetFieldProps;
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchPresets();
  }, []);

  useEffect(() => {
    hotkeyContext.dispatch({
      type: "SET_HOTKEY",
      payload: {
        S: () => !loading && handleSavePreset(),
        N: (e) => !loading && handleAddPresetForm(e),
      },
    });

    return () => {
      hotkeyContext.dispatch({
        type: "UNSET_HOTKEY",
        delete: ["S", "N"],
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, selectedPreset]);

  const searchCallback = (search: string) => {
    setSearch(search);
  };

  const selectPresetCallback = async (presetConfig: PresetConfig) => {
    if (presetConfig !== undefined && presetConfig.id) {
      setLoading(true);
      const preset = await getPreset(presetConfig.id);
      const versions = await findPresetVersions(presetConfig.id);

      setVersions(versions);
      setCurrentVersion({ version: versions.length });
      setSelectedPreset(preset);
      setAddingPreset(false);
      setLoading(false);
    }
  };

  const handleSetPreset = (key: string, value: any) => {
    if (selectedPreset !== null && value !== null && value !== undefined) {
      if (key === "block") {
        const newValue: any[] = [];
        value.forEach((field: any) => {
          if (field.type === "preset") {
            newValue.push(...field.preset);
          } else {
            newValue.push(field);
          }
        });
        setSelectedPreset((prev: any) => ({
          ...prev,
          [key]: newValue,
        }));
      } else {
        setSelectedPreset((prev: any) => ({
          ...prev,
          [key]: value,
        }));
      }
    }
  };

  const handleAddPresetForm = (e: FormEvent | undefined) => {
    e?.preventDefault();
    setSelectedPreset(null);
    setAddingPreset(true);
  };

  const handleSavePreset = async () => {
    if (selectedPreset !== null && selectedPreset.id) {
      const commitMessage = await prompt(
        "Insira a mensagem de alteração da versão:"
      );

      if (!commitMessage?.trim()) {
        return;
      }

      setLoading(true);
      await updatePreset(selectedPreset.id, {
        group: selectedPreset.group,
        type: selectedPreset.type,
        title: selectedPreset.title,
        description: selectedPreset.description,
        field: selectedPreset.field,
        block: selectedPreset.block,
        commitMessage,
      });
      await fetchPresets();
      const versions = await findPresetVersions(selectedPreset.id);
      setVersions(versions);
      setCurrentVersion({ version: versions.length });
      setLoading(false);
    }
  };

  const handleAddPerset = async (newPreset: PresetForm) => {
    setLoading(true);
    const newPresetConfig = {
      field: { field: [] },
      block: { block: [] },
    };

    const preset = {
      group: newPreset.group,
      type: newPreset.type,
      title: newPreset.title,
      description: newPreset.description,
      ...newPresetConfig[newPreset.type],
    };

    await createPreset(preset);
    await fetchPresets();
    setAddingPreset(false);
    setLoading(false);
  };

  const handleRemovePreset = async () => {
    if (selectedPreset !== null && selectedPreset.id) {
      setLoading(true);

      const response = window.confirm(
        "Tem certeza que deseja remover este preset?"
      );

      if (!response) {
        setLoading(false);
        return;
      }

      await deletePreset(selectedPreset.id);
      await fetchPresets();
      setSelectedPreset(null);
      setLoading(false);
    }
  };

  const handleDuplicate = async () => {
    if (selectedPreset !== null && selectedPreset.id) {
      setLoading(true);
      await duplicatePreset(selectedPreset.id);
      await fetchPresets();
      setLoading(false);
    }
  };

  const handleCodeEditor = async () => {
    if (selectedPreset !== null && selectedPreset.id) {
      const value = await (prompt as any)(
        "Código do Preset",
        JSON.stringify(selectedPreset.block ?? selectedPreset.field, null, 4),
        [],
        { code: true }
      );

      if (selectedPreset.type === "block") {
        handleSetPreset("block", JSON.parse(value));
      } else if (selectedPreset.type === "field") {
        handleSetPreset("field", JSON.parse(value));
      }
    }
  };

  return (
    <div className="flex flex-col space-y-6 mb-24 px-6">
      <h1 className="text-2xl md:text-3xl font-black mb-2 text-center">
        Presets
      </h1>
      <div className="text-end">
        <button
          className="bg-green-600 hover:bg-green-700 text-white px-4 py-2.5 px-8 text-lg rounded-lg disabled:opacity-80"
          onClick={handleAddPresetForm}
          disabled={loading}
        >
          <FaPlus className="inline" /> Preset <SL bg="green.400">N</SL>
        </button>
      </div>
      <div className="flex flex-grow border-2 rounded-md">
        <div className="flex flex-col space-y-6 p-6 w-3/12 border-r-2">
          <div>
            <Input
              type="text"
              placeholder="Nome do do preset"
              size="lg"
              className="flex-grow"
              value={search}
              onChange={(e) => searchCallback(e.target.value)}
            />
          </div>
          <div className="mx-0 px-0">
            <PresetsList
              presets={presets}
              search={search}
              onClick={selectPresetCallback}
            ></PresetsList>
          </div>
        </div>
        <div className="flex flex-col space-y-6 p-6 w-9/12">
          {loading && (
            <div className="pt-10 text-center">
              <Spinner size="xl" />
            </div>
          )}
          {selectedPreset === null && addingPreset && (
            <AddPreset onAddPreset={handleAddPerset}></AddPreset>
          )}
          {!loading && selectedPreset !== null && (
            <>
              <div className="flex flex-col mx-auto w-full">
                <div className="flex">
                  <div className="flex-grow"></div>
                  <div className="flex-col w-2/5">
                    <EditableHeader
                      value={selectedPreset.title}
                      onTextChange={(text) => handleSetPreset("title", text)}
                      className="text-xl md:text-3xl font-black mb-2 text-center"
                    />
                    <EditableHeader
                      value={selectedPreset.description}
                      onTextChange={(text) =>
                        handleSetPreset("description", text)
                      }
                      className="mb-2 text-center"
                    />
                  </div>
                  <div className="flex-grow"></div>
                  <VersionsMenu
                    versions={versions}
                    defaultVersion={currentVersion}
                    callback={fetchPresetVersion}
                  ></VersionsMenu>
                </div>
              </div>
              <div className="w-2/3 mx-auto">
                {selectedPreset.type === "field" && (
                  <FormEditor
                    general={{
                      $user: selectedPreset.$user,
                      $environment: selectedPreset.$environment,
                      $library: {
                        ...parseFunctions(selectedPreset?.$function ?? {}),
                      },
                    }}
                    fields={selectedPreset.field as Field[]}
                    onConfigChange={(field) => handleSetPreset("field", field)}
                    fieldLimitNumber={1}
                    showPresets={false}
                  ></FormEditor>
                )}
                {selectedPreset.type === "block" && (
                  <FormEditor
                    general={{
                      $user: selectedPreset.$user,
                      $environment: selectedPreset.$environment,
                      $library: {
                        ...parseFunctions(selectedPreset?.$function ?? {}),
                      },
                    }}
                    fields={(selectedPreset.block ?? []) as Field[]}
                    onConfigChange={(block) => handleSetPreset("block", block)}
                  ></FormEditor>
                )}
              </div>
              <div className="pt-6 text-center">
                <button
                  type="submit"
                  className="bg-yellow-600 hover:bg-yellow-700 text-white text-lg py-2.5 px-8 rounded-lg disabled:opacity-80"
                  onClick={handleSavePreset}
                  disabled={loading}
                >
                  Salvar <SL bg="yellow.500">S</SL>
                </button>
              </div>
              <div className="flex flex-col space-y-4 text-end">
                <div>
                  <span className="font-bold">Código</span>
                  <IconButton
                    size={"lg"}
                    aria-label="Remove Preset"
                    icon={<FaPen />}
                    onClick={handleCodeEditor}
                    className="ml-2"
                    disabled={loading}
                  />
                </div>
                <div>
                  <span className="font-bold">Duplicar</span>
                  <IconButton
                    size={"lg"}
                    aria-label="Remove Preset"
                    icon={<FaRegCopy />}
                    onClick={handleDuplicate}
                    className="ml-2"
                    disabled={loading}
                  />
                </div>
                <div>
                  <span className="font-bold text-red-500">Remover</span>
                  <IconButton
                    size={"lg"}
                    aria-label="Remove Preset"
                    icon={<FaTrash />}
                    onClick={handleRemovePreset}
                    className="ml-2"
                    disabled={loading}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
