import React, { useContext, useEffect } from "react";
import { MdMenu } from "react-icons/md";
import logo from "./assets/logo.png";
import { StyleContext } from "./reducers/style.reducer";
import { HotkeyContext, withNoModifiers } from "./reducers/hotkeys.reducer";
import { GlobalHotKeys } from "react-hotkeys";
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  Flex,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import AccessibilityMenu from "./AccessibilityMenu";
import { AuthContext } from "./reducers/auth.reducer";
import { SL } from "./components";

function Header(): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isAuthenticated } = useContext(AuthContext);
  const styleContext = useContext(StyleContext);
  const hotkeyContext = useContext(HotkeyContext);
  const navigate = useNavigate();
  const navItems = [
    { path: "/subjects", label: "Assuntos", shortcut: "A" },
    { path: "/protocols", label: "Pedidos", shortcut: "P" },
    { path: "/acceptances", label: "Aceites", shortcut: "W" },
    { path: "/datasets", label: "Datasets", mobile: true },
    { path: "/presets", label: "Presets", mobile: true },
    { path: "/secrets", label: "Secrets", mobile: true },
    { path: "/signup", label: "Secrets", mobile: true },
    { path: "/sign-up-editor", label: "Cadastro", mobile: true },
  ];

  function handleRedirectHome() {
    navigate("/");

    if (isOpen) {
      onClose();
    }
  }

  function handleRedirect(path: string) {
    navigate(path);
  }

  function handleRedirectProfile() {
    navigate("/profile");

    if (isOpen) {
      onClose();
    }
  }

  function handleLogout(): void {
    localStorage.removeItem("token");
    window.location.href = "/login";
  }

  useEffect(() => {
    hotkeyContext.dispatch({
      type: "SET_HOTKEY",
      payload: {
        A: withNoModifiers(() => handleRedirect("/subjects")),
        P: withNoModifiers(() => handleRedirect("/protocols")),
        W: withNoModifiers(() => handleRedirect("/acceptances")),
      },
    });
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return (
    <>
      <style>
        {`
          html { 
            font-size: ${styleContext.state.fontSize}%; 
          } 
          body {
            color: ${styleContext.state.textColor}; 
            background-color: ${styleContext.state.backgroundColor}; 
          }
        `}
      </style>
      <GlobalHotKeys
        keyMap={hotkeyContext.state.hotkeyKeyMap}
        handlers={hotkeyContext.state.hotkeyHandlers}
        allowChanges={true}
      />
      <header>
        <div className="mb-6 px-8 md:px-24 py-6 flex items-center">
          <div className="w-full space-x-6 text-lg">
            {isAuthenticated && (
              <>
                {navItems
                  .filter((item) => !item.mobile)
                  .map((item) => (
                    <div
                      key={item.path}
                      className="cursor-pointer"
                      onClick={() => handleRedirect(item.path)}
                      style={{
                        display: window.innerWidth >= 768 ? "inline" : "none",
                      }}
                    >
                      {item.label} <SL>{item.shortcut}</SL>
                    </div>
                  ))}
              </>
            )}
          </div>
          {window.innerWidth >= 768 && (
            <div className="container flex justify-center w-full">
              <img
                className="cursor-pointer"
                onClick={handleRedirectHome}
                src={logo}
                alt=""
                style={{ height: "70px" }}
              />
            </div>
          )}
          {window.innerWidth < 768 && (
            <IconButton
              aria-label="Open menu"
              icon={<MdMenu size={32} />}
              variant="outline"
              onClick={onOpen}
            />
          )}
          {window.innerWidth >= 768 && (
            <div className="w-full">
              <AccessibilityMenu />
            </div>
          )}
        </div>

        {window.innerWidth < 768 && (
          <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
            <DrawerContent
              className="p-8"
              style={{
                backgroundColor: styleContext.state.backgroundColor,
              }}
            >
              <DrawerBody>
                <Flex justifyContent="flex-end">
                  <DrawerCloseButton size="lg" />
                </Flex>
                <div className="flex flex-col font-bold justify-center text-center space-y-6">
                  {navItems.map((item) => (
                    // eslint-disable-next-line
                    <a href="#" onClick={() => handleRedirect(item.path)}>
                      {item.label}
                    </a>
                  ))}
                </div>
                <div className="font-bold pt-6">Opções de acessibilidade</div>
                <AccessibilityMenu />
                <div className="text-center absolute bottom-0 ml-10 mb-6">
                  <div className="flex flex-col justify-center text-center space-y-6">
                    {isAuthenticated && (
                      <>
                        {/* eslint-disable-next-line */}
                        <a
                          className="font-bold"
                          href="#"
                          onClick={handleRedirectProfile}
                        >
                          Perfil
                        </a>
                        {/* eslint-disable-next-line */}
                        <a
                          className="font-bold"
                          href="#"
                          onClick={handleLogout}
                        >
                          Sair
                        </a>
                      </>
                    )}
                    <div className="text-xs">Versão mvp-0.0.0</div>
                  </div>
                </div>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        )}
      </header>
    </>
  );
}

export default Header;
