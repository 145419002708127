import React from "react";
import { RenderFieldModelCalculation } from "./RenderFieldModelCalculation";
import { Label } from "../fields";
import { HelpTooltipClickable } from "../../../../components";

export const RenderLabelTooltip = ({
  parent,
  options,
  field,
  context,
}: {
  parent: Field;
  options: FieldOptions;
  field: Field;
  context: any;
}) => {
  return (
    <div className="flex items-start space-x-4">
      {options.label && (
        <Label key={field.key} context={context} options={options} />
      )}
      {options.tooltip && <HelpTooltipClickable tooltip={options.tooltip} />}
      <RenderFieldModelCalculation
        parent={parent}
        field={field}
        context={context}
      />
    </div>
  );
};
