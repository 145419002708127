import { useContext, useState } from "react";
import { FieldEditable } from "../core/FieldEditable";
import { StyleContext } from "../../../reducers";
import { FaPlus, FaTrash } from "react-icons/fa";
import { IconButton } from "@chakra-ui/react";
import EditableHeader from "../../../components/EditableHeader";

export type AcceptanceEditorProps = {
  acceptance: { [key: string]: Field };
  config: {
    environment: EnvironmentProps;
    function?: FunctionProps;
    $user: UserProps;
  };
  onChange: (value: { [key: string]: Field }) => void;
};

export const AcceptanceEditor = ({
  acceptance,
  config,
  onChange,
}: AcceptanceEditorProps): JSX.Element => {
  const styleContext = useContext(StyleContext);
  const [acceptanceType, setAcceptanceType] = useState<string>("");
  const [value, setValue] = useState<any>({});
  const [valid, setValid] = useState<any>({});

  const handleAcceptanceChange = (value: Field) => {
    const newAcceptance = { ...acceptance };
    newAcceptance[acceptanceType] = value;
    onChange(newAcceptance);
  };

  const handleChangeAcceptanceType = (value: string) => {
    if (value !== acceptanceType) {
      acceptance[value] = acceptance[acceptanceType];
      delete acceptance[acceptanceType];
      setAcceptanceType(value);
      onChange(acceptance);
    }
  };

  const handleAddAcceptance = (type: string) => {
    const newAcceptance = { ...acceptance };
    newAcceptance[type] = {
      type: "block",
      key: "root",
      options: {
        hideEditMenu: true,
      },
      block: [],
    };
    onChange(newAcceptance);
    setAcceptanceType(type);
  };

  const handleRemoveAcceptance = (type: string) => {
    const response = window.confirm(
      "Tem certeza que deseja remover este formulário de aceite?"
    );

    if (!response) {
      return;
    }

    setAcceptanceType("");
    const newAcceptance = { ...acceptance };
    delete newAcceptance[type];
    onChange(newAcceptance);
  };

  const general = {
    $environment: config.environment,
    $global: value,
    $library: config.function,
    $state: "edit",
    $user: config.$user,
  };

  return (
    <div className="flex w-full">
      <div className="flex flex-col">
        <div
          className="flex flex-col space-y-6 border-l-0 border-2 rounded-r-md"
          style={{ minWidth: "250px", width: "250px" }}
        >
          <div className="mx-0 px-0 my-4">
            {Object.keys(acceptance)
              .sort()
              .map((type) => (
                <div
                  className={`flex px-6 py-2.5 ${
                    styleContext.state.backgroundColor === "#000000"
                      ? "hover:bg-gray-600"
                      : "hover:bg-gray-200"
                  }`}
                >
                  <div
                    onClick={() => {
                      setAcceptanceType(type);
                    }}
                    className="flex justify-between w-full items-center cursor-pointer"
                  >
                    {type}
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="flex mt-4 justify-center">
          <IconButton
            aria-label="Add new acceptance type"
            icon={<FaPlus />}
            onClick={() => {
              handleAddAcceptance("Novo Tipo de Aceite");
            }}
            size="sm"
          />
        </div>
      </div>
      <div className="flex flex-col mx-16 w-full">
        <div className="flex justify-center text-lg font-bold mb-2 text-center">
          Formulário de Aceitação
        </div>
        <div className="text-center">
          <EditableHeader
            value={acceptanceType}
            onTextChange={(text) => {
              handleChangeAcceptanceType(text.trim());
            }}
          />
        </div>

        {acceptanceType.length > 0 && (
          <div>
            <FieldEditable
              context={value}
              validContext={valid}
              general={general}
              field={acceptance[acceptanceType]}
              value={value}
              valid={valid}
              onChange={setValue}
              onValidChange={setValid}
              onConfigChange={(field: Field) => {
                handleAcceptanceChange(field);
              }}
              onRemove={() => {}}
            />
            <div className="flex mt-10 justify-end">
              <span className="font-bold">Remover tipo de aceite</span>
              <IconButton
                aria-label="Remove type"
                icon={<FaTrash />}
                onClick={() => {
                  handleRemoveAcceptance(acceptanceType);
                }}
                className="ml-2"
                size="sm"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
