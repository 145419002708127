import React, { useState, useEffect, useCallback } from "react";
import InputMask from "react-input-mask";
import { Input as InputBase } from "../../../../components";
import { evalFieldExpression, modelCallback } from "../utils/expressions";
import debounce from "lodash.debounce";
import { NumericFormat } from "react-number-format";

export type FieldInputProps = {
  field: Field;
  key: string;
  onChange: (value: string) => void;
  options: Pick<
    FieldOptions,
    | "label"
    | "mask"
    | "placeholder"
    | "readOnly"
    | "type"
    | "decimalScale"
    | "enableApostille"
  >;
  value?: string | number;
  context?: any;
  valid?: any;
  general: GeneralContext;
};

export const Input: React.FC<FieldInputProps> = ({
  field,
  key,
  onChange,
  options,
  value: propValue,
  context,
  general,
  valid,
}) => {
  const [value, setValue] = useState(propValue);

  useEffect(() => {
    if (
      !field?.expressions?.model?.includes("context.$") ||
      value === undefined
    ) {
      modelCallback(field, value, context, general, valid, (v) => {
        setValue(v);
        onChange(v);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context, field.expressions?.model]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnChange = useCallback(
    debounce((value) => {
      onChange(value);
    }, 300),
    [onChange]
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { selectionStart, selectionEnd } = e.target;

    if (field?.expressions?.model?.includes("context.$")) {
      modelCallback(
        field,
        value,
        { ...context, [field.key]: e.target.value },
        general,
        valid,
        (v) => {
          setValue(v);
          debouncedOnChange(v);

          requestAnimationFrame(() => {
            if (e.target.setSelectionRange) {
              e.target.setSelectionRange(selectionStart, selectionEnd);
            }
          });
        }
      );
    } else {
      setValue(e.target.value);
      debouncedOnChange(e.target.value);
    }
  };

  const isReadonly =
    options.readOnly === true ||
    options.readOnly === "true" ||
    (general?.$state === "apostille" && options.enableApostille !== true);

  const mask = options?.mask;
  const maskValue = mask
    ? evalFieldExpression(`"${mask}"`, value, {}, {})
    : value;

  const classAttr = `w-full bg-transparent border border-gray-200 rounded-md px-4 py-2.5 ${
    isReadonly ? "cursor-not-allowed opacity-50" : ""
  }`;
  const styleAttr = { fontSize: "1.125rem" };

  if (mask && mask.length > 0) {
    return (
      <InputMask
        key={key}
        mask={maskValue}
        maskChar=""
        placeholder={options?.placeholder}
        onChange={handleChange}
        className={classAttr}
        style={styleAttr}
        value={value}
        disabled={isReadonly}
      ></InputMask>
    );
  }

  switch (options.type) {
    case "area":
    case "currency":
    case "percentage":
      return (
        <NumericFormat
          className={classAttr}
          style={styleAttr}
          placeholder={options?.placeholder}
          thousandSeparator="."
          decimalSeparator=","
          prefix={options.type === "currency" ? "R$ " : ""}
          suffix={options.type === "percentage" ? "%" : ""}
          decimalScale={options.decimalScale ?? 2}
          fixedDecimalScale={true}
          allowNegative={false}
          value={value}
          onValueChange={(values) =>
            handleChange({
              target: { value: values.value },
            } as React.ChangeEvent<HTMLInputElement>)
          }
          disabled={isReadonly}
        />
      );
    default:
      return (
        <InputBase
          key={key}
          type={options?.type ?? "text"}
          placeholder={options?.placeholder}
          size="lg"
          onChange={handleChange}
          className="flex-grow"
          value={value}
          disabled={isReadonly}
        />
      );
  }
};
