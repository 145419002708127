import { Radio as RadioBase, RadioGroup } from "@chakra-ui/react";

export type FieldRadioProps = {
  field: Field;
  key: string;
  onChange: (value: string) => void;
  options: Pick<
    FieldOptions,
    "items" | "label" | "readOnly" | "enableApostille"
  >;
  value?: string;
  general: GeneralContext;
};

export const Radio: React.FC<FieldRadioProps> = ({
  field,
  key,
  onChange,
  options,
  value,
  general,
}) => {
  const isReadonly =
    options.readOnly === true ||
    options.readOnly === "true" ||
    (general?.$state === "apostille" && options.enableApostille !== true);

  return (
    <RadioGroup
      colorScheme="yellow"
      onChange={(value) => {
        if (!isReadonly) {
          onChange(value);
        }
      }}
      value={value?.toString()}
    >
      {options?.items?.map((item: { key: string; value: string }) => (
        <div key={key + "#" + item.key} className="mb-1">
          <RadioBase
            size="lg"
            value={item.value.toString()}
            disabled={isReadonly}
            className={isReadonly ? "cursor-not-allowed" : ""}
          >
            <span className={isReadonly ? "cursor-not-allowed" : ""}>
              {item.key}
            </span>
          </RadioBase>
        </div>
      ))}
    </RadioGroup>
  );
};
