import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Box,
} from "@chakra-ui/react";

export type PresetsConfig = {
  [preset: string]: { [type: string]: PresetConfig[] };
};

export type PresetsTypeConfig = {
  [type: string]: PresetConfig[];
};

export type PresetConfig = {
  id?: string;
  group: string;
  type: "field" | "block";
  title: string;
  description: string;
  field?: Field[];
  block?: Field[];
};

export interface PresetListProps {
  presets: PresetsConfig;
  search: string;
  onClick: (presetItem: PresetConfig) => void;
}

export const PresetsList: React.FC<PresetListProps> = ({
  presets,
  search,
  onClick,
}): JSX.Element[] => {
  const filteredPresets: PresetsConfig = {};

  Object.keys(presets).forEach((groupName) => {
    filteredPresets[groupName] = {};

    let groupHasAnyPreset = false;

    Object.keys(presets[groupName]).forEach((typeName) => {
      const items = presets[groupName][typeName].filter((item) => {
        return item.title.toLowerCase().includes(search.toLowerCase());
      });

      if (items.length) {
        filteredPresets[groupName][typeName] = items;
        groupHasAnyPreset = true;
      }
    });

    if (!groupHasAnyPreset) {
      delete filteredPresets[groupName];
    }
  });

  return Object.keys(filteredPresets)
    .sort()
    .map((groupName) => {
      return (
        <Accordion
          key={"preset-group-" + groupName}
          allowToggle
          border={0}
          className="-mx-6"
        >
          <AccordionItem border={0}>
            <AccordionButton>
              <Box className="pl-4" as="span" flex="1" textAlign="left">
                {groupName}
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <div className="flex flex-col my-0 py-0">
                {Object.keys(filteredPresets[groupName])
                  .sort()
                  .map((typeName) => {
                    return (
                      <Accordion
                        key={"preset-type-" + typeName}
                        allowToggle
                        border="{0}"
                        className="-mx-6 py-0 my-0"
                      >
                        <AccordionItem border="{0}">
                          <AccordionButton>
                            <Box
                              className="pl-10"
                              as="span"
                              flex="1"
                              textAlign="left"
                            >
                              {typeName}
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                          <AccordionPanel className="pb-0 mb-0">
                            {filteredPresets[groupName][typeName]
                              .sort((a, b) => a.title.localeCompare(b.title))
                              .map((item) => {
                                return (
                                  <div
                                    key={"preset-item-" + item.id}
                                    className="-mx-2 pl-16 py-2 hover:bg-gray-200"
                                  >
                                    <div
                                      onClick={() => onClick(item)}
                                      className="flex cursor-pointer justify-between w-full items-center"
                                    >
                                      {item.title}
                                    </div>
                                  </div>
                                );
                              })}
                          </AccordionPanel>
                        </AccordionItem>
                      </Accordion>
                    );
                  })}
              </div>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      );
    });
};
