import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import Header from "./Header";
import Footer from "./Footer";
import RouteDefinitions from "./RouteDefinitions";
import { AuthProvider } from "./reducers/auth.reducer";
import { StyleProvider } from "./reducers/style.reducer";
import { HotkeyProvider } from "./reducers/hotkeys.reducer";
import PromptModal from "./components/Prompt";

export default function App() {
  const shouldDisplayHeaderFooter =
    !window.location.href.includes("-print") &&
    !window.location.href.includes("-validate");

  return (
    <AuthProvider>
      <Router>
        <StyleProvider>
          <PromptModal />
          <HotkeyProvider>
            <Layout shouldDisplayHeaderFooter={shouldDisplayHeaderFooter}>
              <RouteDefinitions />
            </Layout>
          </HotkeyProvider>
        </StyleProvider>
      </Router>
    </AuthProvider>
  );
}

function Layout({
  shouldDisplayHeaderFooter,
  children,
}: {
  shouldDisplayHeaderFooter: boolean;
  children: React.ReactNode;
}) {
  return (
    <div id="application">
      {shouldDisplayHeaderFooter && <Header />}
      {children}
      {shouldDisplayHeaderFooter && <Footer />}
    </div>
  );
}
