import { Checkbox as CheckboxBase } from "@chakra-ui/react";

export type FieldCheckboxProps = {
  field: Field;
  key: string;
  onChange: (value: { [value: string]: boolean } | undefined) => void;
  options: Pick<
    FieldOptions,
    "items" | "label" | "readOnly" | "enableApostille"
  >;
  value: { [value: string]: boolean } | undefined;
  general: GeneralContext;
};

export const Checkbox: React.FC<FieldCheckboxProps> = ({
  field,
  key,
  onChange,
  options,
  value = {},
  general,
}) => {
  const isReadonly =
    options.readOnly === true ||
    options.readOnly === "true" ||
    (general?.$state === "apostille" && options.enableApostille !== true);

  return (
    <div>
      {options?.items?.map((item: { key: string; value: any }) => (
        <div key={item.key} className="mb-1">
          <CheckboxBase
            key={key + "#" + item.key}
            size="lg"
            isChecked={value[item.value] === true}
            colorScheme="yellow"
            className={isReadonly ? "cursor-not-allowed" : ""}
            onChange={(e) => {
              if (e.target.checked) {
                value[item.value] = true;
              } else {
                delete value[item.value];
              }

              if (Object.keys(value).length === 0) {
                onChange(undefined);
              } else {
                onChange(value);
              }
            }}
            disabled={isReadonly}
          >
            {item.key}
          </CheckboxBase>
        </div>
      ))}
    </div>
  );
};
