import { FormControl, FormLabel, Select, Textarea } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { Input, SL } from "../../../components";
import { HotkeyContext } from "../../../reducers";

export type PresetForm = {
  title: string;
  description: string;
  group: string;
  type: "field" | "block";
};

export type AddPresetProps = {
  onAddPreset: (preset: {
    title: string;
    description: string;
    group: string;
    type: "field" | "block";
  }) => void;
};

export const AddPreset: React.FC<AddPresetProps> = ({
  onAddPreset,
}): JSX.Element => {
  const hotkeyContext = useContext(HotkeyContext);
  const [newPresetForm, setNewPresetForm] = useState<{
    title: string;
    description: string;
    group: string;
    type: "field" | "block";
  }>({
    title: "",
    description: "",
    group: "",
    type: "field",
  });

  const types = [
    {
      value: "field",
      label: "Campo",
    },
    {
      value: "block",
      label: "Bloco",
    },
  ];

  useEffect(() => {
    hotkeyContext.dispatch({
      type: "SET_HOTKEY",
      payload: {
        S: () => {
          if (
            newPresetForm.title.length > 0 &&
            newPresetForm.group.length > 0 &&
            newPresetForm.type.length > 0 &&
            newPresetForm.description.length > 0
          ) {
            onAddPreset(newPresetForm);
          }
        },
      },
    });

    return () => {
      hotkeyContext.dispatch({
        type: "UNSET_HOTKEY",
        delete: ["S"],
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPresetForm]);

  return (
    <div className="flex flex-col md:w-1/2 mx-auto space-y-4 ">
      <label className="text-xl md:text-2xl font-black mb-2 text-center">
        Adicionar preset
      </label>
      <FormControl id="title">
        <FormLabel>Título</FormLabel>
        <Input
          autoFocus
          placeholder="Titulo do preset"
          size="lg"
          value={newPresetForm?.title}
          onChange={(e) =>
            setNewPresetForm({
              ...newPresetForm,
              title: e.target.value,
            })
          }
        />
      </FormControl>
      <FormControl id="description">
        <FormLabel>Descrição</FormLabel>
        <Textarea
          placeholder="Descrição do preset"
          size="lg"
          value={newPresetForm?.description}
          onChange={(e) =>
            setNewPresetForm({
              ...newPresetForm,
              description: e.target.value,
            })
          }
        />
      </FormControl>
      <FormControl id="group">
        <FormLabel>Grupo</FormLabel>
        <Input
          placeholder="Nome do grupo do preset"
          size="lg"
          value={newPresetForm?.group}
          onChange={(e) =>
            setNewPresetForm({
              ...newPresetForm,
              group: e.target.value,
            })
          }
        />
      </FormControl>
      <FormControl id="type">
        <FormLabel>Tipo</FormLabel>
        <Select
          placeholder="Selecione o tipo do preset"
          size="lg"
          className="flex-grow"
          value={newPresetForm?.type}
          onChange={(e) =>
            setNewPresetForm({
              ...newPresetForm,
              type: e.target.value as "field" | "block",
            })
          }
        >
          {types.map((type) => (
            <option key={"new-preset-type-" + type.value} value={type.value}>
              {type.label}
            </option>
          ))}
        </Select>
      </FormControl>
      <div className="py-6 text-center">
        <button
          className="bg-yellow-600 hover:bg-yellow-700 text-white text-lg py-2.5 px-8 rounded-lg disabled:opacity-80"
          disabled={
            !newPresetForm.title ||
            !newPresetForm.group ||
            !newPresetForm.type ||
            !newPresetForm.description
          }
          onClick={() => onAddPreset(newPresetForm)}
        >
          Adicionar <SL bg="yellow.500">S</SL>
        </button>
      </div>
    </div>
  );
};
