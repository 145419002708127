import { evalLabel } from "../utils/expressions";

export type FieldLabelProps = {
  key: string;
  context: any;
  options: Pick<FieldOptions, "label" | "required">;
};

export const Label: React.FC<FieldLabelProps> = ({ key, context, options }) => {
  const isRequired = options.required === "true" || options.required === true;
  const label = evalLabel(options.label ?? "", context);

  return (
    <div className="flex space-x-2 mb-2" key={key}>
      <div dangerouslySetInnerHTML={{ __html: label ?? "" }}></div>
      {isRequired && <div>*</div>}
    </div>
  );
};
