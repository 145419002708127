import { FieldLabelProps } from "./Label";
import EditableHeader from "../../../../components/EditableHeader";

export type FieldLabelEditableProps = {
  key: string;
  onChange: (options: FieldLabelProps) => void;
  props: FieldLabelProps;
};

export const LabelEditable: React.FC<FieldLabelEditableProps> = ({
  key,
  onChange,
  props,
}) => {
  return (
    <div className="flex space-x-2">
      <EditableHeader
        key={key}
        html={props.options.label}
        className="mb-3.5"
        onTextChange={(value) => {
          onChange({ ...props, options: { label: value } });
        }}
      ></EditableHeader>
      <div>
        {(props.options.required === "true" ||
          props.options.required === true) &&
          "*"}
      </div>
    </div>
  );
};
