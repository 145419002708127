import { FormControl, FormLabel, Textarea } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { Input, SL } from "../../../components";
import { HotkeyContext } from "../../../reducers";
import { FunctionConfig } from "../Functions";
import Editor from "@monaco-editor/react";

export type AddFunctionProps = {
  onAddFunction: (fun: FunctionConfig) => void;
};

export const AddFunction: React.FC<AddFunctionProps> = ({
  onAddFunction,
}): JSX.Element => {
  const hotkeyContext = useContext(HotkeyContext);
  const [newFunctionForm, setNewFunctionForm] = useState<FunctionConfig>({
    group: "",
    title: "",
    description: "",
    key: "",
    value: "",
  });

  useEffect(() => {
    hotkeyContext.dispatch({
      type: "SET_HOTKEY",
      payload: {
        S: () => {
          if (
            newFunctionForm.title.length > 0 &&
            newFunctionForm.description.length > 0 &&
            newFunctionForm.value.length > 0
          ) {
            onAddFunction(newFunctionForm);
          }
        },
      },
    });

    return () => {
      hotkeyContext.dispatch({
        type: "UNSET_HOTKEY",
        delete: ["S"],
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newFunctionForm]);

  return (
    <div className="flex flex-col md:w-1/2 mx-auto space-y-4 ">
      <label className="text-xl md:text-2xl font-black mb-2 text-center">
        Adicionar Função
      </label>
      <FormControl id="group">
        <FormLabel>Grupo</FormLabel>
        <Input
          autoFocus
          placeholder="Titulo da função"
          size="lg"
          value={newFunctionForm?.group}
          onChange={(e) =>
            setNewFunctionForm({
              ...newFunctionForm,
              group: e.target.value,
            })
          }
        />
      </FormControl>
      <FormControl id="title">
        <FormLabel>Título</FormLabel>
        <Input
          placeholder="Titulo da função"
          size="lg"
          value={newFunctionForm?.title}
          onChange={(e) =>
            setNewFunctionForm({
              ...newFunctionForm,
              title: e.target.value,
            })
          }
        />
      </FormControl>
      <FormControl id="description">
        <FormLabel>Descrição</FormLabel>
        <Textarea
          placeholder="Descrição da função"
          size="lg"
          value={newFunctionForm?.description}
          onChange={(e) =>
            setNewFunctionForm({
              ...newFunctionForm,
              description: e.target.value,
            })
          }
        />
      </FormControl>
      <FormControl id="key">
        <FormLabel>Chave</FormLabel>
        <Input
          placeholder="Chave da função"
          size="lg"
          value={newFunctionForm?.key}
          onChange={(e) =>
            setNewFunctionForm({
              ...newFunctionForm,
              key: e.target.value,
            })
          }
        />
      </FormControl>
      <FormControl id="value">
        <FormLabel>Funções</FormLabel>
        <Editor
          height="30vh"
          defaultLanguage="javascript"
          defaultValue={newFunctionForm?.value}
          theme="vs-dark"
          onChange={(value) =>
            setNewFunctionForm({
              ...newFunctionForm,
              value: value ?? "",
            })
          }
        />
      </FormControl>
      <div className="py-6 text-center">
        <button
          className="bg-yellow-600 hover:bg-yellow-700 text-white text-lg py-2.5 px-8 rounded-lg disabled:opacity-80"
          disabled={
            !newFunctionForm.group ||
            !newFunctionForm.title ||
            !newFunctionForm.description ||
            !newFunctionForm.key ||
            !newFunctionForm.value
          }
          onClick={() => onAddFunction(newFunctionForm)}
        >
          Adicionar <SL bg="yellow.500">S</SL>
        </button>
      </div>
    </div>
  );
};
