import { FormControl, FormLabel, Textarea } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { Input, SL } from "../../../components";
import { HotkeyContext } from "../../../reducers";
import { SecretConfig } from "../Secrets";

export type AddSecretProps = {
  onAddSecret: (secret: SecretConfig) => void;
};

export const AddSecret: React.FC<AddSecretProps> = ({
  onAddSecret,
}): JSX.Element => {
  const hotkeyContext = useContext(HotkeyContext);
  const [newSecretForm, setNewSecretForm] = useState<SecretConfig>({
    title: "",
    description: "",
    value: "",
  });

  useEffect(() => {
    hotkeyContext.dispatch({
      type: "SET_HOTKEY",
      payload: {
        S: () => {
          if (
            newSecretForm.title.length > 0 &&
            newSecretForm.description.length > 0 &&
            newSecretForm.value.length > 0
          ) {
            onAddSecret(newSecretForm);
          }
        },
      },
    });

    return () => {
      hotkeyContext.dispatch({
        type: "UNSET_HOTKEY",
        delete: ["S"],
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newSecretForm]);

  return (
    <div className="flex flex-col md:w-1/2 mx-auto space-y-4 ">
      <label className="text-xl md:text-2xl font-black mb-2 text-center">
        Adicionar secret
      </label>
      <FormControl id="title">
        <FormLabel>Título</FormLabel>
        <Input
          autoFocus
          placeholder="Titulo do secret"
          size="lg"
          value={newSecretForm?.title}
          onChange={(e) =>
            setNewSecretForm({
              ...newSecretForm,
              title: e.target.value,
            })
          }
        />
      </FormControl>
      <FormControl id="description">
        <FormLabel>Descrição</FormLabel>
        <Textarea
          placeholder="Descrição do secret"
          size="lg"
          value={newSecretForm?.description}
          onChange={(e) =>
            setNewSecretForm({
              ...newSecretForm,
              description: e.target.value,
            })
          }
        />
      </FormControl>
      <FormControl id="value">
        <FormLabel>Valor</FormLabel>
        <Input
          placeholder="Valor do secret"
          size="lg"
          value={newSecretForm?.value}
          onChange={(e) =>
            setNewSecretForm({
              ...newSecretForm,
              value: e.target.value,
            })
          }
        />
      </FormControl>
      <div className="py-6 text-center">
        <button
          className="bg-yellow-600 hover:bg-yellow-700 text-white text-lg py-2.5 px-8 rounded-lg disabled:opacity-80"
          disabled={
            !newSecretForm.title ||
            !newSecretForm.description ||
            !newSecretForm.value
          }
          onClick={() => onAddSecret(newSecretForm)}
        >
          Adicionar <SL bg="yellow.500">S</SL>
        </button>
      </div>
    </div>
  );
};
