import React, { useEffect, useState } from "react";
import { FieldBlock } from "./FieldBlock";
import {
  Checkbox,
  Input,
  Integration,
  Link,
  Map,
  Paragraph,
  Radio,
  Select,
  Table,
  Textarea,
  Title,
  Upload,
} from "./fields";
import { HelpTooltipClickable } from "../../../components";
import {
  integrationCallback,
  modelCallback,
  optionCallback,
  validCallback,
  visibleCallback,
} from "./utils/expressions";
import { Array as ArrayField } from "./fields/Array";
import { Spinner } from "@chakra-ui/react";
import { FieldBase, FieldProps } from "./utils/types";
import { RenderValidState } from "./components/RenderValidState";
import { RenderLabelTooltip } from "./components/RenderLabelTooltip";
import { InputFieldTypes } from "./utils/types";

export const FIELD_COMPONENT_MAP: {
  [field: string]: (...args: any[]) => JSX.Element;
} = {
  input: ({
    field,
    key,
    options,
    onChange,
    value,
    context,
    valid,
    general,
  }: FieldBase) => (
    <Input
      field={field}
      key={key}
      onChange={onChange}
      options={options}
      value={value}
      context={context}
      valid={valid}
      general={general}
    />
  ),
  textarea: ({ field, key, options, onChange, value, general }: FieldBase) => (
    <Textarea
      field={field}
      key={key}
      onChange={onChange}
      options={options}
      value={value}
      general={general}
    />
  ),
  select: ({ field, key, options, onChange, value, general }: FieldBase) => (
    <Select
      field={field}
      key={key}
      onChange={onChange}
      options={options}
      value={value}
      general={general}
    />
  ),
  checkbox: ({ field, key, options, onChange, value, general }: FieldBase) => (
    <Checkbox
      field={field}
      key={key}
      onChange={onChange}
      options={options}
      value={value}
      general={general}
    />
  ),
  radio: ({ field, key, options, onChange, value, general }: FieldBase) => (
    <Radio
      field={field}
      key={key}
      onChange={onChange}
      options={options}
      value={value}
      general={general}
    />
  ),
  upload: ({ field, key, options, onChange, value, general }: FieldBase) => (
    <Upload
      field={field}
      key={key}
      onChange={onChange}
      options={options}
      value={value}
      general={general}
    />
  ),
  map: ({ key, options, value }: FieldBase) => (
    <Map key={key} options={options} value={value} />
  ),
  table: ({
    options,
    onChange,
    onValidChange,
    general,
    value,
    valid,
  }: FieldBase) => (
    <Table
      table={options.table as Field[][]}
      options={options}
      general={general}
      value={value}
      valid={valid}
      onChange={onChange}
      onValidChange={onValidChange}
    />
  ),
};

export const useFieldDynamic = (
  field: Field,
  context: any,
  validContext: any,
  general: GeneralContext,
  value: any,
  onChange: (value: any) => void,
  onValidChange: (valid: any) => void
) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(
    field.expressions?.visible ? false : field.options.visible !== false
  );
  const [options, setOptions] = useState<FieldOptions>(field.options ?? {});
  const [validState, setValidState] = useState<boolean | ValidState>(true);

  useEffect(() => {
    optionCallback(field, context, general, validContext, setOptions);
    validCallback(
      field,
      context,
      general,
      validContext,
      setValidState,
      onValidChange
    );
    visibleCallback(field, context, general, validContext, setVisible);
    if (
      (field.type !== "input" && field.type !== "textarea") ||
      visible === false //
    ) {
      // If the field is not an input or textarea, or if it is not visible,
      // we don't need to run the modelCallback. The modelCallback is used
      // inside the <Input> and <Textarea> components to update the value.
      // This is because the value of the input need to be controlled by the
      // component to be reactive to its own changes.
      modelCallback(field, value, context, general, validContext, onChange);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    context,
    field.expressions?.model,
    field.expressions?.options,
    field.expressions?.valid,
    field.expressions?.visible,
  ]);

  useEffect(() => {
    integrationCallback(
      field,
      context,
      general,
      validContext,
      value,
      setLoading,
      onChange
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context, general.$global, field.expressions?.integration]);

  return { loading, visible, options, validState, setOptions };
};

export const Field: React.FC<FieldProps> = ({
  parent,
  context,
  validContext,
  general,
  field,
  value,
  valid,
  onChange,
  onValidChange,
}): JSX.Element => {
  const { loading, visible, options, validState, setOptions } = useFieldDynamic(
    field,
    context,
    validContext,
    general,
    value,
    onChange,
    onValidChange
  );

  const [localValue, setLocalValue] = useState(value);
  const [localValid, setLocalValid] = useState(valid);

  const FieldComponent = FIELD_COMPONENT_MAP[field.type] || (() => <></>);

  if (visible) {
    return (
      <div className="w-full">
        {field.type === "title" && (
          <Title key={field.key} options={field.options as any} />
        )}
        {field.type === "subtitle" && (
          <div className="flex items-center space-x-4">
            <Paragraph key={field.key} options={field.options as any} />
            {field.options.tooltip && (
              <div>
                <HelpTooltipClickable tooltip={field.options.tooltip} />
              </div>
            )}
          </div>
        )}
        {field.type === "link" && <Link value={value} />}
        {field.type === "integration" && (
          <>
            <Integration field={field} options={options} value={value} />
            {loading && <Spinner />}
          </>
        )}
        {parent && InputFieldTypes.includes(field.type) && (
          <>
            <RenderLabelTooltip
              parent={parent}
              options={options}
              field={field}
              context={context}
            />
            <FieldComponent
              field={field}
              key={field.key}
              options={options}
              general={general}
              value={value}
              valid={valid}
              context={context}
              onChange={onChange}
              onValidChange={onValidChange}
            />
          </>
        )}
        {field.type === "block" && field.block && (
          <div
            className={`${
              field.options.card ? "p-6 w-full border rounded" : ""
            }`}
          >
            {field.options.card && (
              <div className="flex items-center space-x-2 text-lg mb-6">
                <label>{options.label}</label>
                {options.tooltip && (
                  <HelpTooltipClickable tooltip={field.options.tooltip ?? ""} />
                )}
                {options.toggle !== false && options.toggle !== "false" && (
                  <>
                    <div className="flex-grow"></div>
                    <div
                      className="cursor-pointer text-blue-500 hover:text-blue-700"
                      onClick={() => {
                        setOptions({
                          ...options,
                          open: !options.open,
                        });
                      }}
                    >
                      {options.open ? "Ocultar" : "Mostrar"}
                    </div>
                  </>
                )}
              </div>
            )}
            <FieldBlock
              parent={{ ...field, options }}
              field={field.block}
              general={general}
              layout={field.options.layout}
              value={localValue}
              valid={localValid}
              onChange={(k, v) => {
                setLocalValue((current: any) => {
                  const newLocalValue = { ...current, [k]: v };
                  onChange(newLocalValue);
                  return newLocalValue;
                });
              }}
              onValidChange={(k, v) => {
                setLocalValid((current: any) => {
                  const newLocalValid = { ...current, [k]: v };
                  onValidChange(newLocalValid);
                  return newLocalValid;
                });
              }}
            ></FieldBlock>
          </div>
        )}
        {field.type === "preset" && field.preset && (
          <FieldBlock
            parent={field}
            field={field.preset}
            general={general}
            layout={field.options.layout}
            value={localValue}
            valid={localValid}
            onChange={(k, v) => {
              setLocalValue((current: any) => {
                const newLocalValue = { ...current, [k]: v };
                onChange(newLocalValue);
                return newLocalValue;
              });
            }}
            onValidChange={(k, v) => {
              setLocalValid((current: any) => {
                const newLocalValid = { ...current, [k]: v };
                onValidChange(newLocalValid);
                return newLocalValid;
              });
            }}
          />
        )}
        {parent && field.type === "array" && (
          <div>
            <RenderLabelTooltip
              parent={parent}
              options={options}
              field={field}
              context={context}
            />
            <ArrayField
              field={field}
              general={general}
              value={value ?? []}
              valid={valid ?? []}
              onChange={onChange}
              onValidChange={onValidChange}
            />
          </div>
        )}

        <RenderValidState validState={validState} />
        {!validState && (
          <p className="text-red-600 font-bold">Campo inválido</p>
        )}
      </div>
    );
  } else {
    return <></>;
  }
};
