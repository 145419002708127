import { FieldView } from "../FieldView";

export type FieldTableViewProps = {
  table: Field[][];
  options: Pick<FieldOptions, "columns" | "width">;
  value: any;
};

export const TableView: React.FC<FieldTableViewProps> = ({
  table,
  options,
  value,
}) => {
  return (
    <div className="flex flex-col" style={{ width: options.width }}>
      {table?.map((row, rowIndex) => {
        return (
          <div className="flex">
            {row.map((field) => {
              if (field.type === "integration" || field.type === "link") {
                return <></>;
              }

              return field.type !== "none" ? (
                <div
                  className={`border-x px-4 pt-3 border-t ${
                    rowIndex + 1 === table.length ? "border-b" : ""
                  }`}
                  style={{
                    width: `${
                      ((field.options.columns ?? row.length) /
                        (options.columns ?? 1)) *
                      100
                    }%`,
                  }}
                >
                  <FieldView
                    key={field.key}
                    general={{}}
                    field={field}
                    value={value?.[field.key]}
                    context={value}
                  />
                </div>
              ) : (
                <div
                  className="border-x"
                  style={{
                    width: `${
                      ((field.options.columns ?? row.length) /
                        (options.columns ?? 1)) *
                      100
                    }%`,
                  }}
                ></div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
