import React, { useState, useCallback } from "react";
import axios from "axios";
import { downloadFile } from "../utils/utils";
// import ImageGallery from "../../components/ImageGallery";

export type FieldUploadProps = {
  field?: Field;
  key: string;
  onChange: (value: string[] | string) => void;
  options: Pick<
    FieldOptions,
    | "multiple"
    | "dir"
    | "readOnly"
    | "gallery"
    | "maxSize"
    | "supportedExtensions"
    | "enableApostille"
  >;
  value: string[] | string;
  general?: GeneralContext;
};

export const Upload: React.FC<FieldUploadProps> = ({
  field,
  key,
  onChange,
  options,
  value,
  general,
}) => {
  const [uploadProgress, setUploadProgress] = useState<{
    [filename: string]: number;
  }>({});
  const [uploadedFiles, setUploadedFiles] = useState<string[]>(
    Array.isArray(value) ? value : value?.length > 0 ? [value] : []
  );
  const [inputKey, setInputKey] = useState(Date.now());

  const uploadFileToServer = async (fileName: string, file: File) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACK_END_API}/datasets/generate-presigned-url`,
        {
          dirName: options.dir,
          fileName: fileName,
          fileType: file.type,
        },
        {
          headers: {
            authorization: `${localStorage.getItem("token")}`,
          },
        }
      );

      setUploadedFiles((prevFiles: string[]) => [...prevFiles, fileName]);

      await axios.put(data.url, file, {
        headers: {
          "Content-Type": file.type,
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / (progressEvent?.total ?? -1)
          );
          setUploadProgress((prevProgress) => ({
            ...prevProgress,
            [fileName]: percentCompleted,
          }));
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileUpload = useCallback(
    (files: FileList | null) => {
      if (files) {
        const validFiles = Array.from(files).filter((file) => {
          const isSizeValid = !options.maxSize || file.size <= options.maxSize;
          const isExtensionValid =
            !options.supportedExtensions ||
            options.supportedExtensions.some((ext) =>
              file.name.toLowerCase().endsWith(ext.toLowerCase())
            );

          if (!isSizeValid) {
            alert(
              `O arquivo ${file.name} ultrapassa o limite permitido de ${options.maxSize} bytes.`
            );
          }

          if (!isExtensionValid) {
            alert(`O arquivo ${file.name} não é de um tipo suportado`);
          }

          return isSizeValid && isExtensionValid;
        });

        if (validFiles.length > 0) {
          const code = (Math.random() + 1).toString(36).substring(7);

          const filenamesWithTimestamp = validFiles.map((file) => {
            const fileName = `${code}/${new Date().getTime()}_${file.name}`;
            uploadFileToServer(fileName, file);
            return fileName;
          });

          onChange(filenamesWithTimestamp);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChange]
  );

  const removeUploadedFile = (filename: string) => {
    setUploadedFiles((prevFiles) => {
      const newFiles = prevFiles.filter((file) => file !== filename);
      onChange(newFiles);
      return newFiles;
    });

    setInputKey(Date.now());
  };

  const isReadonly =
    options.readOnly === true ||
    options.readOnly === "true" ||
    (general?.$state === "apostille" && options.enableApostille !== true) ||
    (options.multiple === false && uploadedFiles.length > 0);

  return (
    <div>
      {uploadedFiles.map((filename) => (
        <div
          key={filename}
          className="border border-gray-200 rounded-lg py-2 px-4 mb-4 flex justify-between items-center"
        >
          <span
            className="cursor-pointer"
            onClick={() => downloadFile(options.dir, filename)}
          >
            {filename}
          </span>
          <span className="ml-4">
            {uploadProgress[filename] && uploadProgress[filename] !== 100 && (
              <div>{uploadProgress[filename]}%</div>
            )}
          </span>
          <button
            onClick={() => removeUploadedFile(filename)}
            className="p-1 rounded"
          >
            x
          </button>
        </div>
      ))}
      <label
        htmlFor={inputKey.toString()}
        className={`bg-red-600 hover:bg-red-700 text-white text-lg w-full py-2 rounded-xl  text-center block ${
          isReadonly ? "opacity-80 cursor-not-allowed" : "cursor-pointer"
        }`}
      >
        Anexar
      </label>
      <input
        id={inputKey.toString()}
        multiple={options.multiple ?? true}
        type="file"
        accept={options.supportedExtensions?.map((ext) => `.${ext}`).join(",")}
        onChange={(e) => handleFileUpload(e.target.files)}
        className="hidden"
        disabled={isReadonly}
      />

      {/* {(options.gallery === true || options.gallery === "true") && (
        <ImageGallery uploadedFiles={uploadedFiles} />
      )} */}
    </div>
  );
};
