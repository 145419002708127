import React, { FC, useContext, useEffect, useState } from "react";
import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalHeader,
  IconButton,
  Switch,
} from "@chakra-ui/react";
import { StyleContext } from "../../../reducers";
import { FaPlus, FaTrash } from "react-icons/fa";
import { Input, Select } from "../../../components";
import { BlockPicker } from "react-color";

interface LayersModalProps {
  layers: LayerDescriptor[];
  isOpen: boolean;
  onSave: (value: any) => void;
  onClose: () => void;
}

const LayersModal: FC<LayersModalProps> = ({
  layers,
  isOpen,
  onSave,
  onClose,
}) => {
  const modalDisclosure = useDisclosure();
  const styleContext = useContext(StyleContext);
  const [localLayers, setLocalLayers] = useState(layers);

  const handleSave = () => {
    onSave(localLayers);
    modalDisclosure.onClose();
  };

  const handleCancel = () => {
    onClose();
    modalDisclosure.onClose();
  };

  useEffect(() => {
    if (isOpen) {
      modalDisclosure.onOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleAddLayer = () => {
    localLayers?.push({
      key: "",
      title: "",
      color: "#fffaaa",
      properties: [],
    });

    setLocalLayers([...localLayers]);
  };

  const handleRemoveLayer = (layerIndex: number) => {
    localLayers?.splice(layerIndex, 1);
    setLocalLayers([...localLayers]);
  };

  const handleSetLayerAttr = (
    layerIndex: number,
    attr: "color" | "key" | "properties" | "title",
    value: string | any
  ) => {
    if (localLayers?.[layerIndex]) {
      localLayers[layerIndex][attr] = value;
      setLocalLayers([...localLayers]);
    }
  };

  const handleSetLayerParseProperties = (
    layerIndex: number,
    propertyIndex: number,
    attr: "label" | "key" | "includeOnPopUp",
    value: string | boolean
  ) => {
    if (localLayers?.[layerIndex]?.properties?.[propertyIndex]) {
      localLayers[layerIndex].properties[propertyIndex] = {
        ...localLayers[layerIndex].properties[propertyIndex],
        [attr]: value,
      };

      setLocalLayers([...localLayers]);
    }
  };

  const handleRemoveLayerParseProperties = (
    layerIndex: number,
    propertyIndex: number
  ) => {
    localLayers?.[layerIndex]?.properties?.splice(propertyIndex, 1);

    setLocalLayers([...localLayers]);
  };

  const handleAddLayerParseProperties = (layerIndex: number) => {
    localLayers?.[layerIndex]?.properties?.push({
      label: "",
      key: "",
      includeOnPopUp: false,
    });

    setLocalLayers([...localLayers]);
  };

  return (
    <Modal isOpen={modalDisclosure.isOpen} onClose={handleCancel}>
      <ModalOverlay />
      <ModalContent
        className="px-4 py-6"
        style={{ minWidth: 800 }}
        bg={styleContext.state.backgroundColor}
      >
        <ModalHeader>Camadas</ModalHeader>
        <ModalBody>
          {" "}
          <div>
            {localLayers &&
              localLayers.map((layer, layerIndex) => (
                <div className="py-4">
                  <h1 className="text-lg md:text-xl font-black mb-4">
                    <span className="mr-2">Camada #{layerIndex + 1} </span>
                    <IconButton
                      aria-label="Remove layer"
                      icon={<FaTrash />}
                      onClick={() => handleRemoveLayer(layerIndex)}
                    />
                  </h1>
                  <div className="flex flex-col w-full">
                    <div className="flex space-x-8 w-full">
                      <div className="flex flex-col space-y-4 w-full">
                        <div className="space-y-4">
                          <label>Chave da camada *</label>
                          <Select
                            placeholder="Selecione o nome da chave da camada"
                            size="lg"
                            className="flex-grow"
                            value={layer?.key}
                            onChange={(e) =>
                              handleSetLayerAttr(
                                layerIndex,
                                "key",
                                e.target.value
                              )
                            }
                          >
                            {layersKey.map((key) => (
                              <option key={key} value={key}>
                                {key}
                              </option>
                            ))}
                          </Select>
                        </div>
                        <div className="space-y-4">
                          <label>Título da camada *</label>
                          <Input
                            type="text"
                            placeholder="Insira o título da camada"
                            size="lg"
                            className="flex-grow"
                            value={layer?.title}
                            onChange={(e) =>
                              handleSetLayerAttr(
                                layerIndex,
                                "title",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="flex">
                        <div className="space-y-4">
                          <label>Cor da camada *</label>
                          <BlockPicker
                            color={
                              localLayers?.[layerIndex]?.color ?? "#fffaaa"
                            }
                            onChangeComplete={(value) =>
                              handleSetLayerAttr(layerIndex, "color", value.hex)
                            }
                            triangle="hide"
                          ></BlockPicker>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col space-y-4 w-full">
                    <h2 className="md:text-lg font-bold mb-2 w-full">
                      Dados da camada
                    </h2>
                    {layer?.properties.map(
                      (
                        property: {
                          label: string;
                          key: string;
                          includeOnPopUp: boolean;
                        },
                        propertyIndex: number
                      ) => {
                        return (
                          <div className="flex space-x-4 w-full">
                            <div className="flex flex-col space-y-4 w-full">
                              <label>Etiqueta *</label>
                              <Input
                                type="text"
                                placeholder="Nome da etiqueta"
                                size="lg"
                                className="flex-grow"
                                value={property?.label}
                                onChange={(e) =>
                                  handleSetLayerParseProperties(
                                    layerIndex,
                                    propertyIndex,
                                    "label",
                                    e.target.value
                                  )
                                }
                              ></Input>
                            </div>
                            <div className="flex flex-col space-y-4 w-full">
                              <label>Chave *</label>
                              <Input
                                type="text"
                                placeholder="Nome da chave"
                                size="lg"
                                className="flex-grow"
                                value={property?.key}
                                onChange={(e) =>
                                  handleSetLayerParseProperties(
                                    layerIndex,
                                    propertyIndex,
                                    "key",
                                    e.target.value
                                  )
                                }
                              ></Input>
                            </div>
                            <div className="flex flex-col space-y-4 w-2/3">
                              <label>Incluir no Pop-up?</label>
                              <Switch
                                isChecked={!!property?.includeOnPopUp}
                                onChange={() =>
                                  handleSetLayerParseProperties(
                                    layerIndex,
                                    propertyIndex,
                                    "includeOnPopUp",
                                    !property?.includeOnPopUp
                                  )
                                }
                                size={"lg"}
                                colorScheme="red"
                                className="pt-3"
                              />
                            </div>
                            <IconButton
                              aria-label="Remove data"
                              icon={<FaTrash />}
                              className="mt-11"
                              onClick={() =>
                                handleRemoveLayerParseProperties(
                                  layerIndex,
                                  propertyIndex
                                )
                              }
                            />
                          </div>
                        );
                      }
                    )}

                    <div className="flex justify-center pt-4">
                      <IconButton
                        aria-label="Add layer property"
                        icon={<FaPlus />}
                        onClick={() =>
                          handleAddLayerParseProperties(layerIndex)
                        }
                      />
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div className="flex justify-center mt-6">
            <IconButton
              aria-label="Add Step"
              icon={<FaPlus />}
              onClick={() => handleAddLayer()}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="bg-yellow-600 hover:bg-yellow-700 text-white p-2.5 px-4 rounded-md mr-4"
            onClick={handleSave}
          >
            Salvar
          </button>
          <button
            className="bg-transparent p-2.5 rounded-md"
            onClick={handleCancel}
          >
            Cancel
          </button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default LayersModal;

const layersKey = [
  "geom_apa",
  "geom_area_contaminada",
  "geom_area_envoltoria_condephaat",
  "geom_area_envoltoria_conpresp",
  "geom_area_envoltoria_iphan",
  "geom_area_manancial_billings",
  "geom_area_manancial_guarapiranga",
  "geom_area_manancial_juquery",
  "geom_bairro_ambiental",
  "geom_calcadas",
  "geom_classificacao_viaria",
  "geom_dis_dup",
  "geom_distrito",
  "geom_faixa_nao_edificavel",
  "geom_gasoduto",
  "geom_linha_alta_tensao",
  "geom_lote",
  "geom_melhoramento_viario",
  "geom_operacao_urbana",
  "geom_parque_estadual",
  "geom_parque_municipal",
  "geom_rampa_heliponto",
  "geom_remanescente_mata_atlantica",
  "geom_requalifica_centro_especial",
  "geom_requalifica_centro_geral",
  "geom_restricao_geotecnica",
  "geom_restricao_mirante_santana",
  "geom_risco_geologico",
  "geom_rppn",
  "geom_sitio_arqueologico",
  "geom_subprefeitura",
  "geom_tca",
  "geom_terra_indigena",
  "geom_tombado",
  "geom_uc",
  "geom_zoneamento_2016",
];
