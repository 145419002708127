import React, { useContext, useEffect, useState } from "react";
import { Input, SL } from "../../components";
import { HotkeyContext } from "../../reducers/hotkeys.reducer";
import { BsFillGridFill } from "react-icons/bs";
import { FaEdit, FaPlus, FaRegCopy, FaThList } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Select, Spinner } from "@chakra-ui/react";

const getColumnClasses = () => {
  if (window.innerWidth > 1280) return "grid-cols-4";
  if (window.innerWidth > 820) return "grid-cols-3";
  if (window.innerWidth > 560) return "grid-cols-2";
  return "grid-cols-1";
};

export const Subjects: React.FC = () => {
  const hotkeyContext = useContext(HotkeyContext);
  const navigate = useNavigate();
  const [subjects, setSubjects] = useState<Subject[]>([]);
  const [search, setSearch] = useState("");
  const [stage, setStage] = useState("development");
  const [isGridView, setIsGridView] = useState(
    localStorage.getItem("listView") !== "true"
  );
  const [columns, setColumns] = useState(getColumnClasses);
  const [loading, setLoading] = useState(true);
  const showEdit = !localStorage.getItem("showEdit");

  const fetchSubjects = async () => {
    setLoading(true);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACK_END_API}/subjects?stage=${stage}`,
        {
          headers: {
            authorization: `${localStorage.getItem("token")}`,
          },
        }
      );
      setSubjects(
        response.data.sort((a: any, b: any) =>
          a.field?.options?.title?.localeCompare(b.field?.options?.title)
        )
      );
    } catch (e) {}

    setLoading(false);
  };

  useEffect(() => {
    fetchSubjects();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [stage]);

  useEffect(() => {
    if (subjects.length === 0) {
      fetchSubjects();
    }

    hotkeyContext.dispatch({ type: "SET_HOTKEY", payload: buildHotkeys() });

    const handleResize = () => setColumns(getColumnClasses);
    window.addEventListener("resize", handleResize);

    return () => {
      hotkeyContext.dispatch({
        type: "UNSET_HOTKEY",
        delete: ["N", "G", "T", "S", "E"],
      });
      window.removeEventListener("resize", handleResize);
    };

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const buildHotkeys = () => ({
    N: () => showEdit && navigate("/subjects/new"),
    G: () => setView(false),
    T: () => setView(true),
    S: () => handlePromptIndex(handleRequest),
    E: () => handlePromptIndex(handEditSubject),
    D: () => handlePromptIndex(handleDuplicate),
  });

  const setView = (listView: boolean) => {
    localStorage.setItem("listView", listView.toString());
    setIsGridView(!listView);
  };

  const handlePromptIndex = async (handler: (id: string | number) => void) => {
    const index = await prompt("Insira o índice do assunto:");
    if (
      index !== "" &&
      index !== undefined &&
      index !== null &&
      Number(index) >= 0 &&
      subjects[Number(index)]
    ) {
      handler(subjects[Number(index)].id as string);
    }
  };

  const handleRequest = (subjectId: string | number) =>
    navigate(`/protocol/create/${subjectId}?stage=${stage}`);
  const handEditSubject = (subjectId: string | number) =>
    navigate(`/subjects/${subjectId}`);
  const handleDuplicate = (subjectId: string | number) => {
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BACK_END_API}/subjects/duplicate/${subjectId}`,
        {},
        {
          headers: {
            authorization: `${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        setSubjects([...subjects, response.data]);
        setLoading(false);
      });
  };

  const filteredSubjects = subjects.filter((subject: Subject) =>
    subject.field?.options?.title?.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div className="flex flex-col space-y-6 mb-24">
      <h1 className="text-2xl md:text-3xl font-black mb-2 text-center">
        Selecione o Assunto
      </h1>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="flex justify-between items-center px-6">
            <div className="flex-grow flex justify-between items-center space-x-4">
              <Input
                type="text"
                placeholder="Nome do assunto"
                size="lg"
                value={search}
                onChange={(e: any) => setSearch(e.target.value)}
                className="flex-grow"
              />
              <ViewButtons />
            </div>
          </div>
          <SubjectsContent stage={stage} setStage={setStage} />
        </>
      )}
    </div>
  );

  function LoadingSpinner() {
    return (
      <div className="pt-10 text-center">
        <Spinner size="xl" />
      </div>
    );
  }

  function SubjectsContent({
    stage,
    setStage,
  }: {
    stage: string;
    setStage: (stage: string) => void;
  }) {
    return (
      <>
        {showEdit && <AddNewButton stage={stage} setStage={setStage} />}
        <SubjectsList />
      </>
    );
  }

  function ViewButtons() {
    return (
      <div className="flex space-x-3.5">
        <ViewButton
          active={isGridView}
          icon={<BsFillGridFill size={32} />}
          onClick={() => setView(false)}
          title="Visualização em grade"
        />
        <ViewButton
          active={!isGridView}
          icon={<FaThList size={32} />}
          onClick={() => setView(true)}
          title="Visualização em lista"
        />
        <SL>G</SL>
        <SL>T</SL>
      </div>
    );
  }

  function ViewButton({
    active,
    icon,
    onClick,
    title,
  }: {
    active: boolean;
    icon: JSX.Element;
    onClick: () => void;
    title: string;
  }) {
    return (
      <button
        title={title}
        onClick={onClick}
        className={active ? "text-yellow-600" : ""}
      >
        {icon}
      </button>
    );
  }

  function AddNewButton({
    stage,
    setStage,
  }: {
    stage: string;
    setStage: (stage: string) => void;
  }) {
    return (
      <div className="flex text-center w-full px-6">
        <div>
          <Select
            size="lg"
            value={stage}
            onChange={(e) => setStage(e.target.value)}
          >
            <option value="development">Desenvolvimento</option>
            <option value="approval">Homologação</option>
            <option value="production">Produção</option>
          </Select>
        </div>
        <div className="flex-grow"></div>
        <div>
          <button
            className="bg-green-600 hover:bg-green-700 text-white px-8 py-2.5 text-lg rounded-lg disabled:opacity-80"
            onClick={() => navigate("/subjects/new")}
          >
            <FaPlus className="inline mr-2" />
            Assunto <SL bg="green.400">N</SL>
          </button>
        </div>
      </div>
    );
  }

  function SubjectsList() {
    return (
      <div
        className={isGridView ? `grid gap-4 px-6 ${columns}` : "px-6 space-y-4"}
      >
        {filteredSubjects.length > 0 ? (
          filteredSubjects.map((subject, index) => (
            <SubjectItem key={index} subject={subject} index={index} />
          ))
        ) : (
          <NoSubjects />
        )}
      </div>
    );
  }

  function NoSubjects() {
    return (
      <div className="flex flex-col mt-6 col-span-4 justify-center text-center text-gray-500 text-2xl">
        <div>Nenhum assunto encontrado</div>
      </div>
    );
  }

  function SubjectItem({
    subject,
    index,
  }: {
    subject: Subject;
    index: number;
  }) {
    return (
      <div
        className={
          isGridView
            ? "border-2 rounded-lg flex flex-col"
            : "border-2 rounded-lg flex flex-row space-x-4 p-4"
        }
      >
        {isGridView ? (
          <GridViewItem index={index} />
        ) : (
          <ListViewItem index={index} />
        )}
      </div>
    );

    function GridViewItem({ index }: { index: number }) {
      return (
        <>
          <div className="flex flex-col space-y-1.5 text-xl font-black p-4 flex-grow">
            <div>{subject.field?.options?.title}</div>
            <div>{showEdit && <EditButton index={index} />} </div>
            <div>{showEdit && <DuplicateButton index={index} />}</div>
          </div>
          <div className="p-4 flex-grow">
            {subject.field?.options?.description}
          </div>
          <div className="mx-auto mb-4">
            <RequestButton index={index} />
          </div>
        </>
      );
    }

    function ListViewItem({ index }: { index: number }) {
      return (
        <div className="flex w-full">
          <div className="flex flex-col">
            <div className="text-xl font-black">
              {subject.field?.options?.title}{" "}
              {showEdit && <EditButton index={index} />}{" "}
              {showEdit && <DuplicateButton index={index} />}
            </div>
            <div>{subject.field?.options?.description}</div>
          </div>
          <div className="ml-auto">
            <RequestButton index={index} />
          </div>
        </div>
      );
    }

    function RequestButton({ index }: { index: number }) {
      return (
        <button
          onClick={() => handleRequest(subject.id as string)}
          className="bg-yellow-600 hover:bg-yellow-700 w-full text-white text-lg py-2.5 px-8 rounded-lg disabled:opacity-80"
        >
          Solicitar <SL bg="yellow.500">S+{index}</SL>
        </button>
      );
    }

    function EditButton({ index }: { index: number }) {
      return (
        <>
          <FaEdit
            className="inline cursor-pointer ml-2"
            onClick={() => handEditSubject(subject.id as string)}
          />{" "}
          <SL>E+{index}</SL>
        </>
      );
    }

    function DuplicateButton({ index }: { index: number }) {
      return (
        <>
          <FaRegCopy
            className="inline cursor-pointer ml-2"
            onClick={() => handleDuplicate(subject.id as string)}
          />{" "}
          <SL>D+{index}</SL>
        </>
      );
    }
  }
};
