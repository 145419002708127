import axios from "axios";
import {
  PresetConfig,
  PresetsConfig,
  PresetsTypeConfig,
} from "./components/PresetList";
import { SecretConfig } from "./Secrets";
import { DatasetConfig } from "./Datasets";
import { FunctionConfig } from "./Functions";
import { FunctionsConfig } from "./components/FunctionsList";

export const createPreset = async (
  preset: PresetConfig
): Promise<PresetConfig> => {
  const response = await axios.post<PresetConfig>(
    `${process.env.REACT_APP_BACK_END_API}/presets`,
    preset,
    {
      headers: {
        authorization: `${localStorage.getItem("token")}`,
      },
    }
  );

  return response.data;
};

export const duplicatePreset = async (
  id: string
): Promise<PresetConfig | null> => {
  const response = await axios.post<PresetConfig | null>(
    `${process.env.REACT_APP_BACK_END_API}/presets/duplicate/${id}`,
    {},
    {
      headers: {
        authorization: `${localStorage.getItem("token")}`,
      },
    }
  );

  return response.data;
};

export const updatePreset = async (
  id: string,
  preset: PresetConfig & { commitMessage: string }
): Promise<PresetConfig | null> => {
  if (preset.type === "block") {
    preset.block = preset.block?.map((field) => {
      if (field.type === "preset") {
        delete field.preset;
        return {
          ...field,
        };
      }
      return field;
    }) as Field[];
  }

  const response = await axios.put<PresetConfig>(
    `${process.env.REACT_APP_BACK_END_API}/presets/${id}`,
    preset,
    {
      headers: {
        authorization: `${localStorage.getItem("token")}`,
      },
    }
  );

  return response.data;
};

export const deletePreset = async (
  id: string
): Promise<PresetConfig | null> => {
  const response = await axios.delete<PresetConfig | null>(
    `${process.env.REACT_APP_BACK_END_API}/presets/${id}`,
    {
      headers: {
        authorization: `${localStorage.getItem("token")}`,
      },
    }
  );

  return response.data;
};

export const listPresets = async (): Promise<PresetsConfig> => {
  const response = await axios.get<PresetsConfig>(
    `${process.env.REACT_APP_BACK_END_API}/presets`,
    {
      headers: {
        authorization: `${localStorage.getItem("token")}`,
      },
    }
  );

  return response.data;
};

export const findPresetVersion = async (
  id: string,
  version: number
): Promise<PresetsConfig> => {
  const response = await axios.get(
    `${process.env.REACT_APP_BACK_END_API}/presets/${id}/versions/${version}`,
    {
      headers: {
        authorization: `${localStorage.getItem("token")}`,
      },
    }
  );

  return response.data;
};

export const findPresetVersions = async (
  id: string
): Promise<
  {
    id: string;
    version: number;
    commitMessage: string;
    timestamp: string;
    createdBy: string;
  }[]
> => {
  const response = await axios.get(
    `${process.env.REACT_APP_BACK_END_API}/presets/${id}/versions`,
    {
      headers: {
        authorization: `${localStorage.getItem("token")}`,
      },
    }
  );

  return response.data;
};

export const listPresetsFields = async (): Promise<PresetsTypeConfig> => {
  const response = await axios.get<PresetsTypeConfig>(
    `${process.env.REACT_APP_BACK_END_API}/presets/fields`,
    {
      headers: {
        authorization: `${localStorage.getItem("token")}`,
      },
    }
  );

  return response.data;
};

export const listPresetsBlocks = async (): Promise<PresetsTypeConfig> => {
  const response = await axios.get<PresetsTypeConfig>(
    `${process.env.REACT_APP_BACK_END_API}/presets/blocks`,
    {
      headers: {
        authorization: `${localStorage.getItem("token")}`,
      },
    }
  );

  return response.data;
};

export const getPreset = async (
  id: string
): Promise<
  | (PresetConfig & {
      $user: any;
      $environment: { [key: string]: string };
      $function: { [key: string]: { [key: string]: string } };
    })
  | null
> => {
  const response = await axios.get<
    | (PresetConfig & {
        $user: any;
        $environment: { [key: string]: string };
        $function: { [key: string]: { [key: string]: string } };
      })
    | null
  >(`${process.env.REACT_APP_BACK_END_API}/presets/${id}`, {
    headers: {
      authorization: `${localStorage.getItem("token")}`,
    },
  });

  const preset = response.data;

  if (preset !== null && (preset as PresetConfig).type === "block") {
    for (let field of ((preset as PresetConfig)?.block ?? []) as Field[]) {
      if (field.type === "preset") {
        field.preset = (await getPreset(field.key))?.field;
      }
    }
  }

  return preset;
};

export const createSecret = async (
  secret: SecretConfig
): Promise<SecretConfig> => {
  const response = await axios.post<SecretConfig>(
    `${process.env.REACT_APP_BACK_END_API}/secrets`,
    secret,
    {
      headers: {
        authorization: `${localStorage.getItem("token")}`,
      },
    }
  );

  return response.data;
};

export const updateSecret = async (
  id: string,
  secret: SecretConfig
): Promise<SecretConfig | null> => {
  const response = await axios.put<SecretConfig>(
    `${process.env.REACT_APP_BACK_END_API}/secrets/${id}`,
    secret,
    {
      headers: {
        authorization: `${localStorage.getItem("token")}`,
      },
    }
  );

  return response.data;
};

export const deleteSecret = async (
  id: string
): Promise<SecretConfig | null> => {
  const response = await axios.delete<SecretConfig | null>(
    `${process.env.REACT_APP_BACK_END_API}/secrets/${id}`,
    {
      headers: {
        authorization: `${localStorage.getItem("token")}`,
      },
    }
  );

  return response.data;
};

export const listSecrets = async (): Promise<SecretConfig[]> => {
  const response = await axios.get<SecretConfig[]>(
    `${process.env.REACT_APP_BACK_END_API}/secrets`,
    {
      headers: {
        authorization: `${localStorage.getItem("token")}`,
      },
    }
  );

  return response.data;
};

export const getSecret = async (id: string): Promise<SecretConfig | null> => {
  const response = await axios.get<SecretConfig | null>(
    `${process.env.REACT_APP_BACK_END_API}/secrets/${id}`,
    {
      headers: {
        authorization: `${localStorage.getItem("token")}`,
      },
    }
  );

  return response.data;
};

export const createDataset = async (
  dataset: DatasetConfig
): Promise<DatasetConfig> => {
  const response = await axios.post<DatasetConfig>(
    `${process.env.REACT_APP_BACK_END_API}/datasets`,
    dataset,
    {
      headers: {
        authorization: `${localStorage.getItem("token")}`,
      },
    }
  );

  return response.data;
};

export const createDatasetTable = async (body: {
  id: string;
}): Promise<{ message: string; error?: string }> => {
  const response = await axios.post<{ message: string; error?: string }>(
    `${process.env.REACT_APP_BACK_END_API}/datasets/create-table`,
    body,
    {
      headers: {
        authorization: `${localStorage.getItem("token")}`,
      },
    }
  );

  return response.data;
};

export const updateDataset = async (
  id: string,
  dataset: DatasetConfig
): Promise<DatasetConfig | null> => {
  const response = await axios.put<DatasetConfig>(
    `${process.env.REACT_APP_BACK_END_API}/datasets/${id}`,
    dataset,
    {
      headers: {
        authorization: `${localStorage.getItem("token")}`,
      },
    }
  );

  return response.data;
};

export const deleteDataset = async (
  id: string
): Promise<DatasetConfig | null> => {
  const response = await axios.delete<DatasetConfig | null>(
    `${process.env.REACT_APP_BACK_END_API}/datasets/${id}`,
    {
      headers: {
        authorization: `${localStorage.getItem("token")}`,
      },
    }
  );

  return response.data;
};

export const listDatasets = async (): Promise<DatasetConfig[]> => {
  const response = await axios.get<DatasetConfig[]>(
    `${process.env.REACT_APP_BACK_END_API}/datasets`,
    {
      headers: {
        authorization: `${localStorage.getItem("token")}`,
      },
    }
  );

  return response.data;
};

export const getDataset = async (id: string): Promise<DatasetConfig | null> => {
  const response = await axios.get<DatasetConfig | null>(
    `${process.env.REACT_APP_BACK_END_API}/datasets/${id}`,
    {
      headers: {
        authorization: `${localStorage.getItem("token")}`,
      },
    }
  );

  return response.data;
};

export const createEnvironment = async (
  environment: EnvironmentConfig
): Promise<EnvironmentConfig> => {
  const response = await axios.post<EnvironmentConfig>(
    `${process.env.REACT_APP_BACK_END_API}/environments`,
    environment,
    {
      headers: {
        authorization: `${localStorage.getItem("token")}`,
      },
    }
  );

  return response.data;
};

export const updateEnvironment = async (
  id: string | number,
  environment: EnvironmentConfig & { commitMessage: string }
): Promise<EnvironmentConfig | null> => {
  const response = await axios.put<EnvironmentConfig>(
    `${process.env.REACT_APP_BACK_END_API}/environments/${id}`,
    environment,
    {
      headers: {
        authorization: `${localStorage.getItem("token")}`,
      },
    }
  );

  return response.data;
};

export const deleteEnvironment = async (
  id: string | number
): Promise<EnvironmentConfig | null> => {
  const response = await axios.delete<EnvironmentConfig | null>(
    `${process.env.REACT_APP_BACK_END_API}/environments/${id}`,
    {
      headers: {
        authorization: `${localStorage.getItem("token")}`,
      },
    }
  );

  return response.data;
};

export const listEnvironments = async (): Promise<EnvironmentConfig[]> => {
  const response = await axios.get<EnvironmentConfig[]>(
    `${process.env.REACT_APP_BACK_END_API}/environments`,
    {
      headers: {
        authorization: `${localStorage.getItem("token")}`,
      },
    }
  );

  return response.data;
};

export const getEnvironment = async (
  id: string | number
): Promise<EnvironmentConfig | null> => {
  const response = await axios.get<EnvironmentConfig | null>(
    `${process.env.REACT_APP_BACK_END_API}/environments/${id}`,
    {
      headers: {
        authorization: `${localStorage.getItem("token")}`,
      },
    }
  );

  return response.data;
};

export const findEnvironmentVersion = async (
  id: string | number,
  version: number
): Promise<EnvironmentConfig> => {
  const response = await axios.get(
    `${process.env.REACT_APP_BACK_END_API}/environments/${id}/versions/${version}`,
    {
      headers: {
        authorization: `${localStorage.getItem("token")}`,
      },
    }
  );

  return response.data;
};

export const findEnvironmentVersions = async (
  id: string | number
): Promise<
  {
    id: string;
    version: number;
    commitMessage: string;
    timestamp: string;
    createdBy: string;
  }[]
> => {
  const response = await axios.get(
    `${process.env.REACT_APP_BACK_END_API}/environments/${id}/versions`,
    {
      headers: {
        authorization: `${localStorage.getItem("token")}`,
      },
    }
  );

  return response.data;
};

export const createFunction = async (
  fun: FunctionConfig
): Promise<FunctionConfig> => {
  const response = await axios.post<FunctionConfig>(
    `${process.env.REACT_APP_BACK_END_API}/functions`,
    fun,
    {
      headers: {
        authorization: `${localStorage.getItem("token")}`,
      },
    }
  );

  return response.data;
};

export const updateFunction = async (
  id: string,
  fun: FunctionConfig
): Promise<FunctionConfig | null> => {
  const response = await axios.put<FunctionConfig>(
    `${process.env.REACT_APP_BACK_END_API}/functions/${id}`,
    fun,
    {
      headers: {
        authorization: `${localStorage.getItem("token")}`,
      },
    }
  );

  return response.data;
};

export const deleteFunction = async (
  id: string
): Promise<FunctionConfig | null> => {
  const response = await axios.delete<FunctionConfig | null>(
    `${process.env.REACT_APP_BACK_END_API}/functions/${id}`,
    {
      headers: {
        authorization: `${localStorage.getItem("token")}`,
      },
    }
  );

  return response.data;
};

export const listFunctions = async (): Promise<FunctionsConfig> => {
  const response = await axios.get<FunctionsConfig>(
    `${process.env.REACT_APP_BACK_END_API}/functions`,
    {
      headers: {
        authorization: `${localStorage.getItem("token")}`,
      },
    }
  );

  return response.data;
};

export const getFunction = async (
  id: string
): Promise<FunctionConfig | null> => {
  const response = await axios.get<FunctionConfig | null>(
    `${process.env.REACT_APP_BACK_END_API}/functions/${id}`,
    {
      headers: {
        authorization: `${localStorage.getItem("token")}`,
      },
    }
  );

  return response.data;
};
