import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Box,
} from "@chakra-ui/react";
import { FunctionConfig } from "../Functions";

export type FunctionsConfig = {
  [preset: string]: FunctionConfig[];
};

export interface FunctionListProps {
  functions: FunctionsConfig;
  search: string;
  onClick: (presetItem: FunctionConfig) => void;
}

export const FunctionsList: React.FC<FunctionListProps> = ({
  functions,
  search,
  onClick,
}): JSX.Element[] => {
  const filteredFunctions: FunctionsConfig = {};

  Object.keys(functions).forEach((groupName) => {
    filteredFunctions[groupName] = [];

    let groupHasAnyFunction = false;

    const items = functions[groupName].filter((item) => {
      return item.title.toLowerCase().includes(search.toLowerCase());
    });

    if (items.length) {
      filteredFunctions[groupName] = items;
      groupHasAnyFunction = true;
    }

    if (!groupHasAnyFunction) {
      delete filteredFunctions[groupName];
    }
  });

  return Object.keys(filteredFunctions)
    .sort()
    .map((groupName) => {
      return (
        <Accordion
          key={"preset-group-" + groupName}
          allowToggle
          border={0}
          className="-mx-6"
        >
          <AccordionItem border={0}>
            <AccordionButton>
              <Box className="pl-4" as="span" flex="1" textAlign="left">
                {groupName}
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              {filteredFunctions[groupName]
                .sort((a, b) => a.title.localeCompare(b.title))
                .map((item) => {
                  return (
                    <div
                      key={"function-item-" + item.id}
                      className="-mx-2 pl-10 py-2 hover:bg-gray-200"
                    >
                      <button
                        onClick={() => onClick(item)}
                        className="flex justify-between w-full items-center"
                      >
                        {item.title}
                      </button>
                    </div>
                  );
                })}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      );
    });
};
