import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { HotkeyContext } from "../../reducers/hotkeys.reducer";
import { IconButton, Spinner, Tag } from "@chakra-ui/react";
import { FaTrash } from "react-icons/fa";
import { COLOR_MAPPER, LABEL_MAPPER, Link } from "./MyRepresentation";

export function MyRepresentatives(): JSX.Element {
  const hotkeyContext = useContext(HotkeyContext);
  const [representatives, setRepresentatives] = useState<Link[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchReprenstatives = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACK_END_API}/users/represented`,
        {
          headers: {
            authorization: `${localStorage.getItem("token")}`,
          },
        }
      );

      setRepresentatives(response.data);
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchReprenstatives();
  }, []);

  const handleDeleteLink = async (
    represented: string,
    representative: string,
    link: string
  ) => {
    setLoading(true);

    try {
      await axios.delete(`${process.env.REACT_APP_BACK_END_API}/users/link`, {
        data: {
          represented,
          representative,
          link,
        },
        headers: {
          authorization: `${localStorage.getItem("token")}`,
        },
      });
    } catch (error) {
      console.log(error);
    }

    await fetchReprenstatives();
  };

  useEffect(() => {
    hotkeyContext.dispatch({
      type: "SET_HOTKEY",
      payload: {},
    });

    return () => {
      hotkeyContext.dispatch({
        type: "UNSET_HOTKEY",
        delete: [],
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div>
        <h1 className="text-xl md:text-2xl font-bold text-center mb-6">
          Meus Representantes
        </h1>
        {loading && (
          <div className="pt-10 text-center">
            <Spinner size="xl" />
          </div>
        )}
        {!loading &&
          representatives?.map((representative) => (
            <div className="flex space-x-6 border rounded-md p-6">
              <div className="flex flex-col">
                <div>
                  Documento:{" "}
                  <span className="font-black ">
                    {representative.representative}
                  </span>
                </div>
                <div>
                  Vínculo:{" "}
                  <span className="font-bold">{representative.link}</span>
                </div>
              </div>
              <div>
                <div>Emitido em:</div>
                <div>
                  {new Date(representative.timestamp).toLocaleString("pt-br")}
                </div>
              </div>
              <div>
                <div>Atualizado em:</div>
                <div>
                  {new Date(representative.updatedAt).toLocaleString("pt-br")}
                </div>
              </div>
              <div className="flex-grow"></div>
              <div>
                <Tag color={COLOR_MAPPER[representative.status]}>
                  {LABEL_MAPPER[representative.status]}
                </Tag>
              </div>
              {representative.status !== "CANCELLED" && (
                <div>
                  <IconButton
                    size={"lg"}
                    aria-label="Remove link"
                    icon={<FaTrash />}
                    onClick={() => {
                      handleDeleteLink(
                        representative.represented,
                        representative.representative,
                        representative.link
                      );
                    }}
                    className="ml-2"
                  />
                </div>
              )}
            </div>
          ))}
      </div>
    </>
  );
}
