import axios from "axios";
import { Map } from "./core/fields";
import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { parseFunctions } from "./core/utils/parsers";
import { evalFieldExpression } from "./core/utils/expressions";

export const PlatePrint = (): JSX.Element => {
  const { id } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const version = searchParams.get("version");

  const [plate, setPlate] = useState<DocumentLayout>();
  const [status, setStatus] = useState<any>({});
  const [context, setContext] = useState<any>({});
  const [general, setGeneral] = useState<any>({});
  const [isQRCodeLoaded, setQRCodeLoaded] = useState(false);
  const [isLogoLoaded, setLogoLoaded] = useState(false);

  const fetchSubject = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACK_END_API}/protocols/api/${id}${
          version ? `/version/${version}` : ""
        }`,
        {
          headers: {
            apiKey: localStorage.getItem("apiKey"),
          },
        }
      );

      delete response.data.id;

      setPlate(response.data.plate);
      setStatus(version ? "APOSTILLED" : response.data.status);
      setContext(response.data.protocol);
      setGeneral({
        acceptances: response.data.acceptances,
        $environment: response.data.environment,
        $library: parseFunctions(response.data.function ?? {}),
        $history: response.data.diff,
        $commits: response.data.versions,
      });
    } catch (e) {}
  };

  useEffect(() => {
    fetchSubject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isAllLoaded = isQRCodeLoaded && isLogoLoaded;

  const pdfPageStyle: React.CSSProperties = {
    minWidth: "2840px",
    minHeight: "4040px",
    boxSizing: "border-box",
    backgroundColor: "white",
  };

  const watermarkStyle: React.CSSProperties = {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    pointerEvents: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "5em",
    color: "rgba(200, 200, 200, 0.5)",
    transform: "rotate(-45deg)",
    zIndex: 9999,
  };

  const element = document.querySelector("div.enabled");

  if (element) {
    element.parentNode?.removeChild(element);
  }

  const PROTOCOL_STATUS_WATERMARK: any = {
    WAITING_ACCEPTANCE: "AGUARDANDO ACEITE",
    WAITING_TAX_PAYMENT: "AGUARDANDO PAGAMENTO",
    APOSTILLED: "APOSTILADO",
  };

  if (plate) {
    return (
      <div id={isAllLoaded ? "waitNoMore" : undefined} style={pdfPageStyle}>
        <div style={watermarkStyle}>
          {PROTOCOL_STATUS_WATERMARK[status] ?? ""}
        </div>

        <header className="flex flex-col py-40 px-40">
          <div className="flex space-x-40">
            <img
              src={plate.logo}
              width="400px"
              alt="city logo"
              onLoad={() => setLogoLoaded(true)}
            />
            <div className="flex flex-col space-y-6">
              <div className="font-black text-8xl mb-6">
                {plate.headerTitle}
              </div>
              <div className="text-6xl">{plate.headerDescription}</div>
            </div>
          </div>
        </header>
        <main className="flex flex-col space-y-10 px-40">
          <div className="flex items-center space-x-40">
            <img
              src={plate.qrcode}
              style={{ minWidth: "755px" }}
              alt="qrcode to document"
              onLoad={() => setQRCodeLoaded(true)}
            />
            <div className="flex flex-col space-y-20 font-bold text-4xl w-full">
              <div
                className="justify-center text-center"
                style={{ fontSize: "9rem", lineHeight: 1.1 }}
              >
                {plate.title}
              </div>
              <div className="justify-center text-center text-8xl">
                {plate.description}
              </div>
            </div>
          </div>
          <div className="flex-col space-y-6 font-bold text-5xl">
            {plate &&
              plate.blocks.map((block) => (
                <>
                  <div className="flex items-center space-x-4 font-bold pt-16 mb-10">
                    {block.label}
                  </div>
                  {block.map?.source && (
                    <div className="flex justify-center">
                      <Map
                        key={"map"}
                        options={{
                          label: "",
                          layers: block.map.layers,
                          width: block.map.width ?? "750px",
                          height: block.map.height ?? "750px",
                          table: false,
                          zoomControl: false,
                          hideHeader: true,
                        }}
                        value={evalFieldExpression(
                          block.map.source,
                          context,
                          {},
                          {}
                        )}
                      />
                    </div>
                  )}
                  <div className="flex flex-col space-y-12">
                    {block?.rows.map((row, rowIndex) => (
                      <div
                        key={"row-" + rowIndex}
                        className="flex items-center space-x-4"
                      >
                        <div className="flex space-x-10 w-full">
                          {row.map((col) => {
                            return (
                              <div className="flex w-full">
                                <div className="flex flex-col space-y-6">
                                  <div className="flex items-center space-x-4 text-gray-500">
                                    {col.label}
                                  </div>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: evalFieldExpression(
                                        `\`${col.value}\``,
                                        context,
                                        general,
                                        {}
                                      ),
                                    }}
                                  ></div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              ))}
          </div>
        </main>
      </div>
    );
  } else {
    return <></>;
  }
};
