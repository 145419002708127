import React, { useEffect, useState } from "react";
import { Table, Thead, Tbody, Tr, Th, Td, Spinner } from "@chakra-ui/react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { SL } from "../../components";

export function MyAcceptances(): JSX.Element {
  const [data, setData] = useState<any>([]);
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const isFirstPage = currentPage === 0;
  const isLastPage = currentPage === Math.ceil(data.length / pageSize) - 1;

  const nextPage = () => {
    if (!isLastPage) {
      setCurrentPage((currentPage) => {
        handleFetchAcceptances(currentPage + 1);
        return currentPage + 1;
      });
    }
  };

  const prevPage = () => {
    if (!isFirstPage) {
      setCurrentPage((currentPage) => {
        handleFetchAcceptances(currentPage - 1);
        return currentPage - 1;
      });
    }
  };

  const handleFetchAcceptances = async (page = 1, limit = 10) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACK_END_API}/protocols/acceptances/my?page=${page}&limit=${limit}`,
        {
          headers: {
            authorization: `${localStorage.getItem("token")}`,
          },
        }
      );

      setData(response.data);
    } catch (e) {}

    setLoading(false);
  };

  useEffect(() => {
    handleFetchAcceptances();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex space-y-6 justify-center sm:px-0 md:px-6 mb-24">
      <div className="flex flex-col mx-6 md:mx-0 justify-center space-y-4">
        <h1 className="text-2xl md:text-3xl font-black text-center">
          Meus Aceites
        </h1>
        {loading && (
          <div className="pt-10 text-center">
            <Spinner size="xl" />
          </div>
        )}
        {!loading && (
          <div className="flex flex-col pt-6">
            {window.innerWidth > 500 && (
              <Table variant="simple" size="lg" style={{ minWidth: 500 }}>
                <Thead>
                  <Tr>
                    <Th>Pedido</Th>
                    <Th>Tipo</Th>
                    <Th>Data</Th>
                  </Tr>
                </Thead>
                <Tbody className="text-lg cursor-pointer">
                  {data.map((item: any, index: number) => (
                    <Tr
                      key={index}
                      onClick={() => navigate(`/protocol/${item.protocol}/document`)}
                    >
                      <Td>{item.protocol}</Td>
                      <Td>{item.type}</Td>
                      <Td>
                        {new Date(item.timestamp).toLocaleString("pt-br")}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            )}
            {data.length === 0 && (
              <div className="w-full mx-auto mt-6 text-center text-gray-500 text-2xl">
                <div>Nenhum pedido encontrado</div>
              </div>
            )}
            {window.innerWidth <= 500 && (
              <Table variant="simple" size="lg">
                <Tbody className="text-lg cursor-pointer">
                  {data.map((item: any, index: number) => (
                    <Tr
                      key={index}
                      onClick={() => navigate(`/protocol/${item.id}/document`)}
                    >
                      <Td margin={0}>
                        <div className="mb-1.5">
                          <b>Pedido:</b> {item.id}
                        </div>
                        <div className="mb-1.5">
                          <b>Assunto:</b> {item.field.options.title}
                        </div>
                        <div>
                          <b>Data:</b>{" "}
                          {new Date(item.timestamp).toLocaleString("pt-br")}
                        </div>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            )}

            <div className="flex flex-wrap space-x-6 items-center mx-auto w-full mt-10">
              <div className="text-lg">Página 1 de 1</div>
              <div className="flex-grow"></div>
              <div className="flex space-x-6">
                <button
                  className="py-2 px-4 text-lg cursor-pointer rounded-md bg-yellow-600 hover:bg-yellow-700 text-white"
                  onClick={prevPage}
                  disabled={isFirstPage}
                >
                  Anterior <SL bg="yellow.500">←</SL>
                </button>
                <button
                  className="py-2 px-4 text-lg cursor-pointer rounded-md bg-yellow-600 hover:bg-yellow-700 text-white"
                  onClick={nextPage}
                  disabled={isLastPage}
                >
                  Próximo <SL bg="yellow.500">→</SL>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
