import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useContext } from "react";
import { StyleContext } from "../../../../reducers";
import { FaExpand } from "react-icons/fa";
import { evalFieldExpression } from "../utils/expressions";

export type FieldIntegrationProps = {
  field: Field;
  options: Pick<FieldOptions, "log">;
  value?:
    | { [key: string]: any }
    | { $cache: { url: string }; $timestamp: number };
};

type Log = {
  source: string;
  message: string;
  timestamp: Date | string | number;
};

export const Integration: React.FC<FieldIntegrationProps> = ({
  field,
  options,
  value,
}) => {
  const styleContext = useContext(StyleContext);
  const { isOpen, onOpen, onClose } = useDisclosure();

  let dynamicLogValue: Log[] | undefined = undefined;

  if (
    field.expressions?.log !== undefined &&
    field.expressions?.log.length > 0
  ) {
    dynamicLogValue = evalFieldExpression(
      field.expressions?.log,
      value,
      {},
      {}
    );
  }

  const displayLog = options.log === "true" || options.log === true;
  const displayDefaultLogValue =
    displayLog && dynamicLogValue === undefined && value?.$cache !== undefined;
  const displayDynamicLogValue =
    dynamicLogValue !== undefined && Array.isArray(dynamicLogValue);

  return (
    <>
      {displayDefaultLogValue && (
        <div className="rounded border my-2 p-4">
          <div className="flex justify-end space-x-2">
            <FaExpand
              title="Mostrar Retorno da Integração"
              size={16}
              onClick={onOpen}
              className="cursor-pointer"
            ></FaExpand>
          </div>
          <div>Fonte: {value.$cache.url}</div>
          <div>
            Data: {new Date(value.$timestamp).toLocaleDateString("pt-br")}{" "}
            {new Date(value.$timestamp).toLocaleTimeString("pt-br")}
          </div>
        </div>
      )}
      {displayDynamicLogValue &&
        dynamicLogValue?.map((l: Log) => {
          return (
            <div className="rounded border my-2 p-4">
              <div className="flex justify-end space-x-2">
                <FaExpand
                  title="Mostrar Retorno da Integração"
                  size={16}
                  onClick={onOpen}
                  className="cursor-pointer"
                ></FaExpand>
              </div>
              <div>{l.message}</div>
              <div>Fonte: {l.source}</div>
              <div>
                Data: {new Date(l.timestamp).toLocaleDateString("pt-br")}{" "}
                {new Date(l.timestamp).toLocaleTimeString("pt-br")}
              </div>
            </div>
          );
        })}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          className="px-4 py-6"
          style={{ minWidth: 800, maxHeight: "80vh" }}
          bg={styleContext.state.backgroundColor}
        >
          <ModalHeader>Retorno da Integração</ModalHeader>
          <ModalCloseButton />
          <ModalBody overflowY="auto" wordBreak="break-word">
            <pre>{JSON.stringify(value, null, 2)}</pre>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
