import {
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  useDisclosure,
  MenuGroup,
  Portal,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { FaCaretRight, FaPlus } from "react-icons/fa";
import { getPreset, listPresetsBlocks, listPresetsFields } from "../api";
import { PresetConfig } from "./PresetList";
import { StyleContext } from "../../../reducers";

interface AddFieldMenuProps {
  addFieldCallback: (config: Field | null) => void;
  showPresets?: boolean;
  showPresetsBlocks?: boolean;
}

export const AddFieldMenu: React.FC<AddFieldMenuProps> = ({
  addFieldCallback,
  showPresets,
  showPresetsBlocks,
}) => {
  const styleContext = useContext(StyleContext);
  const menuController = useDisclosure();
  const submenuController = useDisclosure();
  const customSubmenuController = useDisclosure();
  const [groupSelected, setGroupSelected] = useState<{
    group: string;
    type: "field" | "block";
  }>();
  const [presetsFieldGroups, setPresetsFieldGroups] = useState<any>({});
  const [presetsBlockGroups, setPresetsBlockGroups] = useState<any>({});

  const fieldTypes: [FieldTypes, string][] = [
    ["block", "Bloco"],
    ["title", "Título"],
    ["subtitle", "Descrição"],
    ["input", "Texto simples"],
    ["textarea", "Texto em área"],
    ["select", "Seleção"],
    ["radio", "Escolha"],
    ["checkbox", "Múltipla escolha"],
    ["upload", "Anexo"],
    ["integration", "Integração"],
    ["link", "Vínculo"],
    ["map", "Mapa"],
    ["array", "Arranjo"],
    ["table", "Tabela"],
  ];

  const template = (type: FieldTypes): Field => {
    return {
      type,
      key: (Math.random() + 1).toString(36).substring(7),
      options: {
        ...(type === "title" ? { title: "Insira o título" } : {}),
        ...(type === "subtitle"
          ? { html: "Insira o subtitulo (suporta sintaxe HTML)" }
          : {}),
        ...(!["title", "subtitle", "integration", "link"].includes(type)
          ? { label: "Insira a etiqueta" }
          : {}),
        ...(!["title", "subtitle"].includes(type) ? { required: "true" } : {}),
        ...(type === "block"
          ? {
              card: true,
              open: true,
            }
          : {}),
      },
      expressions: {},
      ...(type === "array" || type === "block"
        ? {
            block: [],
          }
        : {}),
    };
  };

  const presetTemplate = async (
    preset: PresetConfig
  ): Promise<Field | null> => {
    if (preset.id !== undefined) {
      const presetConfig = await getPreset(preset.id as string);

      if (presetConfig) {
        return {
          type: "preset",
          key: preset.id as string,
          preset: presetConfig.field || presetConfig.block,
          options: {
            title: preset.title,
            group: preset.group,
          },
        };
      }
    }

    return null;
  };

  const addPresetCallback = async (preset: PresetConfig) => {
    const presetConfig = await presetTemplate(preset);
    addFieldCallback(presetConfig);
  };

  const fetchPresetGroups = async () => {
    const fields = await listPresetsFields();

    if (showPresetsBlocks) {
      const blocks = await listPresetsBlocks();
      setPresetsBlockGroups(blocks);
    }

    setPresetsFieldGroups(fields);
  };

  useEffect(() => {
    if (showPresets) {
      fetchPresetGroups();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="text-left">
      <Menu isOpen={menuController.isOpen} onClose={menuController.onClose}>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<FaPlus />}
          onClick={menuController.onOpen}
        />
        <Portal>
          <MenuList
            zIndex={"overlay"}
            bg={styleContext.state.backgroundColor}
            maxHeight="300px"
            overflowY="auto"
          >
            <MenuItem
              onClick={submenuController.onOpen}
              icon={<FaCaretRight />}
              bg={styleContext.state.backgroundColor}
            >
              Padrão
            </MenuItem>
            {Object.keys(presetsFieldGroups).length > 0 && (
              <MenuGroup title="Campos"></MenuGroup>
            )}
            {Object.keys(presetsFieldGroups)
              .sort()
              .map((group) => (
                <MenuItem
                  onClick={() => {
                    setGroupSelected({ group, type: "field" });
                    customSubmenuController.onOpen();
                  }}
                  icon={<FaCaretRight />}
                  bg={styleContext.state.backgroundColor}
                >
                  {group}
                </MenuItem>
              ))}
            {Object.keys(presetsBlockGroups).length > 0 && (
              <MenuGroup title="Blocos"></MenuGroup>
            )}
            {Object.keys(presetsBlockGroups)
              .sort()
              .map((group) => (
                <MenuItem
                  onClick={() => {
                    setGroupSelected({ group, type: "block" });
                    customSubmenuController.onOpen();
                  }}
                  icon={<FaCaretRight />}
                  bg={styleContext.state.backgroundColor}
                >
                  {group}
                </MenuItem>
              ))}
          </MenuList>
        </Portal>
      </Menu>
      <Menu
        isOpen={submenuController.isOpen}
        onClose={submenuController.onClose}
      >
        <MenuButton></MenuButton>
        <Portal>
          <MenuList
            zIndex={"overlay"}
            bg={styleContext.state.backgroundColor}
            maxHeight="300px"
            overflowY="auto"
          >
            {fieldTypes
              .sort((a, b) => a[1].localeCompare(b[1]))
              .map((types) => (
                <MenuItem
                  onClick={() => addFieldCallback(template(types[0]))}
                  bg={styleContext.state.backgroundColor}
                >
                  {types[1]}
                </MenuItem>
              ))}
          </MenuList>
        </Portal>
      </Menu>
      <Menu
        isOpen={customSubmenuController.isOpen}
        onClose={customSubmenuController.onClose}
      >
        <MenuButton></MenuButton>
        <Portal>
          <MenuList
            zIndex={"overlay"}
            bg={styleContext.state.backgroundColor}
            maxHeight="300px"
            overflowY="auto"
          >
            {groupSelected?.type === "field" &&
              (presetsFieldGroups as any)[groupSelected.group]
                ?.sort((a: any, b: any) => a.title.localeCompare(b.title))
                ?.map((preset: PresetConfig) => (
                  <MenuItem
                    onClick={() => addPresetCallback(preset)}
                    bg={styleContext.state.backgroundColor}
                  >
                    {preset.title}
                  </MenuItem>
                ))}
            {groupSelected?.type === "block" &&
              (presetsBlockGroups as any)[groupSelected.group]
                ?.sort((a: any, b: any) => a.title.localeCompare(b.title))
                .map((preset: PresetConfig) => (
                  <MenuItem
                    onClick={() => addPresetCallback(preset)}
                    bg={styleContext.state.backgroundColor}
                  >
                    {preset.title}
                  </MenuItem>
                ))}
          </MenuList>
        </Portal>
      </Menu>
    </div>
  );
};
