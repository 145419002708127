import React from "react";
import {
  Select as ChakraSelect,
  SelectProps as ChakraSelectProps,
} from "@chakra-ui/react";

interface SelectProps extends ChakraSelectProps {
  disabled?: boolean;
}

export const Select: React.FC<SelectProps> = ({ disabled, ...props }) => {
  return (
    <ChakraSelect
      {...props}
      disabled={disabled}
      pointerEvents={disabled ? "inherit" : "auto"}
    />
  );
};
