import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { HotkeyContext } from "../../reducers/hotkeys.reducer";
import { IconButton, Spinner, Tag } from "@chakra-ui/react";
import { FaCheck, FaTrash } from "react-icons/fa";
import { MdClose } from "react-icons/md";

export type Link = {
  generatedBy: string;
  generatedType: string;
  link: string;
  representative: string;
  represented: string;
  status: "PENDING" | "ACCEPTED" | "DECLINED" | "CANCELLED";
  timestamp: Date;
  updatedAt: Date;
};

export const COLOR_MAPPER = {
  PENDING: "gray",
  ACCEPTED: "green",
  DECLINED: "red",
  CANCELLED: "red",
};

export const LABEL_MAPPER = {
  PENDING: "Pendente",
  ACCEPTED: "Aceito",
  DECLINED: "Recusado",
  CANCELLED: "Cancelado",
};

export function MyRepresentations(): JSX.Element {
  const hotkeyContext = useContext(HotkeyContext);
  const [representations, setRepresentations] = useState<Link[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchRepresentations = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACK_END_API}/users/representatives`,
        {
          headers: {
            authorization: `${localStorage.getItem("token")}`,
          },
        }
      );

      setRepresentations(response.data);
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchRepresentations();
  }, []);

  const handleUpdateStatus = async (
    represented: string,
    representative: string,
    link: string,
    newStatus: "PENDING" | "ACCEPTED" | "DECLINED" | "CANCELLED"
  ) => {
    setLoading(true);

    try {
      await axios.put(
        `${process.env.REACT_APP_BACK_END_API}/users/link`,
        {
          represented,
          representative,
          link,
          newStatus,
        },
        {
          headers: {
            authorization: `${localStorage.getItem("token")}`,
          },
        }
      );
    } catch (error) {
      console.log(error);
    }

    await fetchRepresentations();
  };

  const handleDeleteLink = async (
    represented: string,
    representative: string,
    link: string
  ) => {
    setLoading(true);

    try {
      await axios.delete(`${process.env.REACT_APP_BACK_END_API}/users/link`, {
        data: {
          represented,
          representative,
          link,
        },
        headers: {
          authorization: `${localStorage.getItem("token")}`,
        },
      });
    } catch (error) {
      console.log(error);
    }

    await fetchRepresentations();
  };

  useEffect(() => {
    hotkeyContext.dispatch({
      type: "SET_HOTKEY",
      payload: {},
    });

    return () => {
      hotkeyContext.dispatch({
        type: "UNSET_HOTKEY",
        delete: [],
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div>
        <h1 className="text-xl md:text-2xl font-bold text-center mb-6">
          Minhas Representações
        </h1>
        {loading && (
          <div className="pt-10 text-center">
            <Spinner size="xl" />
          </div>
        )}
        {!loading &&
          representations?.map((representation) => (
            <div className="flex space-x-6 border rounded-md p-6">
              <div className="flex flex-col">
                <div>
                  Documento:{" "}
                  <span className="font-black ">
                    {representation.represented}
                  </span>
                </div>
                <div>
                  Vínculo:{" "}
                  <span className="font-bold">{representation.link}</span>
                </div>
              </div>
              <div className="flex flex-col space-y-4">
                <div>
                  <div>Emitido em:</div>
                  <div>
                    {new Date(representation.timestamp).toLocaleString("pt-br")}
                  </div>
                </div>
              </div>
              <div>
                <div>Atualizado em:</div>
                <div>
                  {new Date(representation.updatedAt).toLocaleString("pt-br")}
                </div>
              </div>
              <div className="flex-grow"></div>
              <div>
                <Tag color={COLOR_MAPPER[representation.status]}>
                  {LABEL_MAPPER[representation.status]}
                </Tag>
              </div>
              <div>
                {representation.status === "PENDING" && (
                  <>
                    <IconButton
                      size={"lg"}
                      aria-label="Accept link"
                      icon={<FaCheck />}
                      onClick={() => {
                        handleUpdateStatus(
                          representation.represented,
                          representation.representative,
                          representation.link,
                          "ACCEPTED"
                        );
                      }}
                      className="ml-2"
                    />
                    <IconButton
                      size={"lg"}
                      aria-label="Decline link"
                      icon={<MdClose />}
                      onClick={() => {
                        handleUpdateStatus(
                          representation.represented,
                          representation.representative,
                          representation.link,
                          "DECLINED"
                        );
                      }}
                      className="ml-2"
                    />
                  </>
                )}
                {representation.status === "ACCEPTED" && (
                  <IconButton
                    size={"lg"}
                    aria-label="Cancel link"
                    icon={<FaTrash />}
                    onClick={() => {
                      handleDeleteLink(
                        representation.represented,
                        representation.representative,
                        representation.link
                      );
                    }}
                    className="ml-2"
                  />
                )}
              </div>
            </div>
          ))}
      </div>
    </>
  );
}
