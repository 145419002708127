import React, { useContext, useEffect } from "react";
import { StyleContext } from "./reducers/style.reducer";
import { HotkeyContext } from "./reducers/hotkeys.reducer";
import { useNavigate } from "react-router-dom";
import { SL } from "./components";

function Footer(): JSX.Element {
  const { state } = useContext(StyleContext);
  const hotkeyContext = useContext(HotkeyContext);
  const navigate = useNavigate();

  useEffect(() => {
    hotkeyContext.dispatch({
      type: "SET_HOTKEY",
      payload: {
        D: () => navigate("/document-validate"),
      },
    });
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return (
    <footer
      className="fixed bottom-0 mx-auto w-full text-center py-4 px-6"
      style={{ backgroundColor: state.backgroundColor, zIndex: 1000 }}
    >
      {window.innerWidth > 768 && (
        <div className="flex space-x-4 flex-wrap">
          <div
            className="cursor-pointer hover:text-yellow-600 text-yellow-500 font-bold"
            onClick={() => navigate("/document-validate")}
          >
            Consultar documento <SL>D</SL>
          </div>
          <div
            className="cursor-pointer hover:text-yellow-600 text-yellow-500 font-bold"
            onClick={() =>
              (window.location.href = process.env
                .REACT_APP_MAP as string)
            }
          >
            Consultar mapa
          </div>
          <div className="flex-grow"></div>
          <div className="text-sm">Versão mvp-0.0.0</div>
        </div>
      )}
    </footer>
  );
}

export default Footer;
