import { MapContainer, Polygon, Popup, useMap } from "react-leaflet";
import { useState, useEffect } from "react";
import { MdCenterFocusStrong } from "react-icons/md";
import { MapUpdater, convertCoordinates, findCenter } from "../../../../utils";
import { MapScrollManager } from "./../../components/MapScrollManager";
import { GeoSampaTileLayer } from "../../components/GeoSampaTileLayer";
import { Tab, TabList, Tabs } from "@chakra-ui/react";

interface Geometry {
  title: string;
  color: string;
  properties: {
    label: string;
    key: string;
    value?: string;
    includeOnPopUp: boolean;
  }[];
  coordinates: [number, number][];
}

export type FieldMapProps = {
  key: string;
  options: Pick<
    FieldOptions,
    | "height"
    | "label"
    | "layers"
    | "table"
    | "width"
    | "zoomControl"
    | "hideHeader"
  >;
  value?: {
    geometries: Geometry[];
  };
};

export const Map: React.FC<FieldMapProps> = ({ key, options, value }) => {
  const [geometries, setGeometries] = useState<Geometry[]>([]);
  const [bounds, setBounds] = useState<[number, number][]>([]);
  const [position, setPosition] = useState({
    lat: -23.55052,
    lng: -46.633308,
  });
  const [zoom, setZoom] = useState(10);
  const [layerType, setLayerType] = useState("politico"); // Estado para gerenciar o tipo de camada

  useEffect(() => {
    if (value !== undefined) {
      let geometries: Geometry[] = [];

      if (value.geometries && value.geometries.length > 0) {
        geometries = value.geometries.map((geom) => {
          return {
            ...geom,
            coordinates: convertCoordinates(geom.coordinates ?? []).map(
              (coord) => [coord.lat, coord.lng]
            ),
          };
        });
      } else if (options.layers && options.layers.length > 0) {
        geometries = options.layers
          .map((layer) => {
            return extractLayers(value, layer);
          })
          .flat();
      }

      if (geometries.length > 0) {
        const position = findCenter(
          geometries[0].coordinates.map((coord) => ({
            lat: coord[0],
            lng: coord[1],
          }))
        );

        setGeometries(geometries);
        setPosition(position);
        setZoom(20);
        setTimeout(() => setBounds(geometries[0].coordinates), 100);
      }
    }
  }, [value, options.layers]);
  return (
    <>
      {options.hideHeader !== true && (
        <div
          style={{
            paddingBottom: "12px",
          }}
        >
          <Tabs variant="soft-rounded" colorScheme="blue">
            <TabList>
              <Tab onClick={() => setLayerType("politico")}>Mapa</Tab>
              <Tab onClick={() => setLayerType("ortofoto")}>Ortofoto</Tab>
            </TabList>
          </Tabs>
        </div>
      )}

      <MapContainer
        key={key}
        style={{
          width: options.width ?? "100%",
          height: options.height ?? "400px",
          borderRadius: "1rem",
          background: "#ffffff",
        }}
        center={position}
        zoomControl={!(options.zoomControl === false)}
        attributionControl={false}
        maxZoom={20}
        zoom={zoom}
      >
        <GeoSampaTileLayer layerType={layerType} />
        <SetViewToBounds bounds={bounds ?? []}></SetViewToBounds>
        {geometries?.map((geom: Geometry, index: number) => (
          <Polygon
            key={`polygon-${index}`}
            positions={geom.coordinates ?? []}
            color={geom.color}
          >
            <Popup>
              <span className="font-bold">{geom.title}</span>
              <br />
              <br />
              {geom.properties
                .filter((prop) => prop.includeOnPopUp)
                .map((prop) => (
                  <span key={`popup-${prop.key}`}>
                    <span className="font-bold">{prop.label}:</span>{" "}
                    {prop.value}
                    <br></br>
                  </span>
                ))}
            </Popup>
          </Polygon>
        ))}
        <MapUpdater center={position} zoom={zoom} />
        <MapScrollManager />
      </MapContainer>
      {options.table !== false &&
        Array.isArray(geometries) &&
        geometries.length > 0 && (
          <div className="flex flex-col space-y-4 p-4 border rounded mt-6">
            {geometries?.map((geom) => (
              <div className="flex flex-col space-y-2">
                <div className="flex items-center space-x-2">
                  <h2 className="font-bold">{geom.title}</h2>{" "}
                  <MdCenterFocusStrong
                    title="Centralizar no mapa"
                    size={24}
                    onClick={() => {
                      setBounds([]);
                      setTimeout(() => setBounds(geom.coordinates), 0);
                    }}
                    className="cursor-pointer"
                  ></MdCenterFocusStrong>
                </div>
                <div className="flex flex-col space-y-2">
                  {geom.properties.map((prop) => (
                    <span key={`popup-${prop.key}`}>
                      <span className="font-bold">{prop.label}:</span>{" "}
                      {prop.value}
                    </span>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
    </>
  );
};

const SetViewToBounds = ({ bounds }: { bounds: [number, number][] }) => {
  const map = useMap();
  useEffect(() => {
    if (bounds.length > 0) {
      map.fitBounds(bounds, {
        padding: [15, 15],
        maxZoom: 20,
      });
    }
  }, [bounds, map]);

  return null;
};

function extractLayers(value: any, layer: LayerDescriptor): Geometry[] {
  try {
    const data =
      layer.key !== "geom_lote"
        ? value?.[layer.key]
        : [{ geometry: value?.[layer.key] }];

    const layers =
      data
        ?.filter((feature: any) => feature?.geometry?.type === "Polygon")
        ?.map((feature: any) => {
          const properties = layer.properties.map((prop) => {
            return {
              ...prop,
              value: feature.properties[prop.key],
            };
          });

          return {
            color: layer.color,
            coordinates: convertCoordinates(
              Array.isArray(feature.geometry.coordinates[0])
                ? feature.geometry.coordinates[0]
                : feature.geometry.coordinates
            ).map((coord) => [coord.lat, coord.lng]),
            properties,
            title: layer.title,
          };
        }) ?? [];

    const layersMultiPolygon =
      data
        ?.filter((feature: any) => feature?.geometry?.type === "MultiPolygon")
        ?.map((feature: any) => {
          const properties = layer.properties.map((prop) => {
            return {
              ...prop,
              value: feature.properties[prop.key],
            };
          });

          return {
            color: layer.color,
            coordinates: feature.geometry.coordinates[0]
              .map((coord: any[]) =>
                convertCoordinates(coord).map((coord) => [coord.lat, coord.lng])
              )
              .flat(),
            properties,
            title: layer.title,
          };
        }) ?? [];

    return [...layers, ...layersMultiPolygon];
  } catch (e) {
    return [];
  }
}
