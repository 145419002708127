import React, { FormEvent, useContext, useEffect, useState } from "react";
import { FaPlus, FaTrash } from "react-icons/fa";
import { Input, SL, Select } from "../../components";
import { IconButton, Spinner } from "@chakra-ui/react";
import EditableHeader from "../../components/EditableHeader";
import { HotkeyContext } from "../../reducers/hotkeys.reducer";
import {
  AddEnvironment,
  EnvironmentValueForm,
} from "./components/AddEnvironment";
import {
  listEnvironments,
  getEnvironment,
  updateEnvironment,
  createEnvironment,
  deleteEnvironment,
  findEnvironmentVersions,
  findEnvironmentVersion,
} from "./api";
import { VersionsMenu } from "./components/VersionsMenu";

export const Environments: React.FC = () => {
  const hotkeyContext = useContext(HotkeyContext);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [environments, setEnvironments] = useState<EnvironmentConfig[]>([]);
  const [selectedEnvironment, setSelectedEnvironment] =
    useState<EnvironmentConfig | null>(null);
  const [addingEnvironment, setAddingEnvironment] = useState(false);
  const [currentVersion, setCurrentVersion] = useState<{
    version: number;
    stage?: string;
  }>({ version: 0 });
  const [versions, setVersions] = useState<
    {
      id: string;
      version: number;
      commitMessage: string;
      timestamp: string;
      createdBy: string;
    }[]
  >([]);

  const fetchEnvironments = async () => {
    setEnvironments(await listEnvironments());
    setLoading(false);
  };

  const fetchEnvironmentsVersion = async (version: number) => {
    setLoading(true);

    if (selectedEnvironment !== null && selectedEnvironment.id !== undefined) {
      const environment = await findEnvironmentVersion(
        selectedEnvironment.id,
        version
      );

      setCurrentVersion({ version });
      setSelectedEnvironment((prev: any) => {
        return {
          ...environment,
          id: prev.id,
        };
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchEnvironments();
  }, []);

  useEffect(() => {
    hotkeyContext.dispatch({
      type: "SET_HOTKEY",
      payload: {
        N: (e) => !loading && handleAddEnvironmentForm(e),
        S: () => !loading && handleSaveEnvironment(),
      },
    });

    return () => {
      hotkeyContext.dispatch({
        type: "UNSET_HOTKEY",
        delete: ["N", "S"],
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const searchCallback = (search: string) => {
    setSearch(search);
  };

  const selectEnvironmentCallback = async (
    environmentConfig: EnvironmentConfig
  ) => {
    if (environmentConfig !== undefined && environmentConfig.id) {
      setLoading(true);
      const environment = await getEnvironment(environmentConfig.id);
      const versions = await findEnvironmentVersions(environmentConfig.id);

      setVersions(versions);
      setCurrentVersion({ version: versions.length });
      setSelectedEnvironment(environment);
      setAddingEnvironment(false);
      setLoading(false);
    }
  };

  const handleSetEnvironment = (key: string, value: any) => {
    if (selectedEnvironment !== null) {
      setSelectedEnvironment((prev: any) => ({
        ...prev,
        [key]: value,
      }));
    }
  };

  const handleAddEnvironmentForm = (e: FormEvent | undefined) => {
    e?.preventDefault();
    setSelectedEnvironment(null);
    setAddingEnvironment(true);
  };

  const handleSaveEnvironment = async () => {
    if (selectedEnvironment !== null && selectedEnvironment.id) {
      const commitMessage = await prompt(
        "Insira a mensagem de alteração da versão:"
      );

      if (!commitMessage?.trim()) {
        return;
      }

      setLoading(true);
      await updateEnvironment(selectedEnvironment.id, {
        title: selectedEnvironment.title,
        description: selectedEnvironment.description,
        key: selectedEnvironment.key,
        type: selectedEnvironment.type,
        value: selectedEnvironment.value,
        commitMessage,
      });
      await fetchEnvironments();
      const versions = await findEnvironmentVersions(selectedEnvironment.id);
      setVersions(versions);
      setCurrentVersion({ version: versions.length });
      setLoading(false);
    }
  };

  const handleAddEnvironment = async (newEnvironment: EnvironmentConfig) => {
    setLoading(true);

    const environment = {
      title: newEnvironment.title,
      description: newEnvironment.description,
      key: newEnvironment.key,
      type: newEnvironment.type,
      value: newEnvironment.value,
    };

    await createEnvironment(environment);
    await fetchEnvironments();
    setAddingEnvironment(false);
    setLoading(false);
  };

  const handleRemoveEnvironment = async () => {
    if (selectedEnvironment !== null && selectedEnvironment.id) {
      setLoading(true);

      const response = window.confirm(
        "Tem certeza que deseja remover esta variável?"
      );

      if (!response) {
        setLoading(false);
        return;
      }

      await deleteEnvironment(selectedEnvironment.id);
      await fetchEnvironments();
      setSelectedEnvironment(null);
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col space-y-6 mb-24 px-6">
      <h1 className="text-2xl md:text-3xl font-black mb-2 text-center">
        Variáveis de Ambiente
      </h1>
      <div className="text-end">
        <button
          className="bg-green-600 hover:bg-green-700 text-white px-4 py-2.5 px-8 text-lg rounded-lg disabled:opacity-80"
          onClick={handleAddEnvironmentForm}
          disabled={loading}
        >
          <FaPlus className="inline" /> Variável <SL bg="green.400">N</SL>
        </button>
      </div>
      <div className="flex flex-grow border-2 rounded-md">
        <div className="flex flex-col space-y-6 p-6 w-3/12 border-r-2">
          <div>
            <Input
              type="text"
              placeholder="Nome da variável"
              size="lg"
              className="flex-grow"
              value={search}
              onChange={(e) => searchCallback(e.target.value)}
            />
          </div>
          <div className="mx-0 px-0">
            {environments
              .filter(
                (environment) =>
                  search.trim().length === 0 ||
                  environment.title.includes(search)
              )
              .sort((a, b) => a.title.localeCompare(b.title))
              .map((environment) => (
                <div
                  key={"preset-item-" + environment.id}
                  className="px-4 py-2 hover:bg-gray-200"
                >
                  <button
                    onClick={() => selectEnvironmentCallback(environment)}
                    className="flex justify-between w-full items-center"
                  >
                    {environment.title}
                  </button>
                </div>
              ))}
          </div>
        </div>
        <div className="flex flex-col space-y-6 p-6 w-9/12">
          {loading && (
            <div className="pt-10 text-center">
              <Spinner size="xl" />
            </div>
          )}
          {!loading && selectedEnvironment === null && addingEnvironment && (
            <AddEnvironment
              onAddEnvironment={handleAddEnvironment}
            ></AddEnvironment>
          )}
          {!loading && selectedEnvironment !== null && (
            <>
              <div className="flex">
                <div className="flex-grow"></div>
                <div className="flex flex-col md:w-1/2 mx-auto">
                  <EditableHeader
                    value={selectedEnvironment.title}
                    onTextChange={(text) => handleSetEnvironment("title", text)}
                    className="text-xl md:text-3xl font-black mb-2 text-center"
                  />
                  <EditableHeader
                    value={selectedEnvironment.description}
                    onTextChange={(text) =>
                      handleSetEnvironment("description", text)
                    }
                    className="mb-2 text-center"
                  />
                </div>
                <div className="flex-grow"></div>
                <VersionsMenu
                  versions={versions}
                  defaultVersion={currentVersion}
                  callback={fetchEnvironmentsVersion}
                ></VersionsMenu>
              </div>
              <div className="flex flex-col w-2/3 mx-auto space-y-2">
                <label>Chave</label>
                <Input
                  type="text"
                  placeholder="Chave"
                  size="lg"
                  value={selectedEnvironment.key}
                  onChange={(e) => handleSetEnvironment("key", e.target.value)}
                />
                <label>Tipo</label>
                <Select
                  size="lg"
                  className="flex-grow"
                  value={selectedEnvironment?.type}
                  onChange={(e) => handleSetEnvironment("type", e.target.value)}
                >
                  <option value="string">Texto</option>
                  <option value="number">Número</option>
                  <option value="boolean">Booleano</option>
                  <option value="date">Data</option>
                  <option value="object">Objeto</option>
                  <option value="table">Tabela</option>
                </Select>
                <EnvironmentValueForm
                  form={selectedEnvironment}
                  setValue={(value) => handleSetEnvironment("value", value)}
                />
              </div>
              <div className="pt-6 text-center">
                <button
                  type="submit"
                  className="bg-yellow-600 hover:bg-yellow-700 text-white text-lg py-2.5 px-8 rounded-lg disabled:opacity-80"
                  onClick={handleSaveEnvironment}
                  disabled={loading}
                >
                  Salvar <SL bg="yellow.500">S</SL>
                </button>
              </div>
              <div className="text-end">
                <span className="font-bold">Remover variável</span>
                <IconButton
                  size={"lg"}
                  aria-label="Remove Environment"
                  icon={<FaTrash />}
                  onClick={handleRemoveEnvironment}
                  className="ml-2"
                  disabled={loading}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
