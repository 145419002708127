import { Editor } from "@monaco-editor/react";
import { StyleContext } from "../../../reducers";
import { useContext, useCallback } from "react";
import debounce from "lodash.debounce";

export type CodeEditorProps = {
  config: string;
  onChange: (value: string) => void;
  language?: "json" | "javascript";
  height?: string;
};

export const CodeEditor = ({
  config,
  onChange,
  language = "json",
  height,
}: CodeEditorProps): JSX.Element => {
  const styleContext = useContext(StyleContext);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnChange = useCallback(
    debounce((value) => {
      onChange(value);
    }, 600),
    [onChange]
  );

  return (
    <div style={{ width: "100%" }}>
      <Editor
        height={height ?? "100vh"}
        defaultLanguage={language}
        value={config}
        theme={
          styleContext.state.backgroundColor === "#000000"
            ? "vs-dark"
            : "vs-light"
        }
        onChange={(value) => debouncedOnChange(value ?? "")}
      />
    </div>
  );
};
