import React, { useState, useEffect, useCallback } from "react";
import { Textarea as TextAreaBase } from "@chakra-ui/react";
import debounce from "lodash.debounce";

export type FieldTextareaProps = {
  field: Field;
  key: string;
  onChange: (value: string) => void;
  options: Pick<
    FieldOptions,
    "label" | "placeholder" | "readOnly" | "enableApostille"
  >;
  value?: string | number;
  general: GeneralContext;
};

export const Textarea: React.FC<FieldTextareaProps> = ({
  field,
  key,
  onChange,
  options,
  value: propValue,
  general,
}) => {
  const [value, setValue] = useState(propValue);

  useEffect(() => {
    setValue(propValue);
  }, [propValue]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnChange = useCallback(
    debounce((value) => {
      onChange(value);
    }, 600),
    [onChange]
  );

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
    debouncedOnChange(e.target.value);
  };

  const isReadonly =
    options.readOnly === true ||
    options.readOnly === "true" ||
    (general?.$state === "apostille" && options.enableApostille !== true);

  return (
    <TextAreaBase
      key={key}
      placeholder={options?.placeholder}
      size="lg"
      onChange={handleChange}
      className="flex-grow"
      value={value}
      disabled={isReadonly}
    />
  );
};
