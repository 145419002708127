import { FormControl, FormLabel, Textarea } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { Input, SL } from "../../../components";
import { HotkeyContext } from "../../../reducers";
import { DatasetConfig } from "../Datasets";
import { Upload } from "../core/fields";

export type AddDatasetProps = {
  onAddDataset: (dataset: DatasetConfig) => void;
};

export const AddDataset: React.FC<AddDatasetProps> = ({
  onAddDataset,
}): JSX.Element => {
  const hotkeyContext = useContext(HotkeyContext);
  const [newDatasetForm, setNewDatasetForm] = useState<DatasetConfig>({
    title: "",
    description: "",
    link: [],
  });

  useEffect(() => {
    hotkeyContext.dispatch({
      type: "SET_HOTKEY",
      payload: {
        S: () => {
          if (
            newDatasetForm.title.length > 0 &&
            newDatasetForm.description.length > 0 &&
            newDatasetForm.link.length > 0
          ) {
            onAddDataset(newDatasetForm);
          }
        },
      },
    });

    return () => {
      hotkeyContext.dispatch({
        type: "UNSET_HOTKEY",
        delete: ["S"],
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newDatasetForm]);

  return (
    <div className="flex flex-col md:w-1/2 mx-auto space-y-4 ">
      <label className="text-xl md:text-2xl font-black mb-2 text-center">
        Adicionar dataset
      </label>
      <FormControl id="title">
        <FormLabel>Título</FormLabel>
        <Input
          autoFocus
          placeholder="Titulo do dataset"
          size="lg"
          value={newDatasetForm?.title}
          onChange={(e) =>
            setNewDatasetForm({
              ...newDatasetForm,
              title: e.target.value,
            })
          }
        />
      </FormControl>
      <FormControl id="description">
        <FormLabel>Descrição</FormLabel>
        <Textarea
          placeholder="Descrição do dataset"
          size="lg"
          value={newDatasetForm?.description}
          onChange={(e) =>
            setNewDatasetForm({
              ...newDatasetForm,
              description: e.target.value,
            })
          }
        />
      </FormControl>
      <FormControl id="group">
        <FormLabel>Arquivo</FormLabel>
        <Upload
          key="dataset-uploader"
          options={{
            multiple: false,
            dir: "datasets",
            supportedExtensions: ["csv"],
            maxSize: 2 * 1024 * 1024 * 1024, // 2 GB
          }}
          value={newDatasetForm.link}
          onChange={(value) =>
            setNewDatasetForm({
              ...newDatasetForm,
              link: value as string[],
            })
          }
        />
      </FormControl>
      <div className="py-6 text-center">
        <button
          className="bg-yellow-600 hover:bg-yellow-700 text-white text-lg py-2.5 px-8 rounded-lg disabled:opacity-80"
          disabled={
            !newDatasetForm.title ||
            !newDatasetForm.description ||
            !newDatasetForm.link
          }
          onClick={() => onAddDataset(newDatasetForm)}
        >
          Adicionar <SL bg="yellow.500">S</SL>
        </button>
      </div>
    </div>
  );
};
