import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "@chakra-ui/react";
import { SL } from "../../components";
import axios from "axios";
import { HotkeyContext } from "../../reducers/hotkeys.reducer";
import { FieldEditable } from "../form/core/FieldEditable";

export function SignUpEditor(): JSX.Element {
  const [config, setConfig] = useState<Field>();
  const [general, setGeneral] = useState<any>({});
  const [context, setContext] = useState<any>({});
  const [valid, setValid] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const hotkeyContext = useContext(HotkeyContext);

  const fetchConfig = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACK_END_API}/users/config`,
        {
          headers: {
            authorization: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.config?.type === "block") {
        setConfig(response.data.config);
        setGeneral({ $user: response.data.$user });
      } else {
        setConfig({
          type: "block",
          key: "user",
          block: [],
          options: {
            hideEditMenu: true,
          },
        });
        setGeneral({ $user: response.data.$user });
      }
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  const handleSave = async () => {
    setLoading(true);

    try {
      await axios.post(
        `${process.env.REACT_APP_BACK_END_API}/users/config`,
        { config },
        {
          headers: {
            authorization: `${localStorage.getItem("token")}`,
          },
        }
      );
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    hotkeyContext.dispatch({
      type: "SET_HOTKEY",
      payload: {
        S: () => !loading && handleSave(),
      },
    });

    return () => {
      hotkeyContext.dispatch({
        type: "UNSET_HOTKEY",
        delete: ["S"],
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, config]);

  return (
    <div className="pb-20">
      <div className="flex space-y-6 justify-center sm:px-0 md:px-6 mb-6">
        <div
          className="flex flex-col mx-6 md:mx-0 justify-center space-y-4"
          style={{ width: window.innerWidth <= 500 ? "100%" : "685px" }}
        >
          {loading && (
            <div className="pt-10 text-center">
              <Spinner size="xl" />
            </div>
          )}
        </div>
      </div>

      {!loading && config && (
        <div className="flex w-full space-y-6 justify-center sm:px-0 md:px-20 mt-4">
          <div
            className="flex flex-col mx-6 md:mx-0 justify-center space-y-4"
            style={{ width: window.innerWidth <= 500 ? "auto" : "685px" }}
          >
            <h1 className="text-2xl md:text-3xl font-black mb-6 text-center">
              Campos personalizados do usuário
            </h1>
            <FieldEditable
              context={context}
              validContext={valid}
              general={general}
              field={config}
              value={context}
              valid={valid}
              onChange={(value) => setContext(value)}
              onValidChange={(valid) => setValid(valid)}
              onConfigChange={(field: Field) => {
                setConfig(field);
              }}
              onRemove={() => {}}
            />
          </div>
        </div>
      )}

      <div className="justify-center text-center pt-16">
        <button
          className="bg-yellow-600 hover:bg-yellow-700 text-white text-lg px-16 py-2.5 rounded-xl disabled:opacity-80"
          disabled={loading}
          onClick={handleSave}
        >
          Salvar <SL bg="yellow.500">S</SL>
        </button>
      </div>
    </div>
  );
}
