import { FormControl, FormLabel, Textarea } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { Input, SL, Select } from "../../../components";
import { HotkeyContext } from "../../../reducers";
import { CodeEditor } from "./CodeEditor";
import DynamicTable from "./DynamicTable";

export type AddEnvironmentProps = {
  onAddEnvironment: (environment: EnvironmentConfig) => void;
};

export const AddEnvironment: React.FC<AddEnvironmentProps> = ({
  onAddEnvironment,
}): JSX.Element => {
  const hotkeyContext = useContext(HotkeyContext);
  const [newEnvironmentForm, setNewEnvironmentForm] =
    useState<EnvironmentConfig>({
      title: "",
      description: "",
      key: "",
      value: "",
      type: "string",
    });

  useEffect(() => {
    hotkeyContext.dispatch({
      type: "SET_HOTKEY",
      payload: {
        S: () => {
          if (
            newEnvironmentForm.title.length > 0 &&
            newEnvironmentForm.description.length > 0 &&
            newEnvironmentForm.type.length > 0 &&
            newEnvironmentForm.key.length > 0 &&
            newEnvironmentForm.value.length > 0
          ) {
            onAddEnvironment(newEnvironmentForm);
          }
        },
      },
    });

    return () => {
      hotkeyContext.dispatch({
        type: "UNSET_HOTKEY",
        delete: ["S"],
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newEnvironmentForm]);

  return (
    <div className="flex flex-col md:w-2/3 mx-auto space-y-4 ">
      <label className="text-xl md:text-2xl font-black mb-2 text-center">
        Adicionar Variável
      </label>
      <FormControl id="title">
        <FormLabel>Título</FormLabel>
        <Input
          autoFocus
          placeholder="Titulo da variável de ambiente"
          size="lg"
          value={newEnvironmentForm?.title}
          onChange={(e) =>
            setNewEnvironmentForm({
              ...newEnvironmentForm,
              title: e.target.value,
            })
          }
        />
      </FormControl>
      <FormControl id="description">
        <FormLabel>Descrição</FormLabel>
        <Textarea
          placeholder="Descrição da variável de ambiente"
          size="lg"
          value={newEnvironmentForm?.description}
          onChange={(e) =>
            setNewEnvironmentForm({
              ...newEnvironmentForm,
              description: e.target.value,
            })
          }
        />
      </FormControl>
      <FormControl id="key">
        <FormLabel>Chave</FormLabel>
        <Input
          placeholder="Chave da variável de ambiente"
          size="lg"
          value={newEnvironmentForm?.key}
          onChange={(e) =>
            setNewEnvironmentForm({
              ...newEnvironmentForm,
              key: e.target.value,
            })
          }
        />
      </FormControl>
      <FormControl id="type">
        <FormLabel>Tipo</FormLabel>
        <Select
          placeholder="Tipo da variável de ambiente"
          size="lg"
          value={newEnvironmentForm?.type}
          onChange={(e) =>
            setNewEnvironmentForm({
              ...newEnvironmentForm,
              type: e.target.value as EnvironmentConfig["type"],
            })
          }
        >
          <option value="string">Texto</option>
          <option value="number">Número</option>
          <option value="boolean">Booleano</option>
          <option value="date">Data</option>
          <option value="object">Objeto</option>
          <option value="table">Tabela</option>
        </Select>
      </FormControl>
      <EnvironmentValueForm
        form={newEnvironmentForm}
        setValue={(value: any) => {
          setNewEnvironmentForm({
            ...newEnvironmentForm,
            value,
          });
        }}
      />
      <div className="py-6 text-center">
        <button
          className="bg-yellow-600 hover:bg-yellow-700 text-white text-lg py-2.5 px-8 rounded-lg disabled:opacity-80"
          disabled={
            !newEnvironmentForm.title.length ||
            !newEnvironmentForm.description.length ||
            !newEnvironmentForm.key.length ||
            !newEnvironmentForm.value.length
          }
          onClick={() => onAddEnvironment(newEnvironmentForm)}
        >
          Adicionar <SL bg="yellow.500">S</SL>
        </button>
      </div>
    </div>
  );
};

export function EnvironmentValueForm({
  form,
  setValue,
}: {
  form: EnvironmentConfig;
  setValue: (value: any) => void;
}): JSX.Element {
  return (
    <FormControl id="value">
      <FormLabel>Valor</FormLabel>
      {form.type === "string" && (
        <Textarea
          placeholder="Valor da variável de ambiente"
          size="lg"
          value={form?.value}
          onChange={(e) => setValue(e.target.value)}
        />
      )}
      {form.type === "number" && (
        <Input
          placeholder="Valor da variável de "
          size="lg"
          value={form?.value}
          onChange={(e) => {
            handleNumberChange(e, setValue);
          }}
        />
      )}
      {form.type === "boolean" && (
        <Select
          size="lg"
          value={form?.value}
          onChange={(e) => setValue(e.target.value)}
        >
          <option value="true">Verdadeiro</option>
          <option value="false">Falso</option>
        </Select>
      )}
      {form.type === "date" && (
        <Input
          type="date"
          size="lg"
          value={form?.value}
          onChange={(e) => setValue(e.target.value)}
        />
      )}
      {form.type === "object" && (
        <CodeEditor
          config={
            typeof form?.value !== "string"
              ? JSON.stringify(form?.value)
              : form?.value ?? "{}"
          }
          onChange={(value) => {
            setValue(value);
          }}
          language="json"
          height="50vh"
        />
      )}
      {form.type === "table" && (
        <DynamicTable value={form?.value} onChange={setValue} />
      )}
    </FormControl>
  );
}

function handleNumberChange(
  e: React.ChangeEvent<HTMLInputElement>,
  setValue: (value: string) => void
) {
  const inputValue = e.target.value;
  const cursorPosition = e.target.selectionStart || 0;
  let sanitizedValue = inputValue.toString();

  if (inputValue[cursorPosition - 1] === ".") {
    const periodCount = (inputValue.match(/\./g) || []).length;
    if (periodCount > 1) {
      // Remove all periods except the last one
      sanitizedValue = inputValue.split("").reduce((acc, char, index) => {
        if (char === "." && index !== cursorPosition - 1) {
          return acc;
        }
        return acc + char;
      }, "");
    }

    requestAnimationFrame(() => {
      if (e.target.setSelectionRange) {
        e.target.setSelectionRange(cursorPosition, cursorPosition);
      }
    });
  } else {
    sanitizedValue = inputValue.replace(/[^\d.]/g, "");
  }

  setValue(sanitizedValue);
}
