import React from "react";
import { FaCalculator } from "react-icons/fa";
import { MdIntegrationInstructions } from "react-icons/md";
import { extractContext } from "../utils/parsers";
import { evalLabel } from "../utils/expressions";
import { HelpTooltipClickable } from "../../../../components";

export const RenderFieldModelCalculation = ({
  parent,
  field,
  context,
}: {
  parent: Field;
  field: Field;
  context: any;
}) => {
  const integrationsKeys =
    (parent.block ?? parent.preset)
      ?.filter((f) => f.type === "integration")
      .map((f) => `context.${f.key}`) ?? [];

  const modelUsesIntegration = integrationsKeys.some((key) =>
    field.expressions?.model?.includes(key)
  );
  const contexts = extractContext(field.expressions?.model ?? "");
  const referenceToOtherField = Array.from(
    new Set(
      contexts
        .filter((context) => !context.includes("context.$"))
        .map((context) => context.replace("context.", ""))
    )
  ).map((key) => {
    const field = parent.block?.find((f) => f.key === key.split(".")[0]);
    return field?.options?.label ?? key;
  });
  const hasReferenceToOtherField = referenceToOtherField.length > 0;
  const dynamicLabel = field.options.modeExpressionDescription
    ? evalLabel(field.options.modeExpressionDescription, context)
    : undefined;

  return (
    <>
      {hasReferenceToOtherField && !modelUsesIntegration && (
        <HelpTooltipClickable
          tooltip={
            dynamicLabel ??
            `Esse campo possui o valor dele definido de forma automatizada<br><br>Campos utilizados: [${referenceToOtherField}]`
          }
          icon={FaCalculator}
          size="20px"
        />
      )}
      {modelUsesIntegration && (
        <HelpTooltipClickable
          tooltip={
            dynamicLabel ??
            `Esse campo possui o valor dele definido por uma integração<br><br>Campos utilizados: [${referenceToOtherField}]`
          }
          icon={MdIntegrationInstructions}
        />
      )}
    </>
  );
};
