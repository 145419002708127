import { Select as SelectBase } from "../../../../components";

export type FieldSelectProps = {
  field: Field;
  key: string;
  onChange: (value: string) => void;
  options: Pick<
    FieldOptions,
    "items" | "label" | "placeholder" | "readOnly" | "enableApostille"
  >;
  value?: string | number;
  general: GeneralContext;
};

export const Select: React.FC<FieldSelectProps> = ({
  field,
  key,
  onChange,
  options,
  value,
  general,
}) => {
  const isReadonly =
    options.readOnly === true ||
    options.readOnly === "true" ||
    (general?.$state === "apostille" && options.enableApostille !== true);

  return (
    <SelectBase
      placeholder={options?.placeholder}
      size="lg"
      onChange={(e) => onChange(e.target.value)}
      className={`flex-grow ${isReadonly ? "cursor-not-allowed" : ""}`}
      value={value}
      disabled={isReadonly}
    >
      {options?.items?.map((item: { key: string; value: string }) => (
        <option key={key + "#" + item.key} value={item.value}>
          {item.key}
        </option>
      ))}
    </SelectBase>
  );
};
