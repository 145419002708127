import { IconButton, Switch } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaMinus, FaPlus, FaTrash } from "react-icons/fa";
import EditableHeader from "../../../components/EditableHeader";
import { Map } from "../core/fields";
import { FieldMenuEditor } from "./FieldMenuEditor";
import { evalFieldExpression } from "../core/utils/expressions";

export type PlateEditorProps = {
  value?: any;
  field: Field;
  general: GeneralContext;
  context: any;
  onChange: (value: any) => void;
};

export const PlateEditor = ({
  value,
  field,
  general,
  context,
  onChange,
}: PlateEditorProps): JSX.Element => {
  const [showPreview, setShowPreview] = useState(false);
  const [zoom, setZoom] = useState(25);

  const [form, setForm] = useState<DocumentLayout>(
    value !== undefined && value !== null && Object.keys(value).length !== 0
      ? value
      : {
          logo: "https://www.prefeitura.sp.gov.br/cidade/secretarias/upload/direitos_humanos/PARCERIAS/LOGOS_MANUAL/LOGOS_COLABORACOES_EMENDAS/LOGO%20PREFEITURA/LOGOTIPO_PREFEITURA_CENTRALIZADO_FUNDO_CLARO.png",
          headerTitle: "Prefeitura de São Paulo",
          headerDescription:
            "Secretaria de Governo Municipal, Viaduto do Chá, n° 15 - Centro Histórico de São Paulo Telefone +55 (11) 3113-8000",
          qrcode:
            "https://api.qrserver.com/v1/create-qr-code/?size=1000x1000&data=http://localhost:3000/subjects/b8edf4ff-09f7-41b2-92c4-b351eef07d74",
          title: field.options.title,
          description: "",
          blocks: [],
        }
  );
  const [htmlContent, setHtmlContent] = useState("``");

  useEffect(() => {
    onChange(form);
    setHtmlContent(generateStaticHTMLContent(form, context, general));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, context, general]);

  const handleRemoveBlock = (index: number) => {
    form.blocks?.splice(index, 1);
    setForm({
      ...form,
      blocks: form.blocks,
    });
  };

  const handleAddBlock = () => {
    setForm({
      ...form,
      blocks: [
        ...form.blocks,
        {
          label: "Titulo do bloco",
          rows: [],
        },
      ],
    });
  };

  const handleSetDocumentAttr = (
    key: "headerTitle" | "headerDescription" | "title" | "description" | "logo",
    value: string
  ) => {
    setForm({
      ...form,
      [key]: value,
    });
  };

  const handleSetBlockAttr = (index: number, key: "label", value: string) => {
    form.blocks[index][key] = value;
    setForm({
      ...form,
      blocks: form.blocks,
    });
  };

  const handleAddRow = (index: number) => {
    form.blocks[index].rows.push([
      { label: "Insira a etiqueta", value: "Insira o valor" },
    ]);
    setForm({
      ...form,
      blocks: form.blocks,
    });
  };

  const handleAddCol = (blockIndex: number, rowIndex: number) => {
    form.blocks[blockIndex].rows[rowIndex].push({
      label: "Insira a etiqueta",
      value: "Insira o valor",
    });
    setForm({
      ...form,
      blocks: form.blocks,
    });
  };

  const handleRemoveCol = (
    blockIndex: number,
    rowIndex: number,
    colIndex: number
  ) => {
    form.blocks[blockIndex].rows[rowIndex].splice(colIndex, 1);

    if (form.blocks[blockIndex].rows[rowIndex].length === 0) {
      form.blocks[blockIndex].rows.splice(rowIndex, 1);
    }

    setForm({
      ...form,
      blocks: form.blocks,
    });
  };

  const handleSetColAttr = (
    blockIndex: number,
    rowIndex: number,
    colIndex: number,
    key: "label" | "value",
    value: string
  ) => {
    form.blocks[blockIndex].rows[rowIndex][colIndex][key] = value;
    setForm({
      ...form,
      blocks: form.blocks,
    });
  };

  const [draggingRowIndex, setDraggingRowIndex] = useState<number | null>(null);

  const handleDragRowStart = (
    e: React.DragEvent<HTMLDivElement>,
    index: number
  ) => {
    setDraggingRowIndex(index);
    e.dataTransfer.effectAllowed = "move";
  };

  const handleDragRowOver = (
    e: React.DragEvent<HTMLDivElement>,
    index: number,
    rowIndex: number
  ) => {
    e.preventDefault();
    if (draggingRowIndex === null) return;

    let newIndex = draggingRowIndex < index ? index + 1 : index;

    newIndex =
      newIndex > form.blocks[rowIndex].rows.length
        ? form.blocks[rowIndex].rows.length
        : newIndex;

    if (draggingRowIndex !== newIndex) {
      const itemToMove = form.blocks[rowIndex].rows.splice(
        draggingRowIndex,
        1
      )[0];

      if (draggingRowIndex < newIndex) {
        newIndex--;
      }

      form.blocks[rowIndex].rows.splice(newIndex, 0, itemToMove);

      setForm({
        ...form,
        blocks: form.blocks,
      });
      setDraggingRowIndex(newIndex);
    }
  };

  const handleDropRow = (e: React.DragEvent<HTMLDivElement>) => {
    setDraggingRowIndex(null);
  };

  const pdfPageStyle: React.CSSProperties = {
    minWidth: "2840px",
    minHeight: "4040px",
    boxSizing: "border-box",
    backgroundColor: "white",
    transform: `scale(${Math.max(10, zoom) / 100})`,
    transformOrigin: "top left",
  };

  const wrapperStyle: React.CSSProperties = {
    width: "710px",
    height: "1010px",
    overflow: "auto",
    boxSizing: "border-box",
    background: "gray",
  };

  return (
    <div className="flex flex-col pb-10">
      <div className="flex items-center justify-center space-x-6 mb-10">
        <div>
          <div className="mb-4">Mostrar prévia</div>
          <Switch
            isChecked={showPreview}
            onChange={() =>
              showPreview ? setShowPreview(false) : setShowPreview(true)
            }
            size={"lg"}
            colorScheme="red"
          />
        </div>
        <IconButton
          aria-label="Zoom in"
          icon={<FaPlus />}
          onClick={() => setZoom(Math.min(120, zoom + 5))}
        />
        <IconButton
          aria-label="Zoom out"
          icon={<FaMinus />}
          onClick={() => setZoom(Math.max(10, zoom - 5))}
        />
      </div>

      <div className="flex justify-center w-full">
        {!showPreview && (
          <div className="text-black" style={wrapperStyle}>
            <div style={pdfPageStyle}>
              <header className="flex flex-col py-40 px-40">
                <div className="flex space-x-40">
                  <img src={form.logo} width="400px" alt="city logo" />
                  <div className="flex flex-col space-y-6">
                    <EditableHeader
                      value={form.headerTitle}
                      onTextChange={(text) =>
                        handleSetDocumentAttr("headerTitle", text)
                      }
                      className="font-black text-8xl"
                    />
                    <EditableHeader
                      value={form.headerDescription}
                      onTextChange={(text) =>
                        handleSetDocumentAttr("headerDescription", text)
                      }
                      className="text-6xl"
                    />
                  </div>
                </div>
              </header>

              <main className="flex flex-col space-y-10 px-40">
                <div className="flex items-center space-x-40">
                  <img
                    src={form.qrcode}
                    style={{ minWidth: "755px" }}
                    alt="qrcode to document"
                  />
                  <div className="flex flex-col space-y-20 font-bold text-4xl w-full">
                    <EditableHeader
                      value={form.title}
                      onTextChange={(text) =>
                        handleSetDocumentAttr("title", text)
                      }
                      className="justify-center text-center"
                      style={{ fontSize: "9rem", lineHeight: "1.1" }}
                    />
                    <EditableHeader
                      value={form.description}
                      onTextChange={(text) =>
                        handleSetDocumentAttr("description", text)
                      }
                      className="justify-center text-center text-8xl"
                    />
                  </div>
                </div>

                <div className="flex-col space-y-6 font-bold text-5xl">
                  {form &&
                    form.blocks.map((block, index) => (
                      <>
                        <div className="flex items-center space-x-4 font-bold mt-16">
                          <div className="font-normal">
                            <FieldMenuEditor
                              field={{
                                type: "documentMap",
                                key: `block-${index}`,
                                options: {
                                  layers: block.map?.layers,
                                  source: block.map?.source,
                                },
                                expressions: {},
                              }}
                              onChange={(config) => {
                                form.blocks[index].map = config.options;
                                setForm({
                                  ...form,
                                  blocks: form.blocks,
                                });
                              }}
                              options={{ iconSize: 42 }}
                            />
                          </div>

                          <EditableHeader
                            value={form.blocks[index].label}
                            onTextChange={(text) =>
                              handleSetBlockAttr(index, "label", text)
                            }
                          />
                          <div className="cursor-pointer p-4 bg-gray-300 rounded-xl">
                            <FaTrash
                              onClick={() => handleRemoveBlock(index)}
                              size={42}
                            />
                          </div>
                        </div>
                        {block.map?.source && (
                          <div className="flex justify-center">
                            <Map
                              key={"map"}
                              options={{
                                label: "",
                                layers: block.map.layers,
                                width: block.map.width ?? "750px",
                                height: block.map.height ?? "750px",
                                table: false,
                                zoomControl: false,
                                hideHeader: true,
                              }}
                              value={evalFieldExpression(
                                block.map.source,
                                context,
                                general,
                                {}
                              )}
                            />
                          </div>
                        )}
                        <div className="flex flex-col space-y-12">
                          {block?.rows.map((row, rowIndex) => (
                            <div
                              key={"row-" + rowIndex}
                              draggable
                              onDragStart={(e) =>
                                handleDragRowStart(e, rowIndex)
                              }
                              onDragOver={(e) =>
                                handleDragRowOver(e, rowIndex, index)
                              }
                              onDrop={handleDropRow}
                              className="flex items-center space-x-4"
                            >
                              <div className="flex space-x-10 w-full">
                                {row.map((col, colIndex) => {
                                  return (
                                    <div className="flex w-full">
                                      <div className="flex flex-col space-y-6">
                                        <div className="flex items-center space-x-4 text-gray-500">
                                          <EditableHeader
                                            value={col.label}
                                            onTextChange={(text) =>
                                              handleSetColAttr(
                                                index,
                                                rowIndex,
                                                colIndex,
                                                "label",
                                                text
                                              )
                                            }
                                          />
                                          <div className="cursor-pointer text-black p-4 bg-gray-300 rounded-xl">
                                            <FaTrash
                                              onClick={() =>
                                                handleRemoveCol(
                                                  index,
                                                  rowIndex,
                                                  colIndex
                                                )
                                              }
                                              size={42}
                                            />
                                          </div>
                                        </div>
                                        <EditableHeader
                                          value={col.value}
                                          onTextChange={(text) =>
                                            handleSetColAttr(
                                              index,
                                              rowIndex,
                                              colIndex,
                                              "value",
                                              text
                                            )
                                          }
                                        />
                                      </div>
                                      {colIndex + 1 === row.length && (
                                        <div className="space-x-2 ml-auto mb-auto">
                                          <div className="cursor-pointer p-4 bg-gray-300 rounded-xl">
                                            <FaPlus
                                              onClick={() =>
                                                handleAddCol(index, rowIndex)
                                              }
                                              size={42}
                                            />
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="flex items-center space-x-4 justify-center pt-4">
                          <div className="cursor-pointer p-4 bg-gray-300 rounded-xl">
                            <FaPlus
                              onClick={() => handleAddRow(index)}
                              size={42}
                            />
                          </div>
                        </div>
                      </>
                    ))}
                </div>

                <div className="flex items-center space-x-4 justify-center mt-6">
                  <div className="cursor-pointer p-4 bg-gray-300 rounded-xl">
                    <FaPlus onClick={() => handleAddBlock()} size={42} />
                  </div>
                </div>
              </main>
            </div>
          </div>
        )}

        {showPreview && (
          <div className="text-black" style={wrapperStyle}>
            <div
              id="pdfContent"
              style={pdfPageStyle}
              dangerouslySetInnerHTML={{ __html: htmlContent }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

function generateStaticHTMLContent(
  form: DocumentLayout,
  context: any,
  general: any
) {
  return `
    <header class="flex flex-col py-40 px-40">
      <div class="flex space-x-40">
        <img src="${form.logo}" width="400px" alt="City Logo" />
        <div clas="flex flex-col">
          <div class="font-black text-8xl mb-6">${form.headerTitle}</div>
          <div class="text-6xl">${form.headerDescription}</div>
        </div>
      </div>
    </header>
    
    <main class="flex flex-col space-y-10 px-40">
      <div class="flex items-center space-x-40">
        <img src="${form.qrcode}" style="min-width: 755px;" alt="QR Code" />
        <div class="flex flex-col space-y-20 font-bold text-4xl w-full">
          <div class="justify-center text-center" style="font-size: 9rem; line-height: 1.1">${
            form.title
          }</div>
          <div class="justify-center text-center text-8xl">${
            form.description
          }</div>
        </div>
      </div>
      
      <div class="flex-col space-y-6 font-bold text-5xl">
        ${form.blocks
          .map(
            (block) => `
          <div class="flex-col space-y-12">
            <h3 class="flex items-center space-x-4 font-bold mt-16">${
              block.label
            }</h3>
            ${
              !!block.map?.source
                ? `<div class="flex justify-center">
                    <div class="border rounded-lg bg-gray-200" style="min-width: 750px; min-height: 750px" />
                  </div>`
                : ""
            }
            ${block.rows
              .map(
                (row) => `
              <div class="flex">
                ${row
                  .map(
                    (col) => `
                  <div class="flex flex-col space-y-6 w-full">
                    <span class="flex items-center space-x-4 text-gray-600">${
                      col.label
                    }</span>
                    <span>${evalFieldExpression(
                      `\`${col.value}\``,
                      context,
                      general,
                      {}
                    )}</span>
                  </div>
                `
                  )
                  .join("")}
              </div>
            `
              )
              .join("")}
          </div>
        `
          )
          .join("")}
      </div>
    </main>
  `;
}
