import axios from "axios";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import logo from "../../assets/logo.png";
import { parseAndExecuteTaxExpr } from "../../utils";
import { loadFunctions, parseFunctions } from "./core/utils/parsers";

export const TaxPrint = (): JSX.Element => {
  const { id } = useParams();
  const [protocol, setProtocol] = useState<Protocol>();
  const [context, setContext] = useState<any>();
  const [general, setGeneral] = useState<GeneralContext>();
  const [isBarcodeLoaded, setIsBarcodeLoaded] = useState(false);
  const [isLogoLoaded, setIsLogoLoaded] = useState(false);
  const [tax, setTax] = useState<
    {
      pricipal: number;
      multa: number;
      correcao: number;
      mora: number;
      desconto: number;
      descricao: string;
      reclassificacao: number;
    }[]
  >([]);
  const [taxTotal, setTaxTotal] = useState<{
    principal: number;
    multa: number;
    correcao: number;
    mora: number;
    desconto: number;
  }>({
    principal: 0,
    multa: 0,
    correcao: 0,
    mora: 0,
    desconto: 0,
  });

  const fetchSubject = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACK_END_API}/protocols/api/${id}`,
        {
          headers: {
            apiKey: localStorage.getItem("apiKey"),
          },
        }
      );

      delete response.data.id;

      setProtocol(response.data);
      setContext(response.data.protocol);
      setGeneral({
        $user: response.data.$user,
        $environment: response.data.$environment,
        $library: {
          ...parseFunctions(response.data?.$function ?? {}),
          $: loadFunctions(response.data?.library ?? ""),
        },
        $state: "create",
      });
    } catch (e) {}
  };

  useEffect(() => {
    fetchSubject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (protocol && context && general) {
      const taxes = parseAndExecuteTaxExpr(protocol.tax, context, general);
      setTax(taxes);
      setTaxTotal(
        taxes.reduce(
          (acc: any, tax: any) => {
            acc.principal += tax.principal;
            acc.multa += tax.multa;
            acc.correcao += tax.correcao;
            acc.mora += tax.mora;
            acc.desconto += tax.desconto;
            return acc;
          },
          {
            principal: 0,
            multa: 0,
            correcao: 0,
            mora: 0,
            desconto: 0,
          }
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [protocol]);

  const pdfPageStyle: React.CSSProperties = {
    minWidth: "794px",
    minHeight: "1123px",
    overflow: "auto",
    boxSizing: "border-box",
    backgroundColor: "white",
  };

  const isAllLoaded = !!context && isBarcodeLoaded && isLogoLoaded;

  if (context) {
    return (
      <div id={isAllLoaded ? "waitNoMore" : undefined} style={pdfPageStyle}>
        <div className="flex-col space-y-10 p-10">
          <div className="flex space-x-20">
            <div>
              <img
                src={logo}
                width="80px"
                alt="city logo"
                onLoad={() => setIsLogoLoaded(true)}
              />
            </div>
            <div>
              <div className="font-black text-xl">
                PREFEITURA DA CIDADE SÃO PAULO
              </div>
              <div className="text-xl">SECRETARIA MUNICIPAL DA FAZENDA</div>
              <div className="text-xl">
                DAMSP - Documento de Arrecadação do Município de São Paulo
              </div>
            </div>
          </div>

          <div className="border rounded-md">
            <div>
              <div className="border px-4 py-2.5">
                <div>Nome/Razão Social</div>
                <div>[XXXXX XXXXXX XXXXX]</div>
              </div>
              <div className="border px-4 py-2.5">
                <div>Vencimento</div>
                <div>[XX/XX/XXX]</div>
              </div>
            </div>
            <div>
              <div className="border px-4 py-2.5">
                <div>CPF/CNPJ</div>
                <div>[XX.XXX.XXX/XXXX-XX]</div>
              </div>
              <div className="border px-4 py-2.5">
                <div>Valor(R$)</div>
                <div>
                  {taxTotal.principal +
                    taxTotal.multa -
                    taxTotal.desconto -
                    taxTotal.correcao -
                    taxTotal.mora}
                </div>
              </div>
            </div>
            <div>
              <div className="border px-4 py-2.5">
                <div>Taxas</div>
                {tax.map((taxa) => {
                  return (
                    <div>
                      {taxa.reclassificacao} - {taxa.descricao}
                    </div>
                  );
                })}
              </div>
              <div className="flex">
                <div className="border w-full px-4 py-2.5">
                  <div>Multa (R$)</div>
                  <div>{taxTotal.multa}</div>
                </div>
                <div className="border w-full px-4 py-2.5">
                  <div>Correção (R$)</div>
                  <div>{taxTotal.correcao}</div>
                </div>
                <div className="border w-full px-4 py-2.5">
                  <div>Mora (R$)</div>
                  <div>{taxTotal.mora}</div>
                </div>
                <div className="border w-full px-4 py-2.5">
                  <div>Descontos (R$)</div>
                  <div>{taxTotal.desconto}</div>
                </div>
                <div className="border w-full px-4 py-2.5">
                  <div>Total (R$)</div>
                  <div>
                    {taxTotal.principal +
                      taxTotal.multa -
                      taxTotal.desconto -
                      taxTotal.correcao -
                      taxTotal.mora}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer className="flex justify-center -mt-10">
          <img
            className="h-60"
            src="https://img.freepik.com/psd-gratuitas/ilustracao-de-codigo-de-barras-isolada_23-2150584086.jpg?w=1800&t=st=1718301998~exp=1718302598~hmac=21b6137e17334567acc69b870736901c277309ad1455ddbd26fec6b574843e1d"
            alt="barcode"
            onLoad={() => setIsBarcodeLoaded(true)}
          />
        </footer>
      </div>
    );
  } else {
    return <></>;
  }
};
