import React from "react";
import { Field } from "./Field";
import { Step } from "./fields";
import { FieldBlockProps } from "./utils/types";

export const FieldBlock: React.FC<FieldBlockProps> = ({
  parent,
  field,
  general,
  layout = "block",
  value,
  valid,
  onChange,
  onValidChange,
}): JSX.Element => {
  return (
    <>
      {layout === "step" && (
        <Step
          field={field}
          general={general}
          value={value}
          valid={valid}
          onChange={onChange}
          onValidChange={onValidChange}
        />
      )}
      {layout === "block" && (
        <div
          className={`${
            parent.options.card && parent.options.open === false ? "hidden" : ""
          }`}
        >
          {field.map((f) => (
            <div className="mb-4">
              <Field
                parent={parent}
                context={value}
                validContext={valid}
                general={general}
                field={f}
                value={value?.[f.options.key ?? f.key]}
                valid={valid?.[f.options.key ?? f.key]}
                onChange={(value) => {
                  onChange(f.options.key ?? f.key, value);
                }}
                onValidChange={(valid) => {
                  onValidChange(f.options.key ?? f.key, valid);
                }}
              ></Field>
            </div>
          ))}
        </div>
      )}
    </>
  );
};
