import {
  Stepper,
  Box,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepTitle,
  useSteps,
  StepStatus,
  Step,
  IconButton,
  StepSeparator,
} from "@chakra-ui/react";
import { useState } from "react";
import { FaPlus, FaTrash } from "react-icons/fa";
import EditableHeader from "../../../../components/EditableHeader";
import { FieldEditable } from "../FieldEditable";
import { HelpTooltipClickable } from "../../../../components";

export type FieldStepEditableProps = {
  field: Field[];
  general: GeneralContext;
  value: any;
  valid: any;
  onChange: (key: string, value: any) => void;
  onValidChange: (key: string, valid: boolean) => void;
  onConfigChange: (field: Field[]) => void;
};

export const StepEditable: React.FC<FieldStepEditableProps> = ({
  field,
  general,
  value,
  valid,
  onChange,
  onValidChange,
  onConfigChange,
}) => {
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: field?.length ?? 0,
  });
  const [draggingIndex, setDraggingIndex] = useState<number | null>(null);
  // This state is used to force the rerender of the FieldEditable component and
  // avoid use the context value of the other step when loading the FieldEditable
  const [rerender, setRerender] = useState<boolean>(true);

  const handleDragStart = (
    e: React.DragEvent<HTMLDivElement>,
    index: number
  ) => {
    setDraggingIndex(index);
    e.dataTransfer.effectAllowed = "move";
  };

  const handleDragOver = (
    e: React.DragEvent<HTMLDivElement>,
    index: number
  ) => {
    e.preventDefault();
    if (draggingIndex === null) return;

    let newIndex = draggingIndex < index ? index + 1 : index;

    newIndex = newIndex > field.length ? field.length : newIndex;

    if (draggingIndex !== newIndex) {
      const itemToMove = field.splice(draggingIndex, 1)[0];

      if (draggingIndex < newIndex) {
        newIndex--;
      }

      field.splice(newIndex, 0, itemToMove);

      onConfigChange(field ?? []);
      setDraggingIndex(newIndex);
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    setDraggingIndex(null);
  };

  const handleAddStep = () => {
    onConfigChange([
      ...(field ?? []),
      {
        key: `key-${(field?.length ?? 0) + 1}`,
        type: "block",
        options: {
          layout: "block",
          title: `Etapa ${(field?.length ?? 0) + 1}`,
        },
        block: [],
      },
    ]);
  };

  const handleRemoveStep = (index: number) => {
    onConfigChange(field?.filter((_, i) => i !== index) ?? []);
  };

  return (
    <>
      <div
        className=" w-full pb-6"
        style={{
          overflowX: "auto",
          whiteSpace: "nowrap",
        }}
      >
        <Stepper size="lg" colorScheme="red" index={activeStep}>
          {field?.map((f, index) => (
            <Step
              className="cursor-pointer"
              key={"step-" + f.key + "-" + index}
              draggable
              onDragStart={(e) => handleDragStart(e, index)}
              onDragOver={(e) => handleDragOver(e, index)}
              onDrop={handleDrop}
            >
              <StepIndicator
                onClick={() => {
                  setActiveStep(index);
                  setRerender(false);
                  setTimeout(() => {
                    setRerender(true);
                  }, 0);
                }}
              >
                <StepStatus
                  complete={<StepIcon />}
                  incomplete={<StepNumber />}
                  active={<StepNumber />}
                />
              </StepIndicator>
              <Box flexShrink="0">
                <StepTitle className="flex items-center space-x-2">
                  <EditableHeader
                    value={f.options.title}
                    onTextChange={(text: string) => {
                      field[index].options.title = text;
                      onConfigChange([...field]);
                    }}
                  ></EditableHeader>
                  {f.options.tooltip && (
                    <HelpTooltipClickable tooltip={f.options.tooltip} />
                  )}
                </StepTitle>
              </Box>
              <IconButton
                aria-label="Remove Step"
                icon={<FaTrash />}
                onClick={() => {
                  if (activeStep === index) {
                    if (index > 0) {
                      setActiveStep(index - 1);
                    }
                  }
                  handleRemoveStep(index);
                }}
                className="ml-2"
              />
              <StepSeparator />
            </Step>
          ))}
          <IconButton
            aria-label="Add Step"
            icon={<FaPlus />}
            onClick={handleAddStep}
          />
        </Stepper>
      </div>

      {field.length > 0 && rerender && (
        <FieldEditable
          parent={field[activeStep]}
          context={value}
          validContext={valid}
          general={general}
          field={field[activeStep]}
          value={value?.[field[activeStep].key]}
          valid={valid?.[field[activeStep].key]}
          onChange={(v) => {
            onChange(field[activeStep].key, v);
          }}
          onValidChange={(valid: any) => {
            onValidChange(field[activeStep].key, valid);
          }}
          onConfigChange={(config: Field) => {
            field[activeStep] = config;
            onConfigChange(field);
          }}
          onRemove={() => {}}
        />
      )}
    </>
  );
};
