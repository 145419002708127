import React, { useContext, useEffect, useState } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Skeleton,
  Spinner,
} from "@chakra-ui/react";
import InputMask from "react-input-mask";
import { FaEdit, FaWindowClose } from "react-icons/fa";
import axios from "axios";
import { SL } from "../../components/ShortcutLabel";
import { HotkeyContext } from "../../reducers/hotkeys.reducer";
import { Field } from "../form";
import { FieldView } from "../form/core/FieldView";

export function MyData(): JSX.Element {
  const hotkeyContext = useContext(HotkeyContext);
  const [profile, setProfile] = useState<any>(null);
  const [config, setConfig] = useState<Field>();
  const [general, setGeneral] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [editProfile, setEditProfile] = useState<any>(null);
  const [valid, setValid] = useState<any>({});

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACK_END_API}/users/profile`,
          {
            headers: {
              Authorization: localStorage.getItem("token") ?? "",
            },
          }
        );
        const data = await response.json();

        if (data.statusCode === 403) {
          localStorage.removeItem("token");
          window.location.href = "/login";
        } else {
          setProfile(data);
        }
      } catch (error) {
        localStorage.removeItem("token");
        window.location.href = "/login";
      } finally {
        setIsLoading(false);
      }
    };

    const fetchConfig = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACK_END_API}/users/config`,
          {
            headers: {
              authorization: `${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.data.config?.type === "block") {
          setConfig(response.data.config);
          setGeneral({ $user: response.data.$user });
        } else {
          setConfig({
            type: "block",
            key: "user",
            block: [],
            options: {
              hideEditMenu: true,
            },
          });
        }
        setGeneral({ $user: response.data.$user });
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
    fetchConfig();
  }, []);

  const getDocumentMask = (value: string) => {
    const numericValue = value?.replace(/\D/g, "") ?? "";
    if (numericValue.length <= 11) return "999.999.999-999"; // CPF
    return "99.999.999/9999-99"; // CNPJ
  };

  const handleEditToggle = () => {
    setIsEditMode((mode) => !mode);
    if (profile) {
      setEditProfile({ ...profile });
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditProfile((prev: any) => {
      return { ...prev, [name]: value };
    });
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);

      await axios.post(
        `${process.env.REACT_APP_BACK_END_API}/users/update-profile`,
        {
          name: editProfile.name,
          custom: editProfile.custom,
        },
        {
          headers: {
            Authorization: localStorage.getItem("token") ?? "",
          },
        }
      );

      setProfile(editProfile);
      setIsEditMode(false);
    } catch (e) {}

    setIsLoading(false);
  };

  useEffect(() => {
    hotkeyContext.dispatch({
      type: "SET_HOTKEY",
      payload: {
        E: handleEditToggle,
        S: handleSave,
      },
    });

    return () => {
      hotkeyContext.dispatch({
        type: "UNSET_HOTKEY",
        delete: ["E", "S"],
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, editProfile]);

  return (
    <>
      <div className="flex space-x-4 justify-end">
        {!isEditMode && !isLoading && (
          <>
            <FaEdit
              size={24}
              className="inline cursor-pointer ml-2"
              onClick={handleEditToggle}
            />{" "}
            <SL>E</SL>
          </>
        )}
        {isEditMode && !isLoading && (
          <FaWindowClose
            size={24}
            className="inline cursor-pointer ml-2"
            onClick={handleEditToggle}
          />
        )}
      </div>
      <div className="ml-20">
        <h1 className="text-xl md:text-2xl font-bold mb-6">Meus dados</h1>
        {isEditMode ? (
          <div className="flex flex-col justify-center text-center space-y-4">
            <FormControl id="email">
              <FormLabel>E-mail</FormLabel>
              <Input
                autoFocus
                placeholder="e-mail"
                type={"email"}
                size="lg"
                value={editProfile?.email}
                disabled
              />
            </FormControl>
            <FormControl id="document">
              <FormLabel>CPF ou CPNJ *</FormLabel>
              <InputMask
                placeholder="CPF ou CNPJ"
                maskChar=""
                mask={getDocumentMask(editProfile?.document)}
                name="document"
                value={editProfile?.document}
                onChange={handleInputChange}
                className="w-full bg-transparent border text-gray-400 cursor-not-allowed border-gray-200 rounded-md px-4 py-2.5"
                style={{ fontSize: "1.125rem" }}
                disabled
              />
            </FormControl>
            <FormControl id="name">
              <FormLabel>Nome ou razão social *</FormLabel>
              <Input
                size="lg"
                placeholder="Nome ou razão social"
                name="name"
                value={editProfile?.name}
                onChange={handleInputChange}
              />
            </FormControl>
            {config && (
              <Field
                context={editProfile.custom}
                validContext={valid}
                general={general}
                field={config}
                value={editProfile.custom}
                valid={valid}
                onChange={(value) =>
                  setEditProfile({
                    ...editProfile,
                    custom: value,
                  })
                }
                onValidChange={(valid) => setValid(valid)}
              ></Field>
            )}
            <button
              type="submit"
              onClick={handleSave}
              className="bg-yellow-600 hover:bg-yellow-700 mx-auto text-white text-lg py-2.5 px-16 rounded-xl disabled:opacity-80"
              disabled={isLoading}
            >
              {isLoading ? (
                <Spinner />
              ) : (
                <>
                  Salvar <SL bg="yellow.500">S</SL>
                </>
              )}
            </button>
          </div>
        ) : (
          <div className="flex flex-col space-y-3">
            <label className="font-bold">E-mail *</label>
            <Skeleton height="24px" rounded={"md"} isLoaded={!isLoading}>
              {profile?.email ?? "Não informado"}
            </Skeleton>
            <label className="font-bold">Documento *</label>
            <Skeleton height="24px" rounded={"md"} isLoaded={!isLoading}>
              {profile?.document ?? "Não informado"}
            </Skeleton>
            <label className="font-bold">Nome *</label>
            <Skeleton height="24px" rounded={"md"} isLoaded={!isLoading}>
              {profile?.name ?? "Não informado"}
            </Skeleton>
            {config && (
              <div>
                <FieldView
                  context={profile?.custom}
                  general={general}
                  field={config}
                  value={profile?.custom}
                ></FieldView>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}
